import React from 'react'
import { useSelector } from 'react-redux'

const FirstComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='FirstComponent'>

            {
                activeLanguage === "Español" ?
                    <div className="left">

                        <div className="heading">¿Cómo incorporarse como propietario de un tractor?</div>

                        <div className="sub-heading">Plataforma propietario Holatractor</div>

                        <div className="para1">
                            Busque plataformas en línea o aplicaciones móviles diseñadas específicamente para conectar proveedores de servicios, como propietarios de tractores, con clientes.
                        </div>

                        <div className="para2">
                            Además del arado y la labranza básicos, considere ofrecer servicios especializados como nivelación de tierras, preparación de tierras, instalación de sistemas de riego, recolección de cultivos o remoción de árboles. La diversificación de sus servicios puede satisfacer una gama más amplia de necesidades agrícolas y de desarrollo territorial.
                        </div>

                    </div>
                    :
                    <div className="left">

                        <div className="heading">How to become a tractor owner?</div>

                        <div className="sub-heading">Holatractor proprietary platform</div>

                        <div className="para1">
                            Look for online platforms or mobile apps designed specifically to connect service providers, such as tractor owners, with customers.
                        </div>

                        <div className="para2">
                            In addition to basic plowing and tillage, consider offering specialized services such as land grading, land preparation, irrigation system installation, crop harvesting, or tree removal. Diversifying its services can satisfy a broader range of agricultural and territorial development needs.
                        </div>

                    </div>
            }

            {
                activeLanguage === "Español" ?
                <div className="right">

                <div className="button">
                    <span>Aplicar</span>
                </div>

                <div className="buttom">

                    <div className="Form">

                        <div className="formLabel">Paso uno:</div>
                        <div className="formValue">Crear un perfil en línea</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Segundo paso:</div>
                        <div className="formValue">Verifique sus credenciales</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Paso tres:</div>
                        <div className="formValue">sube tus servicios y establece tu precio</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Paso cuatro:</div>
                        <div className="formValue">Promocione sus servicios localmente</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Paso cinco:</div>
                        <div className="formValue">Consigue la reserva y gana</div>

                    </div>

                </div>
            </div>
                    :
                    <div className="right">

                <div className="button">
                    <span>Apply</span>
                </div>

                <div className="buttom">

                    <div className="Form">

                        <div className="formLabel">Step one:</div>
                        <div className="formValue">Create an online profile</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Second step:</div>
                        <div className="formValue">Verify your credentials</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Step three:</div>
                        <div className="formValue">Upload your services and set your price</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Step four:</div>
                        <div className="formValue">Promote your services locally</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Step five:</div>
                        <div className="formValue">Get the reservation and win</div>

                    </div>

                </div>
            </div>
            }

        </div>
    )
}

export default FirstComponent