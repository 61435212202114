import React from 'react';
import styles from './Agenda.module.css';
import SolutionsSection from '../../components/Agendacomponents/SolutionsSection';
import PartnersSection from '../../components/Agendacomponents/PartnersSection';
import TestimonialSection from '../../components/Agendacomponents/Testimonal';
import FAQSection from '../../components/Agendacomponents/FAQSection';
import Custom from '../../components/Agendacomponents/Custom';
import Designer from '../../components/Agendacomponents/Designer';
import CaseStudy from '../../components/Agendacomponents/CaseStudy';

const Agenda = () => {
    return (
        <div>
        <section className={styles.heroSection}>
            <div className={styles.blueGradient}></div>
            <div className={styles.content}>
                <div className={styles.headerTags}>
                    <span>Web & UI/UX Design Agency for</span>
                    <div className={styles.tags}>
                        {['SaaS', 'Web 3.0', 'AI'].map((tag) => (
                            <span key={tag} className={styles.tag}>{tag}</span>
                        ))}
                    </div>
                </div>

                <h1 className={styles.mainHeading}>
                    Your caring <span className={styles.highlight}>Design Partner</span>
                    <br />
                    that provides experienced
                    <br />
                    <span className={styles.underline}>
                        Product Designers
                        <img
                            src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/65f14d2271255e3999a57fed_text-underline-hero.svg"
                            alt=""
                            className={styles.underlineImage}
                        />
                    </span>
                </h1>

                <div className={styles.buttonGroup}>
                    <div className={styles.iconButton}>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 7H17V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7 17L17 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <a href="#hire" className={styles.ctaButton}>
                        Hire a designer
                    </a>
                </div>
                <div className={styles.descriptionContainer}>
                    <svg
                        className={styles.star}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 0L14.59 9.41L24 12L14.59 14.59L12 24L9.41 14.59L0 12L9.41 9.41L12 0Z"
                            fill="#CCFF00"
                        />
                    </svg>
                    <p className={styles.description}>
                        Subscribe to our services on a <span className={styles.highlight}>monthly</span> basis and get an experienced product{' '}
                        <span className={styles.highlight}>designer</span> that will integrate in your team like an{' '}
                        <span className={styles.highlight}>in-house professional</span>.
                    </p>
                </div>
                <div className={styles.clientLogos}>
                    {[
                        { name: 'WordPress.com', logoUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJE6wJ5TvXvIxkb8qt5HIm8VTHr1G56WObzQ&s' },
                        { name: 'Interprefy', logoUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwk8OHCPiv5HrCBe5WLeGEnS4Ez0SXycCzew&s' },
                        { name: 'Players Health', logoUrl: 'https://images.squarespace-cdn.com/content/v1/5ede2122e582b96630a4a73e/1609375769634-EG1WOTIN7Y4MB01N8AV1/Domino%E2%80%99s-logo-2021.jpg' },
                        { name: 'Blockworks', logoUrl: 'https://www.thelogocreative.co.uk/wp-content/uploads/Microsoft-Logo.jpg' },
                        { name: 'Automattic', logoUrl: 'https://logos-world.net/wp-content/uploads/2024/09/Big-Idea-Logo.jpg' },
                        { name: 'Newnew', logoUrl: 'https://cdn.mos.cms.futurecdn.net/qLaJdhXCzSDqV5hk9uPumB-320-80.jpg' }
                    ].map((client) => (
                        <div key={client.name} className={styles.logoWrapper}>
                            <img
                                src={client.logoUrl}
                                alt={client.name}
                                className={styles.logo}
                                style={{ height: 40, width: 120 }}
                            />
                        </div>
                    ))}
                </div>

            </div>
        </section>
        <SolutionsSection/>
        <FAQSection/>

        <PartnersSection/>
        <TestimonialSection/>
        <Custom/>
        <CaseStudy/>
        <Designer/>
        </div>
    );
};

export default Agenda;

