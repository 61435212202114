import React from 'react'
import { Link } from 'react-router-dom'

const LoanButton = () => {

  return (
    <Link to={'/LoanApplication'} className='LoanButton'>

        <button
        name='apply_for_a_loan_button'>
            Apply for a loan
        </button>

    </Link>
  )
}

export default LoanButton