import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const FourthComponent = () => {

  const { activeLanguage } = useSelector(state => state.Languages)

  return (
    <div className='FourthComponent'>

      <div className='container'>

        <p className='mainHeading'>
          {
            activeLanguage === "Español" ?
              "¿Listo para encontrar tu pareja perfecta?"
              :
              "Ready to Find Your Perfect Match?"
          }
        </p>

        <p className='subHeading'>
          {
            activeLanguage === "Español" ?
              "Explore nuestro inventario en línea o visite nuestro concesionario en la aplicación holatractor para explorar nuestra selección de tractores agrícolas. Nuestro equipo está aquí para ayudarle a encontrar el tractor perfecto que le permitirá alcanzar el éxito en su granja."
              :
              "Browse our online inventory or visit our dealership in holatractor app to explore our selection of agricultural tractors. Our team is here to assist you in finding the perfect tractor to empower you to cultivate success on your farm."
          }
        </p>

      </div>

    </div>
  )
}

export default FourthComponent