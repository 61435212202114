import React from 'react'
import { useSelector } from 'react-redux'

const SecondComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const headingArray = activeLanguage === "Español" ? [
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Orientación experta",
            para: "Nuestros agentes de soporte capacitados conocen bien todos los aspectos de nuestro sistema SaaS, lo que garantiza que reciba orientación y asistencia experta siempre que la necesite."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Optimización de la eficiencia",
            para: "Entendemos la importancia de la eficiencia en las operaciones agrícolas. Es por eso que nos dedicamos a ayudarlo a aprovechar al máximo nuestro sistema SaaS, agilizar los procesos y aumentar la productividad."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Soporte personalizado",
            para: "No hay dos granjas iguales, ni tampoco nuestros servicios de soporte. Nos tomamos el tiempo para comprender sus necesidades únicas y adaptar nuestra asistencia en consecuencia, asegurándonos de que reciba el apoyo personalizado que se merece."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Reserva sin problemas",
            para: "¡Experimenta reservas sin problemas con nuestro sistema perfecto! Diga adiós a los dolores de cabeza por la programación, ya que nuestra plataforma intuitiva simplifica la gestión de citas, ahorrándole tiempo valioso y eliminando la frustración."
        },
    ] : [
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Expert guidance",
            para: "Our trained support agents are well versed in all aspects of our SaaS system, ensuring you receive expert guidance and assistance whenever you need it."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Efficiency optimization",
            para: "We understand the importance of efficiency in agricultural operations. That's why we're dedicated to helping you get the most out of our SaaS system, streamline processes, and increase productivity."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Personalized support",
            para: "No two farms are the same, nor are our support services. We take the time to understand your unique needs and tailor our assistance accordingly, ensuring you receive the personalized support you deserve."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Hassle-free booking",
            para: "Experience hassle-free bookings with our perfect system! Say goodbye to scheduling headaches as our intuitive platform simplifies appointment management, saving you valuable time and eliminating frustration."
        },
    ]

    return (
        <div className='SecondComponent'>

            <div
                className='container'>

                <p className='mainHeading'>
                    {
                        activeLanguage === "Español" ?
                            "¡Servicio de agente de soporte avanzado del sistema SaaS de Holatractor!"
                            :
                            "Holatractor SaaS System Advanced Support Agent Service!"
                    }
                </p>


                <p className='subHeading'>
                    {
                        activeLanguage === "Español" ?
                            "Estamos aquí para revolucionar la forma en que los agricultores y los clientes interactúan con la tecnología agrícola. Nuestro soporte de sistema SaaS (software como servicio) de vanguardia garantiza procesos de reserva fluidos y maximiza la eficiencia en el uso del sistema, permitiéndole concentrarse en lo que más importa: su granja."
                            :
                            "We are here to revolutionize the way farmers and customers interact with agricultural technology. Our state-of-the-art SaaS (software as a service) system support ensures smooth booking processes and maximizes efficiency in system usage, allowing you to focus on what matters most: your farm."
                    }
                </p>

                <div
                    className='elementsContainer'>

                    <div className='left'>

                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/hola.webp"
                            alt='left side image' />

                    </div>

                    <div
                        className='right'>

                        {
                            headingArray.map((details, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='box'>

                                        <div className="box-left">
                                            <img src={details.icon} alt="" />
                                        </div>

                                        <div className="box-right">

                                            <div className="heading">
                                                {details.heading}
                                            </div>

                                            <div className="details">
                                                {details.para}
                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

            </div>

        </div>
    )
}

export default SecondComponent