import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

const FifthComponent = () => {

    const [activeArray, setActiveArray] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const contentArray = activeLanguage === "Español" ? [
        {
            count: "01",
            heading: "Proteja su valioso equipo"
        },
        {
            count: "02",
            heading: "Maximizar la eficiencia y la productividad"
        },
        {
            count: "03",
            heading: "Reducir los costos operativos"
        },
        {
            count: "04",
            heading: "Alarga la vida de tu tractor"
        },
        {
            count: "05",
            heading: "Tome decisiones basadas en datos para una campo próspera"
        },
    ]
        :
        [
            {
                count: "01",
                heading: "Protect your valuable equipment"
            },
            {
                count: "02",
                heading: "Maximize efficiency and productivity"
            },
            {
                count: "03",
                heading: "Reduce operating costs"
            },
            {
                count: "04",
                heading: "Extend the life of your tractor"
            },
            {
                count: "05",
                heading: "Make data-driven decisions for a thriving farm"
            },
        ]

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveArray(prev => prev < contentArray.length - 1 ? prev + 1 : 0);
        }, 3000);

        return () => clearInterval(interval);
    }, [activeArray]);

    return (
        <div
            className='FifthComponent'>

            <h2
                className='text-xl 768px:text-3xl'>
                {
                    activeLanguage === "Español" ?
                        "Invierta en su éxito"
                        :
                        "Invest in Your Success"
                }
            </h2>

            <p style={{textAlign: 'center', color: 'white', marginTop: '20px'}}>
                    {
                        activeLanguage === "Español" ?
                            "El servicio de seguimiento de tractores de Holatractor con tecnología IoT es una inversión en tu éxito."
                            :
                            "Holatractor's tractor tracking service with IoT technology is an investment in your success. It empowers you to"
                    }
                </p>

            <div
                className='Fifth_container'>

                <div
                    className='container_left'>

                    <img
                        alt='Tools_and_technologies_for_data_visualization'
                        src={"https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/farm_map.webp"} />

                </div>

                <div
                    className='container_right'>

                    <div
                        className='right_container'>

                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className={` ${index === contentArray.length - 1 ? 'map_container_alt' : 'map_container'}`}>

                                        <div
                                            style={{
                                                minWidth: '56px',
                                                maxWidth: '56px',
                                                minHeight: '56px',
                                                maxHeight: '56px',
                                                borderRadius: '8px',
                                                border: '1px solid #707070',
                                                backgroundColor: '#1b1b1b',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: '1'
                                            }}>

                                            <h4
                                                className={` text-xl 768px:text-2xl`}
                                                style={{
                                                    color: activeArray === index && "#AB0F0C"
                                                }}>
                                                {details.count}
                                            </h4>

                                        </div>

                                        <div>

                                            <h4
                                                className={` text-xl 768px:text-2xl`}
                                                style={{
                                                    color: activeArray === index && "#1b1b1b"
                                                }}>
                                                {details.heading}
                                            </h4>

                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

            </div>

        </div>
    )
}

export default FifthComponent