import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const FirstComponent = () => {

    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const contentArray = activeLanguage === "Español" ? [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Seguimiento de ubicación",
            para: "Sepa siempre dónde está su tractor, ya sea trabajando en el campo, estacionado en el granero o en cualquier punto intermedio. Reciba alertas instantáneas si su tractor abandona los límites designados, lo que disuade el robo y ayuda en los esfuerzos de recuperación."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Monitoreo del rendimiento del motor",
            para: "Realice un seguimiento de los parámetros clave del motor, como las horas del motor, el consumo de combustible y la temperatura de funcionamiento. Identifique problemas potenciales desde el principio para evitar averías costosas y garantizar programas de mantenimiento adecuados."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Datos de uso de campo",
            para: "Obtenga información sobre cómo se utiliza su tractor. Vea en qué campos se está trabajando, realice un seguimiento de las horas de trabajo y optimice la asignación de tractores para lograr la máxima eficiencia."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Informes y análisis detallados",
            para: "Acceda a informes completos con visualizaciones fáciles de entender, que le permitirán analizar tendencias, identificar áreas de mejora y tomar decisiones basadas en datos para su granja."
        },
    ]
    :
    [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Location Tracking",
            para: "Always know where your tractor is, whether it's working in the field, parked at the barn, or anywhere in between. Receive instant alerts if your tractor leaves designated boundaries, deterring theft and aiding recovery efforts."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Engine Performance Monitoring",
            para: "Track key engine parameters like engine hours, fuel consumption, and operating temperature. Identify potential issues early on to prevent costly breakdowns and ensure proper maintenance schedules."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Field Usage Data",
            para: "Gain insights into how your tractor is being utilized. See which fields are being worked on, track working hours, and optimize tractor allocation for maximum efficiency."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Detailed Reports & Analytics",
            para: "Access comprehensive reports with easy-to-understand visualizations, allowing you to analyze trends, identify areas for improvement, and make data-driven decisions for your farm."
        },
    ]

    return (
        <div className='FirstComponent'>

            <div className="headings">

                <h2>
                    {
                        activeLanguage === "Español" ?
                            "¿Qué es el seguimiento de tractores con IoT?"
                            :
                            "What is Tractor Tracking with IoT?"
                    }
                </h2>

                <p>
                    {
                        activeLanguage === "Español" ?
                            "Equipamos su tractor con un dispositivo IoT pequeño y fácil de instalar que recopila y transmite datos en tiempo real a nuestra plataforma segura. Estos datos incluyen"
                            :
                            "We equip your tractor with a small, easy-to-install IoT device that collects and transmits real-time data to our secure platform. This data includes"
                    }
                </p>

            </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className='box'
                                        key={index}
                                        onClick={() => { setActiveContnentHeading(index) }}>
                                        <div
                                            className='heading'
                                            style={{
                                                color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                            }}>
                                            <p>{index + 1 > 9 ? index + 1 : `0${index + 1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img
                            src={contentArray[activeContentHeading].img}
                            alt="" />

                        <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                        </div>

                        <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default FirstComponent