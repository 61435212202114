// import { useState } from 'react'
// import { EyeIcon, EyeOffIcon } from 'lucide-react'
// import './Register.scss'
// import { Backdrop, CircularProgress } from '@mui/material'
// import { useGoogleLogin } from '@react-oauth/google'
// import axios from "axios"

// export default function SignupForm() {
//     const [showPassword, setShowPassword] = useState(false)
//     








//     return (
//         <div className="signup-page">
//             {/* Hero Section */}
//             <div className="signup-page__hero">
//                 <div className="signup-page__hero-title">
//                     <h1>Capturing Moments,</h1>
//                     <h2>Creating Memories</h2>
//                 </div>
//             </div>

//             {/* Form Section */}
//             <div className="signup-page__form-container">
//                 <div className="signup-card">
//                     {/* Card Header */}
//                     <div className="signup-card__header">
//                         <h2 className="title">Create an account</h2>
//                         <p className="description">
//                             Already have an account?{' '}
//                             <Link to="/login">Log in</Link>
//                         </p>
//                     </div>

//                     {/* Card Content */}
//                     <div className="signup-card__content">
//                         {/* Name Inputs */}
//                         <div className="input-group">
//                             <div className="input-wrapper">
//                                 <label htmlFor="first_name">First name</label>
//                                 <input
//                                     id="first_name"
//                                     placeholder="Enter first name"
//                                     value={farmerFirstName}
//                                     onChange={e => { setFarmerFirstName(e.target.value) }}
//                                     required
//                                 />
//                             </div>
//                             <div className="input-wrapper">
//                                 <label htmlFor="last_name">Last name</label>
//                                 <input
//                                     id="last_name"
//                                     placeholder="Enter last name"
//                                     value={farmerLastName}
//                                     onChange={e => { setFarmerLastName(e.target.value) }}
//                                     required
//                                 />
//                             </div>
//                         </div>

//                         {/* Middle Name */}
//                         <div className="input-wrapper">
//                             <label htmlFor="middle_name">Middle name (optional)</label>
//                             <input
//                                 id="middle_name"
//                                 placeholder="Enter middle name"
//                                 value={farmerMiddleName}
//                                 onChange={e => { setFarmerMiddleName(e.target.value) }}
//                             />
//                         </div>

//                         {/* Email */}
//                         <div className="input-wrapper">
//                             <label htmlFor="email">Email</label>
//                             <input
//                                 id="email"
//                                 type="email"
//                                 placeholder="Enter your email"
//                                 value={farmerEmail}
//                                 onChange={e => { setFarmerEmail(e.target.value) }}
//                                 required
//                             />
//                         </div>

//                         {/* Password */}
//                         <div className="input-wrapper">
//                             <label htmlFor="password">Password</label>
//                             <div style={{ position: 'relative' }}>
//                                 <input
//                                     id="password"
//                                     type={showPassword ? "text" : "password"}
//                                     placeholder="Enter your password"
//                                     value={password}
//                                     onChange={e => { setPassword(e.target.value) }}
//                                     required
//                                 />
//                                 <button
//                                     type="button"
//                                     className="password-toggle"
//                                     onClick={() => setShowPassword(!showPassword)}
//                                 >
//                                     {showPassword ? <EyeOffIcon size={16} /> : <EyeIcon size={16} />}
//                                 </button>
//                             </div>
//                         </div>

//                         {/* Currency Selection */}
//                         <div className="input-group">
//                             <div className="input-wrapper">
//                                 <label htmlFor="currency_symbol">Currency Symbol</label>
//                                 <select
//                                     id="currency_symbol"
//                                     value={currency_symbol}
//                                     onChange={e => { set_currency_symbol(e.target.value) }}>
//                                     <option value="">Select symbol</option>
//                                     <option value="$">$ (USD)</option>
//                                     <option value="€">€ (EUR)</option>
//                                     <option value="£">£ (GBP)</option>
//                                     <option value="¥">¥ (JPY)</option>
//                                 </select>
//                             </div>
//                             <div className="input-wrapper">
//                                 <label htmlFor="currency_code">Currency Code</label>
//                                 <select
//                                     id="currency_code"
//                                     value={currency_code}
//                                     onChange={e => { set_currency_code(e.target.value) }}>
//                                     <option value="">Select code</option>
//                                     <option value="USD">USD</option>
//                                     <option value="EUR">EUR</option>
//                                     <option value="GBP">GBP</option>
//                                     <option value="JPY">JPY</option>
//                                 </select>
//                             </div>
//                         </div>

//                         {/* Gender Selection */}
//                         <div>
//                             <label>Gender</label>
//                             <div className="gender-selection">
//                                 <div className="radio-item">
//                                     <input
//                                         type="radio"
//                                         id="male"
//                                         name="gender"
//                                         value="male"
//                                         onClick={e => { set_gender("male") }}
//                                         checked={gender === "male"}
//                                     />
//                                     <label htmlFor="male">Male</label>
//                                 </div>
//                                 <div className="radio-item">
//                                     <input
//                                         type="radio"
//                                         id="female"
//                                         name="gender"
//                                         value="female"
//                                         onClick={e => { set_gender("female") }}
//                                         checked={gender === "female"}
//                                     />
//                                     <label htmlFor="female">Female</label>
//                                 </div>
//                                 <div className="radio-item">
//                                     <input
//                                         type="radio"
//                                         id="other"
//                                         name="gender"
//                                         value="other"
//                                         onClick={e => { set_gender("other") }}
//                                         checked={gender === "other"}
//                                     />
//                                     <label htmlFor="other">Other</label>
//                                 </div>
//                             </div>
//                         </div>

//                         {/* Terms Checkbox */}
//                         <div className="terms-checkbox">
//                             <input
//                                 type="checkbox"
//                                 id="terms"
//                                 required
//                             />
//                             <label htmlFor="terms">
//                                 I agree to the{' '}
//                                 <Link to="/terms">Terms & Conditions</Link>
//                             </label>
//                         </div>
//                     </div>

//                     {/* Card Footer */}
//                     <div className="signup-card__footer">
//                         <button className="signup-button" onClick={() => { handleRegister() }}>
//                             Create account
//                         </button>

//                         <div className="divider">
//                             <div className="divider-line"></div>
//                             <span className="divider-text">Or register with</span>
//                         </div>

//                         <div className="social-buttons">
//                             <button className="social-button" onClick={() => { login() }}>
//                                 Google
//                             </button>
//                             <button className="social-button">
//                                 Apple
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

import { CheckCircle } from 'lucide-react'
import "./Register.scss"
import { useState } from 'react'
import { errorMessage, successMessage } from '../../components/Toastify/message'
import { NestjsBookingInstance } from '../../components/Axios/NestjsBookingInstance'
import CryptoJS from "crypto-js"
import { useNavigate } from 'react-router-dom'
import { Backdrop, CircularProgress } from '@mui/material'
import axios from 'axios'
import { useGoogleLogin } from '@react-oauth/google'
import { DashboardBaseUrl } from '../../components/Axios/DashboardInstance'

export default function SignupPage() {
  const [farmerFirstName, setFarmerFirstName] = useState('')
  const [farmerMiddleName, setFarmerMiddleName] = useState('')
  const [farmerLastName, setFarmerLastName] = useState('')
  const [farmerEmail, setFarmerEmail] = useState('')
  const [password, setPassword] = useState('')
  const [gender, set_gender] = useState('')

  const [loading, setLoading] = useState(false)

  const [googleId, set_googleId] = useState('')
  const [email_verified, set_email_verified] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false)

  const navigate = useNavigate();

  function handleRegister(e) {
    e.preventDefault()

    if (!farmerFirstName) {
      errorMessage("Please give the first name")
      return
    }
    if (!farmerLastName) {
      errorMessage("Please give the last name")
      return
    }
    if (!farmerEmail) {
      errorMessage("Please give email")
      return
    }
    if (!password || password.length < 8) {
      errorMessage("Password should atleast be 8 characters")
      return
    }
    if (!gender) {
      errorMessage("Password select your gender")
      return
    }

    const encryptedPassword = CryptoJS.AES.encrypt(
      password,
      "m4AfXfQ&1brl3LjQFYO"
    ).toString();

    setLoading(true)

    NestjsBookingInstance.post("/farmer/signup", {
      first_name: farmerFirstName,
      last_name: farmerLastName,
      middle_name: farmerMiddleName,
      email: farmerEmail,
      password: encryptedPassword,
      gender,
      authType: "EMAIL",
      role_name: "farmer",
      currency_symbol: "$",
      currency_code: "USD"
    }).then((res) => {
      if (res.status === 201 && res.data.access_token) {
        successMessage("User successfully registered")
        navigate("/login")
      }
    }).catch((err) => {
      if (
        err.response &&
        err.response.status === 409 &&
        err.response.data.message === "User already exists"
      ) {
        errorMessage("Email already taken");
      } else if (
        err.response &&
        err.response.status === 409 &&
        err.response.data.message === "Role does not exist"
      ) {
        errorMessage("Role does not exist");
      } else if (
        err.response &&
        err.response.status === 409 &&
        err.response.data.message === "Something went wrong"
      ) {
        errorMessage("Something went wrong");
      } else {
        errorMessage("Internal server error");
      }
    })
      .finally(() => {
        setLoading(false);
      });
  }

  const splitFullName = (fullName) => {
    const nameParts = fullName.trim().split(/\s+/); // Split by spaces
    const firstName = nameParts.shift(); // Take the first element as the first name
    const lastName = nameParts.pop(); // Take the last element as the last name
    const middleName = nameParts.join(" "); // Join the rest as middle name

    return { firstName, middleName, lastName };
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
          Authorization: `Bearer ${codeResponse.access_token}`,
          Accept: 'application/json'
        }
      })
        .then((res) => {
          const { firstName, lastName, middleName } = splitFullName(res.data.name)
          setFarmerFirstName(firstName)
          setFarmerMiddleName(middleName)
          setFarmerEmail(res.data.email)
          set_email_verified(res.data.verified_email)
          set_googleId(res.data.id)
          if (!lastName) {
            setDialogOpen(true)
          } else {
            handleGoogleRegisterSud(firstName, lastName, middleName, res.data.email, res.data.verified_email, res.data.id)
          }
        })
        .catch((err) => errorMessage('Login Failed'));
    },
    onError: (error) => errorMessage('Login Failed')
  });

  function handleGoogleLogin(email) {
    setLoading(true)
    NestjsBookingInstance.post("/user/login", {
      email: email,
      authType: "GOOGLE",
    })
      .then((res) => {
        if (res.status === 201 && res.data.access_token) {
          if (res.data.isFarmer) {
            successMessage("Log in successful")
            window.location.href = `${DashboardBaseUrl}/farmer_login?token=${res.data.access_token}&isFarmer=${res.data.isFarmer}&isOperator=${res.data.isOperator}&isOwner=${res.data.isOwner}&isDealer=${res.data.isDealer}`
          } else {
            errorMessage("Your account is not a farmer account")
          }
        }
      })
      .catch((err) => {
        errorMessage(err.response?.data?.message || "Some error occurred")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function handleGoogleRegisterSud(firstName, lastName, middleName, email, verified_email, gid) {
    setLoading(true)

    NestjsBookingInstance.post("/farmer/signup", {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      email: email,
      gender: gender ? gender : "male",
      authType: "GOOGLE",
      role_name: "farmer",
      currency_symbol: "$",
      currency_code: "USD",
      googleId: gid,
      email_verified: verified_email
    }).then((res) => {
      handleGoogleLogin(email)
    }).catch((err) => {
      if (
        err.response &&
        err.response.status === 409 &&
        err.response.data.message === "User already exists"
      ) {
        handleGoogleLogin(email)
      } else if (
        err.response &&
        err.response.status === 409 &&
        err.response.data.message === "Role does not exist"
      ) {
        errorMessage("Role does not exist");
      } else if (
        err.response &&
        err.response.status === 409 &&
        err.response.data.message === "Something went wrong"
      ) {
        errorMessage("Something went wrong");
      } else {
        errorMessage("Internal server error");
      }
    })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleGoogleRegister() {
    if (!farmerFirstName) {
      errorMessage("Please give the first name")
      return
    }
    if (!farmerLastName) {
      errorMessage("Please give the last name")
      return
    }
    if (!farmerEmail) {
      errorMessage("Please give email")
      return
    }
    if (!googleId) {
      errorMessage("Google id not available. Try again...")
      return
    }

    setLoading(true)

    NestjsBookingInstance.post("/farmer/signup", {
      first_name: farmerFirstName,
      last_name: farmerLastName,
      middle_name: farmerMiddleName,
      email: farmerEmail,
      gender,
      authType: "GOOGLE",
      role_name: "farmer",
      currency_symbol: "$",
      currency_code: "USD",
      googleId,
      email_verified
    }).then((res) => {
      if (res.status === 201 && res.data.access_token) {
        successMessage("User successfully registered")
        navigate("/login")
      }
    }).catch((err) => {
      if (
        err.response &&
        err.response.status === 409 &&
        err.response.data.message === "User already exists"
      ) {
        errorMessage("Email already taken");
      } else if (
        err.response &&
        err.response.status === 409 &&
        err.response.data.message === "Role does not exist"
      ) {
        errorMessage("Role does not exist");
      } else if (
        err.response &&
        err.response.status === 409 &&
        err.response.data.message === "Something went wrong"
      ) {
        errorMessage("Something went wrong");
      } else {
        errorMessage("Internal server error");
      }
    })
      .finally(() => {
        setLoading(false);
      });
  }

  if (dialogOpen) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || dialogOpen}
      >
        <form onSubmit={(e) => { handleGoogleRegister(e) }}>
          <div className="form-row">
            <div className="form-group">
              <label className="form-label">* Last Name</label>
              <input type="text" className="form-input" required value={farmerLastName} onChange={e => { setFarmerLastName(e.target.value) }} readOnly={loading} />
            </div>
          </div>
          <button type="submit" className="submit-button" disabled={loading}>
            Create account
          </button>
        </form>
      </Backdrop>
    )
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <div className="container">
        <div className="hero">
          <h1> Join HolaTractor Agriculture with Advanced Mechanization for Better Yields!</h1>
          <ul className="features">
            <li className="feature-item">
              <CheckCircle className="check-icon" size={24} />
              Smart Booking System
            </li>
            <li className="feature-item">
              <CheckCircle className="check-icon" size={24} />
              Affordable and Accessible
            </li>
            <li className="feature-item">
              <CheckCircle className="check-icon" size={24} />
              Maintenance and Support
            </li>
          </ul>
        </div>

        <div className="signup-form">
          <button className="google-button" onClick={() => { login() }}>
            <svg width="18" height="18" viewBox="0 0 18 18">
              <path
                fill="#fff"
                d="M17.64 9.2c0-.637-.057-1.251-.164-1.84H9v3.481h4.844c-.209 1.125-.843 2.078-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615z"
              />
              <path
                fill="#fff"
                d="M9 18c2.43 0 4.467-.806 5.956-2.184l-2.908-2.258c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332C2.438 15.983 5.482 18 9 18z"
              />
              <path
                fill="#fff"
                d="M3.964 10.707c-.18-.54-.282-1.117-.282-1.707s.102-1.167.282-1.707V4.961H.957C.347 6.175 0 7.548 0 9s.348 2.825.957 4.039l3.007-2.332z"
              />
              <path
                fill="#fff"
                d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0 5.482 0 2.438 2.017.957 4.961L3.964 7.293C4.672 5.166 6.656 3.58 9 3.58z"
              />
            </svg>
            Sign up with Google
          </button>

          <div className="divider">OR</div>

          <div className="required">* indicates a required field.</div>

          <form onSubmit={(e) => { handleRegister(e) }}>
            <div className="form-row">
              <div className="form-group">
                <label className="form-label">* First Name</label>
                <input type="text" className="form-input" required value={farmerFirstName} onChange={e => { setFarmerFirstName(e.target.value) }} readOnly={loading} />
              </div>
              <div className="form-group">
                <label className="form-label">Middle Name</label>
                <input type="text" className="form-input" value={farmerMiddleName} onChange={e => { setFarmerMiddleName(e.target.value) }} readOnly={loading} />
              </div>
            </div>

            <div className="form-row">

              <div className="form-group">
                <label className="form-label">* Last Name</label>
                <input type="text" className="form-input" required value={farmerLastName} onChange={e => { setFarmerLastName(e.target.value) }} readOnly={loading} />
              </div>
              <div className="form-group">
                <label className="form-label">* Gender</label>
                <select className="form-input" required value={gender} onChange={e => { set_gender(e.target.value) }} readOnly={loading}>
                  <option value="">Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label">* Email</label>
              <input type="email" className="form-input" required value={farmerEmail} onChange={e => { setFarmerEmail(e.target.value) }} readOnly={loading} />
            </div>

            <div className="form-group">
              <label className="form-label">* Password</label>
              <input type="password" className="form-input" required value={password} onChange={e => { setPassword(e.target.value) }} readOnly={loading} />
            </div>

            <div className="form-group">
              <label className="form-label terms-checkbox">
                <input type="checkbox" required />I agree to the <a href="#">terms of service</a> and{" "}
                <a href="#">privacy policy</a>
              </label>
            </div>

            <button type="submit" className="submit-button" disabled={loading}>
              Create account
            </button>
          </form>

          <div className="login-link">
            Already have an account? <a href="/login">Log In</a>
          </div>
        </div>
      </div>

      <div className="trusted-section">
        <h2>Trusted at companies</h2>
        <div className="logo-grid">
          <img src="https://i.pinimg.com/736x/48/71/ff/4871ffad31eb2c1f355cd7c6e8eae454.jpg" alt="Dropbox logo" />
          <img src="https://i.pinimg.com/736x/48/71/ff/4871ffad31eb2c1f355cd7c6e8eae454.jpg" alt="Lyft logo" />
          <img src="https://i.pinimg.com/736x/48/71/ff/4871ffad31eb2c1f355cd7c6e8eae454.jpg" alt="HelloFresh logo" />
          <img src="https://i.pinimg.com/736x/48/71/ff/4871ffad31eb2c1f355cd7c6e8eae454.jpg" alt="Asana logo" />
          <img src="https://i.pinimg.com/736x/48/71/ff/4871ffad31eb2c1f355cd7c6e8eae454.jpg" alt="Zendesk logo" />
        </div>
      </div>
    </>
  )
}