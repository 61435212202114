import React from 'react'
import './MiniTractorPage.scss'
import { motion } from 'framer-motion'
import BookingStepper from './BookingStepper'
import { useSelector } from 'react-redux'

const MiniTractorPage = () => {
  const { activeLanguage } = useSelector(state => state.Languages)
    return (
        <div className='MiniTractorPage'>

            <div className="banner">

                <img
                    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/two_wheel_tractor.webp"
                    alt="mini tractor banner" />

                    <div className="wrapper" />

                    <motion.div
                    initial={{
                        x: -100,
                        opacity: 0
                      }}
                      whileInView={{
                        x: 0,
                        opacity: 1
                      }}
                      transition={{
                        duration: 1
                      }}
                      className='page-heading'>
                        {
                        activeLanguage === "Español" ?
                            <div
                                className='text-container'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px'
                                }}>

                                <p className="hO">
                                Servicio asequible para tractores de dos ruedas de Hola Tractor
                                </p>

                                {/* <p className="hF">
                                Hola Tractor le ofrece servicios de tractores de dos ruedas asequibles y sin complicaciones directamente en la puerta de su casa. Nuestro sencillo proceso garantiza una experiencia perfecta, ahorrándole tiempo y esfuerzo.
                                </p> */}

                            </div>
                            :
                            <div
                                className='text-container'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px'
                                }}>

                                <p className="hO">
                                Affordable Two-Wheeler Tractor Service from Hola Tractor
                                </p>

                                {/* <p className="hF">
                                Hola Tractor brings you affordable and hassle-free two-wheeler tractor services right to your doorstep. Our easy process ensures a seamless experience, saving you time and effort.
                                </p> */}

                            </div>
                    }
                    </motion.div>

            </div>

            <BookingStepper />

        </div>
    )
}

export default MiniTractorPage