import React from 'react';
import { ArrowRight } from 'lucide-react';
import styles from './SolutionsSection.module.css';

const SolutionsSection = () => {
  return (
    <section className={styles.solutionsSection}>
      <div className={styles.container}>
        <h2 className={styles.title}>Our Solutions</h2>
        
        <p className={styles.description}>
          Whether you need to design your first MVP to get funding, redesign your product to improve business 
          metrics, or get a remote design team to grow faster - our product designers are here to help.
        </p>

        <div className={styles.cardGrid}>
          {/* MVP Design Card */}
          <div className={`${styles.card} ${styles.cardMvp}`}>
            <div className={styles.cardHeader}>
              <div className={styles.cardLabel}>
                FOR
                <br />
                STARTUPS
              </div>
              <div className={styles.cardIcon} style={{ backgroundImage: "url('https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/6694c0e75f3ff8d3016bb98a_i1.webp')" }} />
            </div>
            
            <h3 className={styles.cardTitle}>MVP Design</h3>
            <p className={styles.cardDescription}>
              Create a digital product, attract investors and new clients
            </p>
            <button className={`${styles.exploreButton} ${styles.exploreButtonDark}`}>
              Explore
              <ArrowRight className={styles.arrowIcon} />
            </button>
          </div>

          {/* Product Redesign Card */}
          <div className={`${styles.card} ${styles.cardRedesign}`}>
            <div className={styles.cardHeader}>
              <div className={styles.cardLabel}>
                FOR STARTUPS
                <br />
                & EXISTING COMPANIES
              </div>
              <div className={styles.cardIcon} style={{ backgroundImage: "url('https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/65fad3cdf7f2f741fb5e323b_i2.webp" }} />
            </div>
            
            <h3 className={styles.cardTitle}>Product Redesign</h3>
            <p className={styles.cardDescription}>
              Get a fresh look, improved user experience, or enhanced functionality
            </p>
            <button className={`${styles.exploreButton} ${styles.exploreButtonDark}`}>
              Explore
              <ArrowRight className={styles.arrowIcon} />
            </button>
          </div>

          {/* Team Extension Card */}
          <div className={`${styles.card} ${styles.cardExtension}`}>
            <div className={styles.cardHeader}>
              <div className={styles.cardLabel}>
                FOR
                <br />
                EXISTING COMPANIES
              </div>
              <div className={styles.cardIcon} style={{ backgroundImage: "url('https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/6694c0e7005722eda36efd67_i3.webp" }} />
            </div>
            
            <h3 className={styles.cardTitle}>Team Extension</h3>
            <p className={styles.cardDescription}>
              Expand your team with our dedicated and talented design experts
            </p>
            <button className={`${styles.exploreButton} ${styles.exploreButtonLight}`}>
              Explore
              <ArrowRight className={styles.arrowIcon} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionsSection;

