import React from 'react';
import styles from './CtaSection.module.css';

const CtaSection = () => {
  return (
    <section className={styles.ctaSection}>
      <div className={styles.ctaContainer}>
        <div className={styles.ctaContent}>
          <h2 className={styles.ctaTitle}>
            Stop dreaming, start building.
            <span className={styles.ctaSubtitle}>Your portfolio awaits</span>
          </h2>
          <p className={styles.ctaDescription}>
            Get started today and build your dream portfolio website in minutes!
          </p>
          <button className={styles.ctaButton}>
            Buy template now
          </button>
        </div>
        <div className={styles.ctaImageWrapper}>
          <img
            src="https://getpublii.com/blog/media/posts/63/contact-form.png"
            alt="Portfolio template preview showing desktop and mobile versions"
            className={styles.ctaImage}
          />
        </div>
      </div>
    </section>
  );
};

export default CtaSection;

