import React from 'react'

const SixthComponent = () => {
    const componentArray = [
        {
            heading: "Address Your Specific Needs",
            description: "We conduct a thorough needs assessment to identify skill gaps and develop customized training programs aligned with your business objectives and technology stack.",
        },
        {
            heading: "Offer a Variety of Training Methods",
            description: "From instructor-led classroom sessions to engaging online learning modules and interactive workshops, we cater to different learning styles and preferences.",
        },
        {
            heading: "Deliver Expert Instruction",
            description: "Our team of experienced IT professionals and certified trainers possess in-depth knowledge of the latest technologies and industry best practices.",
        },
        {
            heading: "Focus on Practical Application",
            description: "Our training goes beyond theory, providing hands-on exercises and real-world scenarios to equip your employees with the skills they can immediately apply in their roles.",
        },
        {
            heading: "Measure Training Effectiveness",
            description: "We track learning outcomes and provide comprehensive reports to ensure your training investments deliver measurable results.",
        },
    ]
    
    return (
        <div
            className='SixthComponent'>

                <h1
                className='text-3xl 1000px:text-5xl'>
                   We provide comprehensive corporate training services
                </h1>

                <div
                className='grid-cols-1 768px:grid-cols-2 sixth_container'>

            {
                componentArray.map((details, index) => {
                    return (
                        <div
                        key={index}
                            className='px-[20px] py-[20px] 1600px:px-[60px] 1600px:py-[40px] gap-5 1600px:gap-8 box'>

                            <h2
                                className='text-3xl 1600px:text-5xl text-center'
                                style={{textAlign: 'center'}}>
                                {details.heading}
                            </h2>

                            <p
                                className='text-xl 1600px:text-2xl text-center'
                                style={{textAlign: 'center'}}>
                                {details.description}
                            </p>

                        </div>
                    )
                })
            }

            </div>

        </div>
    )
}

export default SixthComponent