import React from 'react';
import './partnerssection.css';
import { motion } from 'framer-motion';

const PartnersSection = () => {

  const logos = [
    'https://www.tailorbrands.com/wp-content/uploads/2021/06/Artboard-1-copy-10-100.jpg',
    'https://as1.ftcdn.net/v2/jpg/04/35/17/68/1000_F_435176826_WKljInFzobmBZD6UgRyS8gdFLlvFZgVw.jpg',
    'https://www.tailorbrands.com/wp-content/uploads/2021/06/Artboard-1-copy-10-100.jpg',
    'https://as1.ftcdn.net/v2/jpg/04/35/17/68/1000_F_435176826_WKljInFzobmBZD6UgRyS8gdFLlvFZgVw.jpg',
    'https://www.tailorbrands.com/wp-content/uploads/2021/06/Artboard-1-copy-10-100.jpg',
    'https://www.tailorbrands.com/wp-content/uploads/2021/06/Artboard-1-copy-10-100.jpg',
    'https://www.tailorbrands.com/wp-content/uploads/2021/06/Artboard-1-copy-10-100.jpg',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQy-SHft5CWIeztfNOLddLhO0Y5csEI23tuExv6aed8VBnatALbcQq_xndNNceCytgXxes&usqp=CAU',
    'https://www.tailorbrands.com/wp-content/uploads/2021/06/Artboard-1-copy-10-100.jpg',
    'https://www.tailorbrands.com/wp-content/uploads/2021/06/Artboard-1-copy-10-100.jpg',
    'https://www.tailorbrands.com/wp-content/uploads/2021/06/Artboard-1-copy-10-100.jpg',
    'https://www.tailorbrands.com/wp-content/uploads/2021/06/Artboard-1-copy-10-100.jpg',
  ];

  // Double the logos to create seamless infinite scroll
  const doubledLogos = [...logos, ...logos];

  return (
    <section className="partners-section">
      <div className="partner-inside">
        <div className="partners-container">
          <h2 className="partners-heading">
            Turning projects into trusting
            <br />
            <span className="partnerships-line">
              partnerships
              <img
                src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/66ba180d7f424f6586bfa652_partners-title-underline.svg"
                alt=""
                className="underline-img"
              />
            </span>
          </h2>
        </div>
        <div className="partners-grid">
            {/* First Row - Left to Right */}
            <div className="partner-row scroll-left">
              {doubledLogos.map((logo, index) => (
                <div 
                  key={`row1-${index}`} 
                  className="partner-logo-container"
                >
                  <img
                    src={logo}
                    alt={`Partner ${index + 1}`}
                    className="partner-logo"
                  />
                </div>
              ))}
            </div>

            {/* Second Row - Right to Left */}
            <div className="partner-row scroll-right">
              {doubledLogos.map((logo, index) => (
                <div 
                  key={`row2-${index}`} 
                  className="partner-logo-container"
                >
                  <img
                    src={logo}
                    alt={`Partner ${index + 1}`}
                    className="partner-logo"
                  />
                </div>
              ))}
            </div>

            {/* Third Row - Left to Right */}
            <div className="partner-row scroll-left">
              {doubledLogos.map((logo, index) => (
                <div 
                  key={`row3-${index}`} 
                  className="partner-logo-container"
                >
                  <img
                    src={logo}
                    alt={`Partner ${index + 1}`}
                    className="partner-logo"
                  />
                </div>
              ))}
            </div>
          </div>
      </div>
    </section>

  );
};

export default PartnersSection;