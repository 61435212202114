import React from 'react'

const FourthComponent = () => {

    const gridArray = [
        {
            imageLink: "https://www.xpressloan.in/assets/img/Home-renovation.png",
            heading: "SMALL HOME RENOVATIONS",
            description: "Medical emergencies are one of the most common reasons people apply for personal loans."
        },
        {
            imageLink: "https://www.xpressloan.in/assets/img/Home-renovation.png",
            heading: "SMALL HOME RENOVATIONS",
            description: "Medical emergencies are one of the most common reasons people apply for personal loans."
        },
        {
            imageLink: "https://www.xpressloan.in/assets/img/Home-renovation.png",
            heading: "SMALL HOME RENOVATIONS",
            description: "Medical emergencies are one of the most common reasons people apply for personal loans."
        },
    ]

  return (
    <div className='FourthComponent'>

        <h1>
        Apart from household needs, our loans can be used for several other purposes
        </h1>

        <div className="offeringsGrid">

            {
                gridArray.map((details, index)=>{
                    return(
                        <div
                        key={index} className='offeringsBox'>

                            <img src={details.imageLink} alt="" />

                            <h2>
                                {details.heading}
                            </h2>

                            <p>
                                {details.description}
                            </p>

                            <button
                            name='Apply_now_button'>
                                Apply now
                            </button>

                        </div>
                    )
                })
            }

        </div>

    </div>
  )
}

export default FourthComponent