import React from 'react'
import { useSelector } from 'react-redux'

const SecondComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const containerArrayDetails = activeLanguage === "Español" ? [
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Tractores guiados por GPS",
            paragraphs: "Nuestros tractores equipados con tecnología GPS permiten una navegación precisa y eficiente en el campo, reduciendo la superposición y minimizando el desperdicio de esfuerzo."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Monitoreo y control remotos",
            paragraphs: "Los agricultores pueden aprovechar los teléfonos inteligentes y las tabletas para monitorear de forma remota el rendimiento de sus equipos, ajustar la configuración e incluso solucionar problemas en tiempo real."
        },
    ]
    :
    [
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "GPS guided tractors",
            paragraphs: "Our GPS-equipped tractors enable accurate and efficient navigation in the field, reducing overlap and minimizing wasted effort."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Remote monitoring and control",
            paragraphs: "Farmers can take advantage of smartphones and tablets to remotely monitor their equipment's performance, adjust settings, and even troubleshoot problems in real time."
        },
    ]

    return (
        <div className='SecondComponent'>

            <p className='mainHeading'>
            {
          activeLanguage === "Español" ?
            "Equipos inteligentes y automatización"
            :
            "Smart equipment and automation"
        }
            </p>

            <div className='container'>

                {
                    containerArrayDetails.map((details, index) => {
                        return (
                            <div
                                className='box'
                                key={index}>
                                <div className="boxTop">
                                    <video
                                        src={details.videoLink}
                                        autoPlay
                                        loop
                                        controls={false}
                                        width="120px" // Adjust width as needed
                                        height="auto" // Adjust height as needed
                                    />
                                    <div className='boxTopRight'>
                                        <p>
                                            {details.mainHeading}
                                        </p>
                                    </div>
                                </div>
                                <div className="boxBottom">
                                    <p>
                                        {details.paragraphs}
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default SecondComponent