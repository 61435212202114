import React, { useState } from 'react'
import demoImage from '../../../assets/farmer_tract.jpg'
import demoLogo from '../../../assets/partnerBrandImage.jpg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSelector } from 'react-redux';

const PartnersOfFarmers = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const boxesArray = [
        {
            id: 1,
            partnerLogo: demoLogo,
            p: activeLanguage === "Español" ? "Reserva de tractor rentable" : 'Profitable tractor booking',
            companyName: 'Hola tractors',
            rightImage: demoImage
        },
        {
            id: 2,
            partnerLogo: demoLogo,
            p: activeLanguage === "Español" ? "Proceso de reserva simplificado" : "Simplified booking process",
            companyName: 'Hola tractors',
            rightImage: demoImage
        },
        {
            id: 3,
            partnerLogo: demoLogo,
            p: activeLanguage === "Español" ? "Tractores de dos ruedas" : "two wheel tractors",
            companyName: 'Hola tractors',
            rightImage: demoImage
        },
    ]

    const mediaQuery = window.matchMedia(`(max-width: 700px)`);

    const [query, setQuery] = useState(mediaQuery.matches);

    window.onresize = () => {
        setQuery(mediaQuery.matches);
    };

    return (

        <div className='PartnersOfFarmers'>

            <div className="mainHeading">
                {
                    activeLanguage === "Español" ?
                        "Soluciones de tractores asequibles para pequeños agricultores"
                        :
                        "Affordable tractor solutions for small farmers"
                }
            </div>

            <div className="subHeading">
                {
                    activeLanguage === "Español" ?
                        "Reserva de tractores asequible y una plataforma para el alquiler de tractores que le permitirán cultivar el éxito."
                        :
                        "Affordable tractor booking and tractor rental platform to help you cultivate success."
                }
            </div>

            <div className="container">

                {
                    boxesArray.map((box) => {
                        return (
                            <div key={box.id} className='boxContainer'>

                                <div className="left">

                                    {/* <img src={box.partnerLogo} alt="" /> */}

                                    <p1>{box.p}</p1>
                                    <div className='companyName'>{box.companyName}</div>

                                    <button>
                                        <p>
                                            {
                                                activeLanguage === "Español" ?
                                                    "Saber más"
                                                    :
                                                    "Know more"
                                            }
                                        </p>
                                    </button>

                                </div>

                                <div className="right">
                                    <img src={box.rightImage} alt="" />
                                </div>

                            </div>
                        )
                    })
                }

            </div>

        </div>

    )

}

export default PartnersOfFarmers