import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const FourthComponent = () => {

  const { activeLanguage } = useSelector(state => state.Languages)

  return (
    <div className='FourthComponent'>

      <div className='container'>

        <p className='mainHeading'>
          {
            activeLanguage === "Español" ?
              "Valoramos sus comentarios y consultas."
              :
              "We value your comments and queries."
          }
        </p>

        <p className='subHeading'>
          {
            activeLanguage === "Español" ?
              "Si tiene alguna pregunta, sugerencia o inquietud, no dude en comunicarse con nosotros."
              :
              "If you have any questions, suggestions or concerns, please do not hesitate to contact us."
          }
        </p>

        <Link to={'#'}>
          <button className=''>
            {
              activeLanguage === "Español" ?
                "Contacta con nosotros"
                :
                "Contact us"
            }
          </button>
        </Link>

      </div>

    </div>
  )
}

export default FourthComponent