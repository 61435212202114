import React from 'react'
import './NewComponent.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const NewComponent = () => {
    const { activeLanguage } = useSelector(state => state.Languages)

  return (
    <div className='MiniTractor'>

            <div className="container">

                <div className="details-box">
                    {
                        activeLanguage === "Español" ?
                        <div className="left-details">

                        <div className="left-heading">
                        El potente sistema de reservas de Hola Tractor
                        </div>

                        <div className="left-paragraph">
                        Hemos diseñado un sistema de reserva potente e intuitivo para que sea más fácil que nunca programar servicios de tractores. Nuestro sistema está diseñado para agilizar el proceso, ahorrándole tiempo y esfuerzo.
                        </div>

                        <Link to={'/Booking'}>
                            <button
                                name='know more button'>
                                Reserva ahora
                            </button>
                        </Link>

                    </div>
                    :
                    <div className="left-details">

                        <div className="left-heading">
                        Hola Tractor's powerful reservation system
                        </div>

                        <div className="left-paragraph">
                        We've designed a powerful and intuitive booking system to make it easier than ever to schedule tractor services. Our system is designed to streamline the process, saving you time and effort.
                        </div>

                        <Link to={'/Booking'}>
                            <button
                                name='know more button'>
                                Book now
                            </button>
                        </Link>

                    </div>
                    }
                    
                    <div
                        className="right-details">
                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/booking.webp"
                            alt="mini tractor" />
                    </div>

                </div>

            </div>

        </div>
  )
}

export default NewComponent