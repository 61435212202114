import React, { useState, useEffect } from 'react';
import styles from './TestimonialSlider.module.css';

const testimonials = [
  {
    id: 1,
    image: "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_1280.png",
    quote: "We hired Brooks for a website redesign, and the results were outstanding. His design sense and technical expertise are top-notch. Highly recommend his services!",
    name: "David Thompson",
    title: "CEO",
    company: "TechSprint Solutions"
  },
  {
    id: 2,
    image: "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_1280.png",
    quote: "Outstanding attention to detail and exceptional delivery time. The website exceeded our expectations!",
    name: "Sarah Miller",
    title: "Marketing Director",
    company: "InnovateTech"
  },
  {
    id: 3,
    image: "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_1280.png",
    quote: "Professional, creative, and highly skilled. The best web development experience we've had.",
    name: "Michael Chen",
    title: "Founder",
    company: "Digital Dynamics"
  },
  {
    id: 4,
    image: "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_1280.png",
    quote: "Transformed our online presence completely. Couldn't be happier with the results!",
    name: "Emma Rodriguez",
    title: "COO",
    company: "Future Systems"
  },
  {
    id: 5,
    image: "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_1280.png",
    quote: "Exceptional work quality and great communication throughout the project.",
    name: "James Wilson",
    title: "Product Manager",
    company: "Cloud Solutions"
  }
];

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        setIsTransitioning(false);
      }, 500);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const handleDotClick = (index) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(index);
      setIsTransitioning(false);
    }, 500);
  };

  return (
    <section className={styles.testimonialSection}>
      <div className={styles.testimonialContainer}>
        <div className={styles.profileImages}>
          {testimonials.map((testimonial, index) => (
            <button
              key={testimonial.id}
              onClick={() => handleDotClick(index)}
              className={`${styles.profileButton} ${index === currentIndex ? styles.active : ''}`}
            >
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className={styles.profileImage}
              />
            </button>
          ))}
        </div>

        <div className={`${styles.testimonialContent} ${isTransitioning ? styles.fadeOut : styles.fadeIn}`}>
          <blockquote className={styles.quote}>
            "{testimonials[currentIndex].quote}"
          </blockquote>
          <div className={styles.author}>
            <p className={styles.name}>{testimonials[currentIndex].name}, {testimonials[currentIndex].title}</p>
            <p className={styles.company}>{testimonials[currentIndex].company}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;

