import React from 'react'
import { useSelector } from 'react-redux'

const FirstComponent = () => {
    const { activeLanguage } = useSelector(state => state.Languages)

    const headingArray = activeLanguage === "Español" ? [
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Empresas de paisajismo",
            para: "Utilícelos para transportar materiales, nivelar terrenos, cavar zanjas y mantener grandes propiedades."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Sitios de construcción",
            para: "Pueden manejar equipos de remolque, rellenar zanjas y nivelar superficies."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Municipios",
            para: "Ideal para remoción de nieve, mantenimiento de parques y corte de césped en carreteras."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Instalaciones ecuestres",
            para: "Mantenga arenas, transporte heno y administre pastos."
        },
    ] : [
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Landscaping Businesses",
            para: "Utilize them for hauling materials, grading land, digging trenches, and maintaining large properties."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Construction Sites",
            para: "They can handle towing equipment, backfilling trenches, and leveling surfaces."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Municipalities",
            para: "Ideal for snow removal, park maintenance, and roadside mowing."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Equestrian Facilities",
            para: "Maintain arenas, haul hay, and manage pastures."
        },
    ]

    return (
        <div className='FirstComponent'>

            <div
                className='container'>

                <p className='mainHeading'>
                    {
                        activeLanguage === "Español" ?
                            "¡Los tractores utilitarios no son sólo para granjas!"
                            :
                            "Utility tractors aren't just for farms!"
                    }
                </p>


                <p className='subHeading'>
                    {
                        activeLanguage === "Español" ?
                            "Su versatilidad los convierte en activos valiosos para una variedad de aplicaciones, incluidas"
                            :
                            "Their versatility makes them valuable assets for a variety of applications, including"
                    }
                </p>

                <div
                    className='elementsContainer'>

                    <div className='left'>

                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/hola.webp"
                            alt='left side image' />

                    </div>

                    <div
                        className='right'>

                        {
                            headingArray.map((details, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='box'>

                                        <div className="box-left">
                                            <img src={details.icon} alt="" />
                                        </div>

                                        <div className="box-right">

                                            <div className="heading">
                                                {details.heading}
                                            </div>

                                            <div className="details">
                                                {details.para}
                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

            </div>

        </div>
    )
}

export default FirstComponent