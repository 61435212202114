import React, { useState } from 'react'
import { useSelector } from 'react-redux'



const BookingStepper = () => {
    const { activeLanguage } = useSelector(state => state.Languages)

    const stepperDetails = [
        {
            heading: activeLanguage === "Español" ? "Reserve su tractor con facilidad" : "Book Your Tractor with Ease",
            para: activeLanguage === "Español" ? "puede reservar un tractor cómodamente a través de nuestra aplicación móvil, sitio web o llamándonos. Simplemente proporcione su ubicación, el servicio deseado y la fecha y hora preferidas. Nuestro amigable equipo se encargará del resto, asegurándose de que usted obtenga la asistencia que necesita cuando la necesita." : "You can book a tractor conveniently through our mobile app, website, or by giving us a call. Just provide your location, desired service, and preferred date and time. Our friendly team will handle the rest, ensuring you get the assistance you need when you need it.",
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/schedule_book.webp"
        },
        {
            heading: activeLanguage === "Español" ? "Programar entrega" : "Schedule Delivery",
            para: activeLanguage === "Español" ? "Nuestro equipo trabajará estrechamente con usted para programar el tiempo de entrega que sea más conveniente para usted. Haremos los arreglos necesarios para garantizar que el tractor llegue a su granja según su conveniencia." : "Our team will work closely with you to schedule a delivery time that is most convenient for you.we'll make the necessary arrangements to ensure the tractor arrives at your farm as per your convenient.",
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/delivery.webp"
        },
        {
            heading: activeLanguage === "Español" ? "Disfrute del servicio" : "Enjoy the Service",
            para: activeLanguage === "Español" ? "una vez que nuestro tractor llegue a su granja, podrá comenzar a usarlo inmediatamente para sus necesidades agrícolas sin ningún costo inicial. Disfrute de la flexibilidad y asequibilidad de pagar solo por el tiempo de uso del tractor." : "Once our tractor arrives at your farm, you can immediately start using it for your agricultural needs without any upfront costs. Enjoy the flexibility and affordability of paying only for the time you use the tractor.",
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/enjoy_service.webp"
        }
    ]

    return (
        <div className='BookingStepper'>

            <h3>
                {
                    activeLanguage === "Español" ?
                        "Nuestro sencillo proceso para acceder al servicio de tractores de dos ruedas."
                        :
                        "Get your two wheel tractor delivered to your door step in 3 easy steps"
                }
            </h3>

            <div className="scrolling-container" style={{ scrollbarWidth: 'none' }}>

                {
                    stepperDetails.map((details, index) => {
                        return (
                            <div className="container" key={index}>

                                <div className="left">

                                    <div className="left-heading">
                                        {details.heading}
                                    </div>

                                    <div className="details">
                                        {details.para}
                                    </div>

                                </div>

                                <div className="right">
                                    <img
                                        src={details.imageLink}
                                        alt="qr code to book a two wheeler tractor" />
                                </div>

                            </div>
                        )
                    })
                }

            </div>

            {/* <div className="container">

                <div className="left">

                    <div className="left-heading">
                        Heading
                    </div>

                    <div className="details">
                        Sub Heading
                    </div>

                </div>

                <div className="right">
                    <img
                        src={QRDemo}
                        alt="qr code to book a two wheeler tractor" />
                </div>

            </div> */}

        </div>
    )
}

export default BookingStepper