import React, { useState, useEffect } from "react";
import styles from "./TalkWithMe.module.css";

const TalkWithMe = () => {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.container}>
      <p className={styles.subtitle}>Have a project?</p>
      <h1 className={styles.title}>Let's talk with me</h1>
      <button className={styles.button}>Talk with me</button>
      <p className={styles.time}>
        My local time <span className={styles.clock}>{currentTime}</span>
      </p>
    </div>
  );
};

export default TalkWithMe;
