import React, { useEffect, useState } from 'react'
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { useSelector } from 'react-redux'

const ThirdComponent = () => {
    const [activeArrayElement, setActiveArrayElement] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            mainHeading: "Costos reducidos",
            subHeading: "Elimine la carga de los costos de propiedad, como mantenimiento, almacenamiento y depreciación."
        },
        {
            mainHeading: "Eficiencia incrementada",
            subHeading: "Alquile exactamente el equipo que necesita para el trabajo, garantizando una eficiencia óptima para su proyecto."
        },
        {
            mainHeading: "Tranquilidad de espíritu",
            subHeading: "Nuestros tractores bien mantenidos están listos para usar, para que usted pueda concentrarse en hacer el trabajo sin preocuparse por averías."
        },

    ]
        :
        [
            {
                mainHeading: "Reduced Costs",
                subHeading: "Eliminate the burden of ownership costs like maintenance, storage, and depreciation."
            },
            {
                mainHeading: "Increased Efficiency",
                subHeading: "Rent exactly the equipment you need for the job, ensuring optimal efficiency for your project."
            },
            {
                mainHeading: "Peace of Mind",
                subHeading: "Our well-maintained tractors are ready to use, so you can focus on getting the job done without worrying about breakdowns."
            },

        ]

    function changeContext() {
        setActiveArrayElement(prev => prev + 1 === componentArray.length ? 0 : prev + 1)
    }

    useEffect(() => {
        const intervalSet = setInterval(() => {
            changeContext()
        }, 3000)
        return () => clearInterval(intervalSet);
    }, [])

    return (
        <div className='ThirdComponent'>

            <div className="container">

                <div className="left-container">

                    <p className='mainHeading'>
                        {
                            activeLanguage === "Español" ?
                                "Ventaja de pago por uso"
                                :
                                "Pay-As-You-Go Advantage"
                        }
                    </p>

                    <div className="paragraphsContainer">

                        {
                            componentArray.map((details, index) => {
                                return (
                                    <div className='box' key={index}>

                                        <div className='box-top'>
                                            <AcUnitIcon />
                                            <p>
                                                {details.mainHeading}
                                            </p>
                                        </div>

                                        <div className="box-middle">
                                            <div
                                                className="inside-box-middle"
                                                style={{
                                                    display: activeArrayElement === index ? 'block' : 'none'
                                                }} />
                                        </div>

                                        <div className="box-bottom" style={{
                                            display: activeArrayElement === index ? 'block' : 'none'
                                        }}>
                                            <p>
                                                {details.subHeading}
                                            </p>
                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

                <div className="right-container">
                    <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractor_two.webp" alt="Third component" />
                </div>

            </div>

        </div>
    )
}

export default ThirdComponent