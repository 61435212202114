import { useRef } from 'react'
import EastIcon from '@mui/icons-material/East';
import ProcessImage from "../../assets/booking/process_image.png"
import { useSelector } from 'react-redux';

const Process = () => {
    let scrl = useRef(null);
    const { activeLanguage } = useSelector(state => state.Languages)

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
    };

    return (
        <div className='process'>

            {
                activeLanguage === "Español" ?
                    <div className="container">

                        <div className="left">
                            <h2>
                                Nuestro proceso de reserva
                            </h2>
                            <div className="subHeading">
                                CON HOLATRACTOR?
                                <div className="arrowIcon">
                                    <EastIcon />
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <p>
                                En HolaTractor, hemos diseñado un proceso de reserva fácil de usar para que a los agricultores les resulte fácil acceder a los servicios de tractores.
                            </p>
                        </div>

                    </div>
                    :
                    <div className="container">

                        <div className="left">
                            <h2>
                                Our reservation process
                            </h2>
                            <div className="subHeading">
                                With Hola Tractor
                                <div className="arrowIcon">
                                    <EastIcon />
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <p>
                                At HolaTractor, we have designed a user-friendly booking process to make it easy for farmers to access tractor services.
                            </p>
                        </div>

                    </div>
            }

            {
                activeLanguage === "Español" ?
                    <div className="imagecontainer">

                        <img className="processImage" alt='process image' src={ProcessImage} />

                        <div className='box'>

                            <div className="bg-wrapper">
                                <div className='num-content'>
                                    01
                                </div>
                            </div>

                            <div className='content'>
                                <div className='arrow'>
                                    <EastIcon />
                                </div>
                                <p>
                                    Busque y encuentre un tractor cercano
                                </p>
                            </div>

                        </div>

                        <div className='box'>

                            <div className="bg-wrapper">
                                <div className='num-content'>
                                    02
                                </div>
                            </div>

                            <div className='content'>
                                <div className='arrow'>
                                    <EastIcon />
                                </div>
                                <p>
                                    Seleccione y reserve un tractor
                                </p>
                            </div>

                        </div>

                        <div className='box'>

                            <div className="bg-wrapper">
                                <div className='num-content'>
                                    03
                                </div>
                            </div>

                            <div className='content'>
                                <div className='arrow'>
                                    <EastIcon />
                                </div>
                                <p>
                                    Pago seguro
                                </p>
                            </div>

                        </div>

                        <div className='box'>

                            <div className="bg-wrapper">
                                <div className='num-content'>
                                    04
                                </div>
                            </div>

                            <div className='content'>
                                <div className='arrow'>
                                    <EastIcon />
                                </div>
                                <p>
                                    Entrega de tractores
                                </p>
                            </div>

                        </div>

                    </div>
                    :
                    <div className="imagecontainer">

                        <img className="processImage" alt='process image' src={ProcessImage} />

                        <div className='box'>

                            <div className="bg-wrapper">
                                <div className='num-content'>
                                    01
                                </div>
                            </div>

                            <div className='content'>
                                <div className='arrow'>
                                    <EastIcon />
                                </div>
                                <p>
                                    Search and find a tractor nearby
                                </p>
                            </div>

                        </div>

                        <div className='box'>

                            <div className="bg-wrapper">
                                <div className='num-content'>
                                    02
                                </div>
                            </div>

                            <div className='content'>
                                <div className='arrow'>
                                    <EastIcon />
                                </div>
                                <p>
                                    Select and reserve a tractor
                                </p>
                            </div>

                        </div>

                        <div className='box'>

                            <div className="bg-wrapper">
                                <div className='num-content'>
                                    03
                                </div>
                            </div>

                            <div className='content'>
                                <div className='arrow'>
                                    <EastIcon />
                                </div>
                                <p>
                                    Secure payment
                                </p>
                            </div>

                        </div>

                        <div className='box'>

                            <div className="bg-wrapper">
                                <div className='num-content'>
                                    04
                                </div>
                            </div>

                            <div className='content'>
                                <div className='arrow'>
                                    <EastIcon />
                                </div>
                                <p>
                                    Tractor delivery
                                </p>
                            </div>

                        </div>

                    </div>
            }

            <div className="imagecontainerres">

                <div className='below-headings-section'>

                    <div className="scrolling-button-section">

                        <div className="Button" onClick={() => { slide(-150) }}>
                            <i className="fa-solid fa-arrow-left"></i>
                        </div>

                        <div className="Button" onClick={() => { slide(+150) }}>
                            <i className="fa-solid fa-arrow-right"></i>
                        </div>

                    </div>

                </div>

                {
                    activeLanguage === "Español" ?
                        <div className="image-container" style={{ scrollbarWidth: 'none' }} ref={scrl}>

                            <div className='box'>

                                <div className="bg-wrapper">
                                    <div className='num-content'>
                                        01
                                    </div>
                                </div>

                                <div className='content'>
                                    <div className='arrow'>
                                        <EastIcon />
                                    </div>
                                    <p>
                                        Busque y encuentre un tractor cercano
                                    </p>
                                </div>

                            </div>

                            <div className='box'>

                                <div className="bg-wrapper">
                                    <div className='num-content'>
                                        02
                                    </div>
                                </div>

                                <div className='content'>
                                    <div className='arrow'>
                                        <EastIcon />
                                    </div>
                                    <p>
                                        Seleccione y reserve un tractor
                                    </p>
                                </div>

                            </div>

                            <div className='box'>

                                <div className="bg-wrapper">
                                    <div className='num-content'>
                                        03
                                    </div>
                                </div>

                                <div className='content'>
                                    <div className='arrow'>
                                        <EastIcon />
                                    </div>
                                    <p>
                                        Pago seguro
                                    </p>
                                </div>

                            </div>

                            <div className='box'>

                                <div className="bg-wrapper">
                                    <div className='num-content'>
                                        04
                                    </div>
                                </div>

                                <div className='content'>
                                    <div className='arrow'>
                                        <EastIcon />
                                    </div>
                                    <p>
                                        Entrega de tractores
                                    </p>
                                </div>

                            </div>

                        </div>
                        :
                        <div className="image-container" style={{ scrollbarWidth: 'none' }} ref={scrl}>

                            <div className='box'>

                                <div className="bg-wrapper">
                                    <div className='num-content'>
                                        01
                                    </div>
                                </div>

                                <div className='content'>
                                    <div className='arrow'>
                                        <EastIcon />
                                    </div>
                                    <p>
                                        Search and find a tractor nearby
                                    </p>
                                </div>

                            </div>

                            <div className='box'>

                                <div className="bg-wrapper">
                                    <div className='num-content'>
                                        02
                                    </div>
                                </div>

                                <div className='content'>
                                    <div className='arrow'>
                                        <EastIcon />
                                    </div>
                                    <p>
                                        Select and reserve a tractor
                                    </p>
                                </div>

                            </div>

                            <div className='box'>

                                <div className="bg-wrapper">
                                    <div className='num-content'>
                                        03
                                    </div>
                                </div>

                                <div className='content'>
                                    <div className='arrow'>
                                        <EastIcon />
                                    </div>
                                    <p>
                                        Secure payment
                                    </p>
                                </div>

                            </div>

                            <div className='box'>

                                <div className="bg-wrapper">
                                    <div className='num-content'>
                                        04
                                    </div>
                                </div>

                                <div className='content'>
                                    <div className='arrow'>
                                        <EastIcon />
                                    </div>
                                    <p>
                                        Tractor delivery
                                    </p>
                                </div>

                            </div>

                        </div>
                }

            </div>

        </div>
    )
}

export default Process