import VisitBox from "./VisitBox"
import Location from "../../assets/booking/location-Icon.png"
import Phone from "../../assets/booking/phone-call.png"
import Mail from "../../assets/booking/mail.png"
import { useSelector } from "react-redux"

const Visit = () => {
    const { activeLanguage } = useSelector(state => state.Languages)
    return (
        <div
        className="Visit">

            <div
            className="container">

                <VisitBox icon={Location} headingOne={activeLanguage === "Español" ?"Mapeo de campo" : "Field mapping"} headingTwo={activeLanguage === "Español" ? "Mida y registre el tamaño de sus campos para un uso óptimo del tractor." : "Measure and record the size of your fields for optimal tractor use."} />
                <VisitBox icon={Phone} headingOne={activeLanguage === "Español" ? "Seguimiento" : "Follow-up"} headingTwo={activeLanguage === "Español" ? "Supervise la ubicación en tiempo real de su tractor reservado" : "Monitor the real-time location of your reserved tractor"} />
                <VisitBox icon={Mail} headingOne={activeLanguage === "Español" ? "Reserva fácil" : "Easy reservation"} headingTwo={activeLanguage === "Español" ? "Reserve tractores cómodamente a través de una plataforma en línea fácil de usar." : "Book tractors conveniently through an easy-to-use online platform."} />

            </div>

            <div className="resContainer">
            <VisitBox icon={Location} headingOne={activeLanguage === "Español" ? "Mapeo de campo" : "Field mapping"} headingTwo={activeLanguage === "Español" ? "Mida y registre el tamaño de sus campos para un uso óptimo del tractor." : "Measure and record the size of your fields for optimal tractor use."} />
            </div>

            <div className="resContainer">
            <VisitBox icon={Phone} headingOne={activeLanguage === "Español" ? "Seguimiento" : "Follow-up"} headingTwo={activeLanguage === "Español" ? "Supervise la ubicación en tiempo real de su tractor reservado" : "Monitor the real-time location of your reserved tractor"} />
            </div>

            <div className="resContainer">
            <VisitBox icon={Mail} headingOne={activeLanguage === "Español" ? "Reserva fácil" : "Easy reservation"} headingTwo={activeLanguage === "Español" ? "Reserve tractores cómodamente a través de una plataforma en línea fácil de usar." : "Book tractors conveniently through an easy-to-use online platform."} />
            </div>

        </div>
    )
}

export default Visit