import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const SecondComponent = () => {

    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const contentArray = activeLanguage === "Español" ? [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Sistemas de guía GPS",
            para: "Aumente la precisión y reduzca la superposición durante las operaciones de plantación, fumigación y cosecha."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Control automático de implementos",
            para: "Automatice las funciones del implemento para lograr un funcionamiento más suave y reducir la fatiga del operador."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Telemática y monitoreo de datos",
            para: "Realice un seguimiento del rendimiento del tractor, el consumo de combustible y los programas de mantenimiento de forma remota para mejorar la gestión de la flota."
        },
    ]
    :
    [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "GPS Guidance Systems",
            para: "Increase accuracy and reduce overlap during planting, spraying, and harvesting operations."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Automatic Implement Control",
            para: "Automate implement functions for smoother operation and reduced operator fatigue."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Telematics & Data Monitoring",
            para: "Track tractor performance, fuel consumption, and maintenance schedules remotely for improved fleet management."
        },
    ]

    return (
        <div className='SecondComponent'>

            <div className="headings">

                <h2>
                    {
                        activeLanguage === "Español" ?
                            "Tecnología en tractores comerciales"
                            :
                            "Technology at Commercial Tractors"
                    }
                </h2>

                <p>
                    {
                        activeLanguage === "Español" ?
                            "Muchos de nuestros tractores comerciales vienen equipados con tecnologías avanzadas para mejorar la eficiencia y la precisión."
                            :
                            "Many of our commercial tractors come equipped with advanced technologies to enhance efficiency and precision"
                    }
                </p>

            </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className='box'
                                        key={index}
                                        onClick={() => { setActiveContnentHeading(index) }}>
                                        <div
                                            className='heading'
                                            style={{
                                                color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                            }}>
                                            <p>{index + 1 > 9 ? index + 1 : `0${index + 1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img
                            src={contentArray[activeContentHeading].img}
                            alt="" />

                        <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                        </div>

                        <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default SecondComponent