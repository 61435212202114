import React from 'react'
import { useSelector } from 'react-redux'

const SecondComponent = () => {
    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='ServiceFooter'>

            <div className="left">

                <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/iotenabled.webp" alt="" />

            </div>


            {
                activeLanguage === "Español" ?
                    <div className="right">

                        <div className="headingOne">
                            Fácil instalación e interfaz fácil de usar
                        </div>

                        <div className="subHeading">
                            Nuestro dispositivo IoT compacto y duradero se instala fácilmente en su tractor (es posible que haya disponible una instalación profesional). La aplicación móvil y la plataforma en línea proporcionan una interfaz fácil de usar para acceder a todos los datos de seguimiento, informes y alertas. ¡No se requiere experiencia técnica!
                        </div>

                    </div>
                    :
                    <div className="right">

                        <div className="headingOne">
                            Easy Installation and User-Friendly Interface
                        </div>

                        <div className="subHeading">
                            Our compact and durable IoT device is easily installed on your tractor (professional installation may be available). The mobile app and online platform provide a user-friendly interface for accessing all tracking data, reports, and alerts. No technical expertise is required!
                        </div>

                    </div>
            }

        </div>
    )
}

export default SecondComponent