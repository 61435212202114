import React from 'react'
import './Farmer.scss'
import topBackgroundComponent from '../../../assets/farmerpageTopBackground.jpg'
import PartnersOfFarmers from './PartnersOfFarmers'
import Benefits from './Benefits'
import SearchBar from '../../../components/Home/SearchBar/SearchBar'
import { useSelector } from 'react-redux'

const Farmer = () => {

  const { activeLanguage } = useSelector(state => state.Languages)

  const belowBenifitsArray = [
    {
      id: 1,
      mainHeading: '10K+',
      subHeading: activeLanguage === "Español" ? 'Agricultores' : 'Farmers'
    },
    {
      id: 2,
      mainHeading: '500 +',
      subHeading: activeLanguage === "Español" ? 'Tractores' : 'Tractors'
    },
    {
      id: 3,
      mainHeading: '3000+',
      subHeading: activeLanguage === "Español" ? 'Servicios' : 'Services'
    },
  ]

  return (
    <div className='Farmer'>

      <div className="Container">

        <div
          className="topSearchComponent" >

          <div className="container-top">

            <div className="left">

              <p>
                {
                  activeLanguage === "Español" ?
                    "¿Sueñas con una granja más eficiente y productiva? No busques más allá de Holatractor, el innovador tractor de dos ruedas que revoluciona la agricultura a pequeña escala. En Holatractor, nos apasiona empoderar a los agricultores para que logren un mayor éxito, y Holatractor es un cambio de juego para las pequeñas granjas"
                    :
                    "Do you dream of a more efficient and productive farm? Look no further than Holatractor, the innovative two-wheeled tractor revolutionizing small-scale farming. At Holatractor, we are passionate about empowering farmers to achieve greater success, and Holatractor is a game changer for small farms."
                }
              </p>
            </div>

            <div className="right">
              <img src={topBackgroundComponent} alt="" />
            </div>

          </div>

          <SearchBar />

        </div>

        <PartnersOfFarmers />

        <Benefits />

        <div className="belowBenifits">
          {
            belowBenifitsArray.map((box) => {
              return (
                <div key={box.id} className='belowBenifitsMainContainer'>
                  <div>{box.mainHeading}</div>
                  <div>{box.subHeading}</div>
                </div>
              )
            })
          }
        </div>

      </div>

    </div>
  )
}

export default Farmer