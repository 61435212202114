import React from 'react'
import EastIcon from '@mui/icons-material/East';
import { useSelector } from 'react-redux';

const About = () => {
    const { activeLanguage } = useSelector(state => state.Languages)
    return (
        <div className='about'>
            {
                activeLanguage === "Español" ?
                    <div className="container">

                        <div className="left">
                            <h2>
                                INTRODUCCIÓN
                            </h2>
                            <div className="subHeading">
                                Hola Tractor
                                <div className="arrowIcon">
                                    <EastIcon />
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <p>
                                HolaTractor es un proveedor líder de servicios innovadores de reserva de tractores que permiten a los agricultores mejorar sus operaciones agrícolas y contribuir a un futuro más sostenible. Con un fuerte compromiso con la tecnología y la gestión ambiental, ofrecemos una amplia gama de soluciones diseñadas para optimizar las prácticas agrícolas y mitigar los impactos del cambio climático.
                            </p>
                        </div>

                    </div>
                    :
                    <div className="container">

                        <div className="left">
                            <h2>
                                INTRODUCTION
                            </h2>
                            <div className="subHeading">
                                Hola Tractor
                                <div className="arrowIcon">
                                    <EastIcon />
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <p>
                                HolaTractor is a leading provider of innovative tractor booking services that enable farmers to improve their farming operations and contribute to a more sustainable future. With a strong commitment to technology and environmental management, we offer a wide range of solutions designed to optimize agricultural practices and mitigate the impacts of climate change.
                            </p>
                        </div>

                    </div>
            }

            <div className="container">

                <div className="left">
                    <img
                        alt="about image"
                        src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/about.webp" />
                </div>
                {
                    activeLanguage === "Español" ?
                        <div className="right">
                            <div className="headingContainer">
                                <h2>
                                    ACERCA DE
                                </h2>
                                <div className="subHeading">
                                    HOLA TRACTOR
                                </div>
                            </div>
                            <p>
                                HolaTractor es una empresa tecnológica pionera dedicada a transformar el panorama agrícola a través de servicios innovadores de reserva de tractores y soluciones agrícolas inteligentes. Aprovechamos el poder de la IoT y la tecnología de software para mejorar la eficiencia, la sostenibilidad y la resiliencia climática en las prácticas agrícolas.
                            </p>
                            <button
                                name='build your world button'>
                                CONTÁCTENOS
                            </button>
                        </div>
                        :
                        <div className="right">
                            <div className="headingContainer">
                                <h2>
                                    ABOUT
                                </h2>
                                <div className="subHeading">
                                    HOLA TRACTOR
                                </div>
                            </div>
                            <p>
                                HolaTractor is a pioneering technology company dedicated to transforming the agricultural landscape through innovative tractor booking services and smart farming solutions. We harness the power of IoT and software technology to improve efficiency, sustainability and climate resilience in agricultural practices.
                            </p>
                            <button
                                name='build your world button'>
                                CONTACT US
                            </button>
                        </div>
                }



            </div>

        </div>
    )
}

export default About