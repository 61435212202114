import React from 'react'
import './FarmersBooking.scss'
import Banner from './Banner'
import SeventhComponent from './SeventhComponent'
import SecondComponent from './SecondComponent'
import FourthComponent from './FourthComponent'

const FarmersBooking = () => {
  return (
    <div className='FarmersBooking'>

      <Banner />

      <SeventhComponent />

      <SecondComponent />

      <FourthComponent />

    </div>
  )
}

export default FarmersBooking