import { configureStore } from '@reduxjs/toolkit'
import UserSlice from './userSlice'
import userLogInComponentShow from './userLogInComponentShow'
import cookieComponentShow from './cookieComponentShow'
import languageReducer from './languageSlice'
import ContactPageShow from './ContactPageShow'
import Languages from './Languages'
import SelectLanguageComShow from './SelectLanguageComShow'

export default configureStore({
    reducer:{
        UserSlice: UserSlice,
        userLogInComponentShow: userLogInComponentShow,
        cookieComponentShow: cookieComponentShow,
        language: languageReducer,
        ContactPageShow: ContactPageShow,
        Languages: Languages,
        SelectLanguageComShow: SelectLanguageComShow
    }
})