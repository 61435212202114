import React from 'react'

const ThirdComponent = () => {
    return (
        <div className="ThirdComponent">

            <img src="https://wallpapercave.com/wp/wp13121058.jpg" alt="" />

            <div className="container">

                <h1>
                    Lorem ipsum dolor sit amet.
                </h1>

                <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, atque.
                </p>

                <div className="boxContainer">

                    <div className="box">

                        <h1>5000</h1>

                        <p>
                            Lorem ipsum dolor sit amet.
                        </p>

                    </div>

                    <div className="box">

                        <h1>5000</h1>

                        <p>
                            Lorem ipsum dolor sit amet.
                        </p>

                    </div>

                    <div className="box">

                        <h1>5000</h1>

                        <p>
                            Lorem ipsum dolor sit amet.
                        </p>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default ThirdComponent