import React, { useState } from "react";
import "./navbar.scss";
import Logo from "../../../utils/nnacres_white_v2.png";
import AccountHover from "./UserHover/AccountHover";
import ForBuilders from "./ForBuilders/ForBuilders";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ForComunidad from "./ForBuilders/ForComunidad";
import ForAgent from "./ForBuilders/ForAgent";
import ForInvestor from "./ForBuilders/ForInvestor";
import Login from "../../Login/Login";
import Cookie from "../../Cookie/Cookie";
import { useDispatch, useSelector } from 'react-redux';
import LanguageOptions from './LanguageOptions'
import { updateSelectLanguageComShowToFalse, updateSelectLanguageComShowToTrue } from "../../../redux/SelectLanguageComShow";

const Navbar = ({ bgTransparent }) => {
  const [accountHover, setAccountHover] = useState(false);
  const [builderHover, setBuilderHover] = useState(false);
  const [ownerHover, setOwnerHover] = useState(false);
  const [tenantHover, setTenantHover] = useState(false);
  const [insightsHover, setInsightsHover] = useState(false);
  const [menuHandler, setMenuHandler] = useState(true);
  const [resNavActiveMenu, setResNavActiveMenu] = useState(0)

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch()
  const activeLanguage = useSelector(state => state.Languages)
  const selectLanguageComShow = useSelector(state => state.SelectLanguageComShow)

  const OpenDialogBox = () => {
    setAccountHover(!false);
  };
  const CloseDialogBox = () => {
    setAccountHover(!true);
  };

  const OpenBuilderBox = () => {
    setBuilderHover(true);
  };
  const CloseBuilderBox = () => {
    setBuilderHover(false);
  };

  const OpenOwnerBox = () => {
    setOwnerHover(true);
  };
  const CloseOwnerBox = () => {
    setOwnerHover(false);
  };

  const OpenTenantBox = () => {
    setTenantHover(true);
  };
  const CloseTenantBox = () => {
    setTenantHover(false);
  };

  const OpenInsightBox = () => {
    setInsightsHover(true);
  };
  const CloseInsightBox = () => {
    setInsightsHover(false);
  };

  const OpenMenu = () => {
    setMenuHandler(!menuHandler);
  };

  const buildersLinks = activeLanguage === "Español" ? [
    {
      heading: "dueño del tractor",
      link: "denu-del-tractor",
    },
    {
      heading: "Tractor nuevo",
      link: "#",
    },
    {
      heading: "Reserva de tractores",
      link: "#",
    },
    {
      heading: "Tractor comerciales",
      link: "#",
    },
    {
      heading: "Servicios comerciales",
      link: "#",
    },
    {
      heading: "Finanzas",
      link: "#",
    },
    {
      heading: "Servicios de tractores",
      link: "service"
    },
    {
      heading: "Mecanización agrícola",
      link: "tractor-assembly-service"
    },
    {
      heading: "Servicios de seguimiento de tractores",
      link: "servicios-de-seguimineto"
    },
    {
      heading: "servicios semanales o mensuales",
      link: "servicios-mensuales"
    },
    {
      heading: "Tractores compactos",
      link: "new-tractor/compact-tractors"
    },
    {
      heading: "Tractores agrícolas",
      link: "new-tractor/agricultural-tractors"
    },
    {
      heading: "Tractores utilitarios",
      link: "new-tractor/utility-tractors"
    },
    {
      heading: "Tractores especiales",
      link: "new-tractor/commercial-tractors"
    },
    {
      heading: "Reserva y Alquiler",
      link: "tractor-reservations/reservation-and-rental"
    },
    {
      heading: "Reserva comunitaria",
      link: "tractor-reservations/community-booking"
    },
    {
      heading: "Reserva de granjero",
      link: "tractor-reservations/farmers-booking"
    },
    {
      heading: "Reserva comercial",
      link: "tractor-reservations/commercial-booking"
    },
    {
      heading: "Incorporación de Saas",
      link: "#"
    },
    {
      heading: "Gestión de flotas",
      link: "#"
    },
    {
      heading: "arrendar el tractor",
      link: "#"
    },
    {
      heading: "Personalizar requisito",
      link: "#"
    },
    {
      heading: "Préstamo de tractor",
      link: "#"
    },
    {
      heading: "Préstamos para equipos",
      link: "#"
    },
    {
      heading: "Programa de reembolso",
      link: "#"
    },
    {
      heading: "Elegibilidad para préstamos",
      link: "#"
    },
    {
      heading: "Calculadora de préstamo",
      link: "#"
    },
  ] : [
    {
      heading: "Tractor owner",
      link: "denu-del-tractor",
    },
    {
      heading: "New tractor",
      link: "#",
    },
    {
      heading: "Tractor Reservation",
      link: "#",
    },
    {
      heading: "commercial tractor",
      link: "#",
    },
    {
      heading: "Commercial services",
      link: "#",
    },
    {
      heading: "Finance",
      link: "#",
    },
    {
      heading: "Tractor services",
      link: "service"
    },
    {
      heading: "Agricultural mechanization",
      link: "tractor-assembly-service"
    },
    {
      heading: "Tractor Tracking Services",
      link: "servicios-de-seguimineto"
    },
    {
      heading: "Weekly or monthly services",
      link: "servicios-mensuales"
    },
    {
      heading: "Compact tractors",
      link: "new-tractor/compact-tractors"
    },
    {
      heading: "Agricultural tractors",
      link: "new-tractor/agricultural-tractors"
    },
    {
      heading: "Utility tractors",
      link: "new-tractor/utility-tractors"
    },
    {
      heading: "Special tractors",
      link: "new-tractor/commercial-tractors"
    },
    {
      heading: "Reservation and Rental",
      link: "tractor-reservations/reservation-and-rental"
    },
    {
      heading: "Community booking",
      link: "tractor-reservations/community-booking"
    },
    {
      heading: "Farmer's booking",
      link: "tractor-reservations/farmers-booking"
    },
    {
      heading: "Commercial booking",
      link: "tractor-reservations/commercial-booking"
    },
    {
      heading: "SaaS Incorporation",
      link: "#"
    },
    {
      heading: "Fleet management",
      link: "#"
    },
    {
      heading: "Rent the tractor",
      link: "#"
    },
    {
      heading: "Customize requirement",
      link: "#"
    },
    {
      heading: "Tractor loan",
      link: "#"
    },
    {
      heading: "Equipment loans",
      link: "#"
    },
    {
      heading: "Refund program",
      link: "#"
    },
    {
      heading: "Loan Eligibility",
      link: "#"
    },
    {
      heading: "Loan Calculator",
      link: "#"
    },
  ]

  return (
    <div
      className={`${location.pathname === "/"
        ? "navbar-container"
        : "navbar-container nav-bg"
        }`}
      style={{
        backgroundColor: bgTransparent ? "transparent" : "#AB0F0C",
      }}
    >
      <div className="navbar-section">
        <div className="nav-left">
          <p onClick={OpenMenu}>
            <i className="fa-solid fa-bars"></i>
          </p>

          <div className="logo">
            <img src={Logo} alt="Logo" onClick={() => navigate("/")} />
          </div>
        </div>
        <div className="nav-right">
          <ul className={`${menuHandler ? "ul" : "ul ul-list"}`}>
            <div className="nav-list">
              <li>
                <Link to="/farmer">
                  {
                    activeLanguage.activeLanguage === "Español" ?
                      "Agricultores"
                      :
                      "Farmers"
                  }
                </Link>
              </li>
            </div>
            <div
              className="nav-list"
              onMouseEnter={OpenTenantBox}
              onMouseLeave={CloseTenantBox}
            >
              <li>
                <a href="#">
                  {
                    activeLanguage.activeLanguage === "Español" ?
                      "Servicios"
                      :
                      "Services"
                  }
                </a>
              </li>
              <div
                className={`${tenantHover ? "nav-tenant" : "nav-tenant nav-hide"
                  }`}
              >
                <ForBuilders />
              </div>
            </div>
            <div
              className="nav-list"
              onMouseEnter={OpenOwnerBox}
              onMouseLeave={CloseOwnerBox}
            >
              <li>
                <a href="#">
                  {
                    activeLanguage.activeLanguage === "Español" ?
                      "Comunidad"
                      :
                      "Community"
                  }
                </a>
              </li>
              <div
                className={`${ownerHover ? "nav-owner" : "nav-owner nav-hide"}`}
              >
                <ForComunidad />
              </div>
            </div>
            <div
              className="nav-list"
              onMouseEnter={OpenBuilderBox}
              onMouseLeave={CloseBuilderBox}
            >
              <li>
                <a href="#">
                  {
                    activeLanguage.activeLanguage === "Español" ?
                      "Agente / Operadores"
                      :
                      "Agent/Operators"
                  }
                </a>
              </li>

              <div
                className={`${builderHover ? "nav-builder" : "nav-builder nav-hide"
                  }`}
              >
                <ForAgent />
              </div>
            </div>
            <div
              className="nav-list"
              onMouseEnter={OpenInsightBox}
              onMouseLeave={CloseInsightBox}
            >
              <li>
                <a href="#">
                  {
                    activeLanguage.activeLanguage === "Español" ?
                      "Inversores"
                      :
                      "Investors"
                  }
                </a>
              </li>
              <div
                className={`${insightsHover ? "nav-insight" : "nav-insight nav-hide"
                  }`}
              >
                <ForInvestor />
              </div>
            </div>
            <div className="nav-btn" onClick={() => { selectLanguageComShow ? dispatch(updateSelectLanguageComShowToFalse()) : dispatch(updateSelectLanguageComShowToTrue()) }}>
              <button name="language_selection_button">
                {
                  activeLanguage.activeLanguage === "Español" ?
                    "Seleccione el idioma"
                    :
                    "Select language"
                }
              </button>

              <div
                style={{
                  position: 'absolute'
                }}>
                {
                  selectLanguageComShow &&
                  <LanguageOptions />
                }
              </div>
            </div>
          </ul>
          {/* <div className="nav-btn">
            <button onClick={() => navigate("/search/123")}>Asocia tu aquí</button>
          </div> */}
          <div
            className="user-hover"
            onMouseEnter={OpenDialogBox}
            onMouseLeave={CloseDialogBox}
          >
            <div className="user-account">
              <p>
                <i className="fa-solid fa-user"></i>
              </p>
              <span>
                <i className="fa-solid fa-chevron-down"></i>
              </span>
            </div>
            <div className={`${accountHover ? "user-show" : "user-show hide"}`}>
              <AccountHover />
            </div>
          </div>
        </div>

        <div
          className={!menuHandler ? "resNavShow resNav" : "resNavHide resNav"}
          style={{
            position: 'absolute',
            top: '12vh',
            left: '0px',
            width: "300px",
            height: "86vh",
          }}>

          <div className="left">
            <Link to="/farmer" style={{ textDecoration: 'none', color: "white" }}>
              {
                activeLanguage.activeLanguage === "Español" ?
                  "Agricultores"
                  :
                  "Farmers"
              }
            </Link>
            <div onClick={() => { setResNavActiveMenu(1) }}>
              {
                activeLanguage.activeLanguage === "Español" ?
                  "Servicios"
                  :
                  "Services"
              }
            </div>
            <div onClick={() => { setResNavActiveMenu(2) }}>
              {
                activeLanguage.activeLanguage === "Español" ?
                  "Comunidad"
                  :
                  "Community"
              }
            </div>
            <div onClick={() => { setResNavActiveMenu(3) }}>
              {
                activeLanguage.activeLanguage === "Español" ?
                  "Agente / Operadores"
                  :
                  "Agent/Operators"
              }
            </div>
            <div onClick={() => { setResNavActiveMenu(4) }}>
              {
                activeLanguage.activeLanguage === "Español" ?
                  "Inversores"
                  :
                  "Investors"
              }
            </div>
          </div>

          <div className="right">
            {
              resNavActiveMenu === 1 && buildersLinks.map((details, i) => {
                return (
                  <Link to={details.link} style={{ textDecoration: 'none', color: "white" }}>
                    {details.heading}
                  </Link>
                )
              })
            }
            {
              resNavActiveMenu === 2 && activeLanguage === "Español" &&
              <>
                <Link to={'/comunidad/post-requirement-of-tractor'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>Poste requisito de tractor</p>
                </Link>
                <Link to={'/comunidad/beneficio-tecnológico-para-la-agricultura'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>Beneficio tecnológico para la agricultura</p>
                </Link>
                <Link to={'/comunidad/reuniones-communitrates'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>Reuniones comunitarias</p>
                </Link>
                <Link to={'/comunidad/consejos-de-cultivo'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>Consejos de cultivo</p>
                </Link>
              </>
            }{
              resNavActiveMenu === 2 && activeLanguage !== "Español" &&
              <>
                <Link to={'/comunidad/post-requirement-of-tractor'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>Tractor Requirement Pole</p>
                </Link>
                <Link to={'/comunidad/beneficio-tecnológico-para-la-agricultura'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>Technological benefit for agriculture</p>
                </Link>
                <Link to={'/comunidad/reuniones-communitrates'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>Community meetings</p>
                </Link>
                <Link to={'/comunidad/consejos-de-cultivo'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>Growing Tips</p>
                </Link>
              </>
            }
            {
              resNavActiveMenu === 3 && activeLanguage === "Español" &&
              <>
                <Link to={'/agente/servicios-de-soporte-de-agentes'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>
                    Servicios de soporte de agentes
                  </p>
                </Link>
                <Link to={'/agente/reparación-de-tractores'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>
                    Reparación de tractores
                  </p>
                </Link>
                <Link to={'/agente/mesa-de-ayuda'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>
                    Mesa de ayuda
                  </p>
                </Link>
                <Link to={'/agente/servicios-de-reparacion-de-escalones'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>
                    Servicios de reparación de escalones
                  </p>
                </Link>
              </>
            }{
              resNavActiveMenu === 3 && activeLanguage !== "Español" &&
              <>
                <Link to={'/agente/servicios-de-soporte-de-agentes'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>
                    Agent Support Services
                  </p>
                </Link>
                <Link to={'/agente/reparación-de-tractores'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>
                    Tractor repair
                  </p>
                </Link>
                <Link to={'/agente/mesa-de-ayuda'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>
                    Help Desk
                  </p>
                </Link>
                <Link to={'/agente/servicios-de-reparacion-de-escalones'} style={{ textDecoration: 'none', color: "white" }}>
                  <p>
                    Step Repair Services
                  </p>
                </Link>
              </>
            }

          </div>

        </div>
      </div>

      <Login />
      <Cookie />
    </div>
  );
};

export default Navbar;