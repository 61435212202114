import { updateLanguages } from '../../../redux/Languages'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { updateSelectLanguageComShowToFalse } from '../../../redux/SelectLanguageComShow';
import './LanguageOptions.scss'

const LanguageOptions = () => {

    const dispatch = useDispatch()
    const activeLanguage = useSelector(state => state.Languages)

    const allLanguages = [
        "Español",
        "English",
    ]

    return (
        <div
            className='LanguageOptions'>
                
            <div
                className='languageList'>

                {
                    allLanguages.map((languageName, index) => {
                        return (
                            <div
                                key={index}
                                className='listBox'>

                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        border: activeLanguage.activeLanguage === languageName ? '2px solid #AB0F0C' : '2px solid black',
                                        borderRadius: '99px',
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}
                                    onClick={() => { dispatch(updateLanguages(languageName)) }} >

                                    {
                                        activeLanguage.activeLanguage === languageName &&
                                        <div
                                        style={{
                                            width: '14px',
                                            height: '14px',
                                            borderRadius: '99px',
                                            backgroundColor: '#AB0F0C',
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)'
                                        }} />
                                    }

                                </div>

                                <p>
                                    {languageName}
                                </p>

                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default LanguageOptions