import React from 'react'
import './ApplyLoan.scss'
import Banner from './Banner'
import LoanButton from './LoanButton'
import FirstComponent from './FirstComponent'
import SecondComponent from './SecondComponent'
import ThirdComponent from './ThirdComponent'
import FourthComponent from './FourthComponent'
import FifthComponent from './FifthComponent'
import SxthComponent from './SxthComponent'

const ApplyLoan = () => {
  return (
    <div className='ApplyLoan'>

        <Banner />

        <LoanButton />

        <FirstComponent />

        <SecondComponent />

        <ThirdComponent />

        <FourthComponent />

        <FifthComponent />

        <SxthComponent />

    </div>
  )
}

export default ApplyLoan