import React from 'react'

const Banner = () => {
    return (
        <div className='Banner'>

            <video autoPlay={true} loop={true} muted={true} playsInline={true}>
                <source src={"https://pakhiweb.s3.us-west-2.amazonaws.com/ss/home/homebanner.mp4"} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <h1>
            Partnering With Businesses to Put AI to Work
            </h1>

            <p>
            From conversational virtual assistants to generative AI applications, Kore.ai gives you the platform, no-code tools, and solutions to do AI right.
            </p>

            <div className='buttons_div'>

                <button
                name="get_started_button"
                className='get_started_button'>
                    Get started
                </button>

                <button
                name='talk_to_an_expert_button'
                className='talk_to_an_expert_button'>
                    Talk to an expert
                </button>

            </div>

        </div>
    )
}

export default Banner