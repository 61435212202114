import React from 'react'
import { useSelector } from 'react-redux'

const ThirdComponent = () => {
    const { activeLanguage } = useSelector(state => state.Languages)

    const headingArray = activeLanguage === "Español" ? [
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Edad mínima",
            para: "Debe tener al menos 18 años (o la edad mínima requerida por la ley en su región) para solicitar un programa de propiedad de tractores."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Residencia",
            para: "Debes ser residente del país o región en la que operamos. (Bolivia, Perú, México)"
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Identificación válida",
            para: "Proporcione una identificación emitida por el gobierno para verificar su identidad."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Prueba de ingreso",
            para: "Demuestre su capacidad para pagar el préstamo con documentos de ingresos, como extractos bancarios, declaraciones de impuestos o cartas de verificación de ingresos."
        },
        // {
        //     icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
        //     heading: "Propiedad de la tierra (para agricultores)",
        //     para: "La mayoría de los prestamistas, incluidos nosotros, normalmente exigen una propiedad mínima de la tierra para los agricultores que buscan programas de propiedad de tractores. Esta superficie mínima puede variar según el prestamista y su ubicación."
        // },
    ] : [
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Minimum Age",
            para: "You must be at least 18 years old (or the minimum age requirement as set by law in your region) to apply for a tractor ownership program."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Residency",
            para: "You must be a resident of the country or region we operate in.(Bolivia,Peru,Mexico)"
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Valid Identification",
            para: "Provide a government-issued ID to verify your identity."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Proof of Income",
            para: "Demonstrate your ability to repay the loan with income documents like bank statements, tax returns, or income verification letters."
        },
        // {
        //     icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
        //     heading: "Land Ownership (For Farmers)",
        //     para: "Most lenders, including us, typically require some minimum land ownership for farmers seeking tractor ownership programs. This minimum acreage may vary depending on the lender and your location."
        // },
    ]

    return (
        <div className='ThirdComponent'>

            <div
                className='container'>

                <p className='mainHeading'>
                    {
                        activeLanguage === "Español" ?
                            "¡Requisitos del propietario del tractor!"
                            :
                            "Eligibility Requirements for a tractor owner !"
                    }
                </p>

                <div
                    className='elementsContainer'>

                    <div className='left'>

                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/hola.webp"
                            alt='left side image' />

                    </div>

                    <div
                        className='right'>

                        {
                            headingArray.map((details, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='box'>

                                        <div className="box-left">
                                            <img src={details.icon} alt="" />
                                        </div>

                                        <div className="box-right">

                                            <div className="heading">
                                                {details.heading}
                                            </div>

                                            <div className="details">
                                                {details.para}
                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

            </div>

        </div>
    )
}

export default ThirdComponent