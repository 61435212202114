import React from 'react'
import serviceBackgroundVideo from '../../../../../assets/video/service.mp4'
import { useSelector } from 'react-redux'

const Banner = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className="Banner">

            <video autoPlay loop muted>
                <source src={serviceBackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <h1>
                {
                    activeLanguage === "Español" ?
                        "Tractores compactos"
                        :
                        "Compact Tractors"
                }
            </h1>

            {
                activeLanguage === "Español" ?
                    <p className='paragraphOne'>
                        Los tractores compactos son los caballos de batalla de las pequeñas granjas, las granjas de aficionados, las empresas de jardinería e incluso las grandes propiedades con diversas necesidades. Ofrecen una combinación perfecta de potencia, tamaño y versatilidad, lo que los hace ideales para abordar una amplia gama de tareas.
                    </p>
                    :
                    <p className='paragraphOne'>
                        Compact tractors are the workhorses of small farms, hobby farms, landscaping businesses, and even large properties with diverse needs. They offer a perfect blend of power, size, and versatility, making them ideal for tackling a wide range of tasks.
                    </p>
            }

        </div>
    )
}

export default Banner