import React from 'react'
import { useSelector } from 'react-redux'

const SeventhComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            heading: "Rendimiento potente",
            description: "Ofrecen una gama de opciones de motores que ofrecen suficiente potencia para afrontar trabajos exigentes sin sacrificar la eficiencia del combustible.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
        },
        {
            heading: "Construído para perdurar",
            description: "Los tractores utilitarios son conocidos por su construcción robusta y durabilidad, lo que garantiza que puedan soportar años de uso intensivo.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
        },
        {
            heading: "Magia del apego",
            description: "La capacidad de conectar una amplia gama de archivos adjuntos realmente amplía su funcionalidad. Desde cargadores frontales y retroexcavadoras hasta cortadoras de césped, cultivadores e implementos especializados, puede personalizar su tractor para cualquier aplicación específica.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
        },
        {
            heading: "Comodidad del operador",
            description: "Los tractores utilitarios modernos priorizan la comodidad del operador con características como asientos ergonómicos, controles ajustables y cabinas espaciosas, lo que permite trabajar largas horas sin fatiga.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
        },
        {
            heading: "Tecnología avanzada",
            description: "Muchos modelos ofrecen tecnologías integradas como sistemas de guía GPS, control automático de implementos y capacidades de monitoreo de datos, lo que mejora la precisión y la eficiencia.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
        },
    ]
        :
        [
            {
                heading: "Powerhouse Performance",
                description: "They offer a range of engine options, delivering enough horsepower to tackle demanding jobs without sacrificing fuel efficiency.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
            },
            {
                heading: "Built to Last",
                description: "Utility tractors are known for their robust construction and durability, ensuring they can withstand years of heavy use.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
            },
            {
                heading: "Attachment Magic",
                description: "The ability to connect a vast array of attachments truly expands their functionality. From front loaders and backhoes to mowers, tillers, and specialized implements, you can customize your tractor for any specific application.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
            },
            {
                heading: "Operator Comfort",
                description: "Modern utility tractors prioritize operator comfort with features like ergonomic seating, adjustable controls, and spacious cabs, allowing for long hours of work without fatigue.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
            },
            {
                heading: "Advanced Technology",
                description: "Many models offer integrated technologies like GPS guidance systems, automatic implement control, and data monitoring capabilities, enhancing precision and efficiency.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
            },
        ]

    return (
        <div
            className='SeventhComponent'>

            <div
                className='seventh_container'
                style={{
                    // background: 'radial-gradient(50% 50% at 50% 50%, rgba(158, 110, 230, 0.28) 0%, rgba(158, 110, 230, 0) 100%)'
                }}>

                <h1
                    className='text-3xl 1000px:text-5xl'>
                    {
                        activeLanguage === "Español" ?
                            "Esto es lo que hace que los tractores utilitarios sean tan efectivos"
                            :
                            "Here's what makes utility tractors so effective"
                    }
                </h1>

                <div
                    className='grid-cols-1 800px:grid-cols-2 data_container'>

                    {
                        componentArray.map((details, index) => {
                            return (
                                <div
                                    key={index}
                                    className='data_box'>

                                    <img
                                        alt={details.heading}
                                        src={details.imageLink}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            objectFit: 'cover'
                                        }} />

                                    <div
                                        className='text-lg 1000px:text-2xl data_text_container'>

                                        <p>
                                            <b>
                                                {details.heading}
                                            </b>
                                        </p>

                                        <p>
                                            {details.description}
                                        </p>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </div>

        </div>
    )
}

export default SeventhComponent