import React from 'react'

const SecondComponent = () => {

    const offeringsArray = [
        {
            imageLink: "https://www.xpressloan.in/assets/img/fast%20approval-01.svg",
            heading: "fast approval",
            description: "Your application will be processed in a short duration if you provide all the necessary documents and meet the eligibility criteria."
        },
        {
            imageLink: "https://www.xpressloan.in/assets/img/fast%20approval-01.svg",
            heading: "fast approval",
            description: "Your application will be processed in a short duration if you provide all the necessary documents and meet the eligibility criteria."
        },
        {
            imageLink: "https://www.xpressloan.in/assets/img/fast%20approval-01.svg",
            heading: "fast approval",
            description: "Your application will be processed in a short duration if you provide all the necessary documents and meet the eligibility criteria."
        },
        {
            imageLink: "https://www.xpressloan.in/assets/img/fast%20approval-01.svg",
            heading: "fast approval",
            description: "Your application will be processed in a short duration if you provide all the necessary documents and meet the eligibility criteria."
        },
    ]

  return (
    <div className='SecondComponent'>

        <h1>
        Best features of our loan offerings
        </h1>

        <div className="offeringsGrid">

            {
                offeringsArray.map((details, index)=>{
                    return(
                        <div className='offeringsBox' key={index}>

                            <img src={details.imageLink} alt="" />

                            <h3>
                                {details.heading}
                            </h3>

                            <p>
                                {details.description}
                            </p>

                        </div>
                    )
                })
            }

        </div>

    </div>
  )
}

export default SecondComponent