import React from "react";
import styles from "./LogoSlider.module.scss";

const logos = [
    { logo: "Codecov", number: "8+", text: "Years of Experience" },
    { logo: "Greylock", number: "40+", text: "Website Developed" },
    { logo: "Figma", number: "40+", text: "Project Finished" },
     { logo: "Greylock", number: "40+", text: "Website Developed" },
    { logo: "Figma", number: "40+", text: "Project Finished" },
  ];
  
  const ContinuousLogoSlider = () => {
    return (
      <div className={styles.sliderContainer}>
        <div className={styles.sliderTrack}>
          {/* Duplicate content for seamless looping */}
          {[...logos, ...logos].map((item, index) => (
            <div key={index} className={styles.slide}>
              <div className={styles.logo}>{item.logo}</div>
              <div className={styles.divider}></div>
              <div className={styles.stats}>
                <span className={styles.number}>{item.number}</span>
                <span className={styles.text}>{item.text}</span>
              </div>
              <div className={styles.divider}></div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default ContinuousLogoSlider;
