import React, { useState } from 'react'
import { ChevronLeft, ChevronRight, Play } from 'lucide-react'
import styles from './testimonial-section.module.css'

// Sample data for testimonials (you can expand this)
const testimonials = [
  {
    id: 1,
    name: 'Olha Metofor',
    position: 'PM of Mira Nova',
    quote: '"Our team has achieved our business goals with Acme\'s help for sure. It was beneficial to cooperate with them on our internal team. Instead of us building an internal team like that on our own, it took them less time to provide us with more value than we would have struggled ourselves."',
    videoPlaceholder: '/placeholder.svg?height=400&width=600',
    authorImage: '/placeholder.svg?height=48&width=48'
  },
  // Add more testimonial objects here
  {
    id: 2,
    name: 'John Doe',
    position: 'CTO of Example Corp',
    quote: '"Working with this team was an incredible experience. Their expertise and dedication truly transformed our project."',
    videoPlaceholder: '/placeholder.svg?height=400&width=600',
    authorImage: '/placeholder.svg?height=48&width=48'
  }
]

export default function TestimonialSection() {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleNext = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex + 1) % testimonials.length
    )
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    )
  }

  const currentTestimonial = testimonials[currentIndex]

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.heading}>
          Long-term partnerships is what we are always striving{' '}
          <span className={styles.highlight}>to build</span>
        </h2>
        
      </div>
      <div className={`${styles.navigationWrapper}`}>
          <div className={styles.navigation}>
            <button 
              className={styles.navButton} 
              aria-label="Previous testimonial"
              onClick={handlePrev}
            >
              <ChevronLeft size={20} />
            </button>
            <button 
              className={styles.navButton} 
              aria-label="Next testimonial"
              onClick={handleNext}
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
      <div className={styles.content}>
        <div className={styles.videoContainer} key={`video-${currentTestimonial.id}`}>
          <img
            src={currentTestimonial.videoPlaceholder}
            alt="Video thumbnail"
            fill
            className="object-cover"
          />
          <button className={styles.playButton} aria-label="Play video">
            <Play size={24} fill="black" />
          </button>
        </div>

        <div 
          className={styles.testimonial} 
          key={`testimonial-${currentTestimonial.id}`}
        >
          <div className={styles.author}>
            <img
              src={currentTestimonial.authorImage}
              alt={currentTestimonial.name}
              width={48}
              height={48}
              className={styles.authorImage}
            />
            <div className={styles.authorInfo}>
              <h3>{currentTestimonial.name}</h3>
              <p>{currentTestimonial.position}</p>
              <div className={styles.rating}>
                <img
                  src="/placeholder.svg?height=20&width=60"
                  alt="Clutch"
                  width={60}
                  height={20}
                />
                <span>5.0 ★</span>
              </div>
            </div>
          </div>

          <blockquote className={styles.quote}>
            {currentTestimonial.quote}
          </blockquote>

          <a href="#" className={styles.caseStudyButton}>
            View case study
            <ChevronRight size={16} />
          </a>
        </div>
      </div>
    </section>
  )
}