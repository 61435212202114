import React from 'react'

const SecondComponent = () => {
    return (
        <div className="SecondComponent">

            <p className='mainHeading'>
                What is Home Loan EMI Calculation Formula?
            </p>

            <div className="container">

                <p className="subHeading">
                    Formula -
                </p>

                <p>
                    Formula for Home Loan EMI Calculation - <span className="subHeading">EMI = [P x R x (1+R)^N]/[(1+R)^N-1]</span>
                </p>

                <p>
                    Where,
                </p>

                <p>
                    <span className="subHeading">E </span>- Equated Monthly Installment,<span className="subHeading">P </span>- Principal or loan amount,<span className="subHeading">R </span>- Interest rate per month (the annual interest rate is divided by 12 to get the monthly interest rate), and <span className="subHeading">N </span>- Number of monthly instalments or loan tenure in months.*This formula doesn’t include the home loan processing fee charged by the bank.
                </p>

            </div>

            <p className="subHeading">
                Home Loan EMI Calculation with Example
            </p>

            <p>
                Mr. Patra borrowed a home loan amount of 3,500,000 (Principal amount or P) from a reputed bank. The rate of interest (R) associated with the loan stands at 8.65% p.a. The tenure of the loan (N) is 360 months for 30 years
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                After you apply the formula to <span className="subHeading">calculate your home loan EMI, </span>the monthly instalment amount comes up to ₹ 27,285.
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span className="subHeading">P </span>= 3,500,000 <span className="subHeading">R = </span>8.65% <span className="subHeading">N= </span>30 years or 360 Months
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span className="subHeading">Interest Rate Applied  </span>= 8.65%
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span className="subHeading">Monthly EMI </span>= Rs 27,285
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span className="subHeading">EMI Paid for the Year </span>= Rs 27,285 * 12= Rs 327,419
            </p>

        </div>
    )
}

export default SecondComponent