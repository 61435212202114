import React from 'react'
import { useSelector } from 'react-redux'

const FourthComponent = () => {

  const { activeLanguage } = useSelector(state => state.Languages)

  return (
    <div className='FourthComponent'>

      <div className='container'>

        <p className='mainHeading'>
          {
            activeLanguage === "Español" ?
              "Invertir en un tractor utilitario es una inversión en su productividad y eficiencia."
              :
              "Investing in a utility tractor is an investment in your productivity and efficiency"
          }
        </p>

        <p className='subHeading'>
          {
            activeLanguage === "Español" ?
              "Le proporciona una herramienta poderosa y versátil que puede manejar una multitud de tareas, ayudando a que su granja, negocio de paisajismo u operación de administración de propiedades prospere."
              :
              "It equips you with a powerful and versatile tool that can handle a multitude of tasks, helping your farm, landscaping business, or property management operation thrive."
          }
        </p>

      </div>

    </div>
  )
}

export default FourthComponent