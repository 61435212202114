import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import Slider from '@mui/material/Slider'

const FirstComponent = () => {
  const [monthlyEMI, setMonthlyEMI] = useState(0)
  const [loanAmount, setLoanAmount] = useState(100000)
  const [totalIntrest, setTotalIntrest] = useState(10000)
  const [processingFee, setProcessingFee] = useState(0)

  const [intrestRate, setIntrestRate] = useState(1.2)
  const [loanTenure, setLoanTenure] = useState(1)

  const series = [Number(loanAmount), Number(totalIntrest), Number(processingFee)]

  const options = {
    chart: {
      type: 'donut',
    },
    labels: ['Loan Amount', 'Total Intrest', 'Processing Fee'],
    colors: ['#FF0000', '#00FF00', '#0000FF'],
    legend: {
      show: false // Disable the legend
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        }
      }
    }
  }

  const handleSliderChange = (event, newValue) => {
    setLoanAmount(newValue);
    // Optionally, update monthly EMI or other calculations based on the new loan amount
  };

  const handleSliderChangeIntrestRate = (event, newValue) => {
    setIntrestRate(newValue);
    // Optionally, update monthly EMI or other calculations based on the new loan amount
  };

  const handleSliderChangeLoanTenure = (event, newValue) => {
    setLoanTenure(newValue);
    // Optionally, update monthly EMI or other calculations based on the new loan amount
  };

  const handleSliderChangeProcessingFee = (event, newValue) => {
    setProcessingFee(newValue);
    // Optionally, update monthly EMI or other calculations based on the new loan amount
  };

  useEffect(() => {
    const calculateEMI = () => {
      const P = loanAmount;
      const r = intrestRate / (12 * 100); // Monthly interest rate
      const n = loanTenure * 12; // Number of monthly installments

      const EMI = P * r * Math.pow(1 + r, n) / (Math.pow(1 + r, n) - 1);
      setMonthlyEMI(EMI.toFixed(2));

      // Calculate the total interest
      const totalPayment = EMI * n;
      const totalInterest = totalPayment - P;
      setTotalIntrest(totalInterest.toFixed(2));
    };

    calculateEMI();
  }, [loanAmount, intrestRate, loanTenure]);

  return (
    <div className='FirstComponent'>

      <div className="leftSide">

        <div className="box">

          <div className="boxDetails">

            <p>
              Loan Amount
            </p>

            <div className='value-box'>

              <span>₹</span>
              <input
              value={loanAmount}
              onChange={(e)=>{setLoanAmount(e.target.value)}} />

            </div>

          </div>

          <Slider
            value={loanAmount}
            aria-label="Default"
            valueLabelDisplay="auto"
            onChange={handleSliderChange}
            min={100000}
            max={1000000} />

        </div>

        <div className="box">

          <div className="boxDetails">

            <p>
              Intrest Rate
            </p>

            <div className='value-box'>

              <input
              value={intrestRate}
              onChange={(e)=>{setIntrestRate(e.target.value)}} />
              <span>%</span>

            </div>

          </div>

          <Slider
            value={intrestRate}
            aria-label="Default"
            valueLabelDisplay="auto"
            onChange={handleSliderChangeIntrestRate}
            min={1.2}
            max={20} />

        </div>

        <div className="box">

          <div className="boxDetails">

            <p>
              Loan Tenure
            </p>

            <div className='value-box'>

              <input
              value={loanTenure}
              onChange={(e)=>{setLoanTenure(e.target.value)}} />
              <span>Year</span>

            </div>

          </div>

          <Slider
            value={loanTenure}
            aria-label="Default"
            valueLabelDisplay="auto"
            onChange={handleSliderChangeLoanTenure}
            min={1}
            max={30} />

        </div>

        <div className="box">

          <div className="boxDetails">

            <p>
              Processing Fee
            </p>

            <div className='value-box'>

              <input
              value={processingFee}
              onChange={(e)=>{setProcessingFee(e.target.value)}} />
              <span>Year</span>

            </div>

          </div>

          <Slider
            value={processingFee}
            aria-label="Default"
            valueLabelDisplay="auto"
            onChange={handleSliderChangeProcessingFee}
            min={0}
            max={99999} />

        </div>

      </div>

      <div className='rightSide'>

        <div className='right-left'>

          <ReactApexChart
            series={series}
            options={options}
            type='donut' />

        </div>

        <div className="right-right">

          <p className='infobox'>
            Your monthly EMI: <span>₹{monthlyEMI}</span>
          </p>

          <div className='infobox'>
            <div className='subInfoBox'>
              <div
                style={{
                  backgroundColor: '#FF0000',
                  width: '16px',
                  height: '16px'
                }} />
              <p>
                Loan Amount:
              </p>
            </div>
            <>
              <span>₹{loanAmount}</span>
            </>
          </div>

          <div className='infobox'>
            <div className='subInfoBox'>
              <div
                style={{
                  backgroundColor: '#00FF00',
                  width: '16px',
                  height: '16px'
                }} />
              <p>
                Total Intrest:
              </p>
            </div>
            <>
              <span>₹{totalIntrest}</span>
            </>
          </div>

          <div className='infobox'>
            <div className='subInfoBox'>
              <div
                style={{
                  backgroundColor: '#0000FF',
                  width: '16px',
                  height: '16px'
                }} />
              <p>
                Processing Fee:
              </p>
            </div>
            <>
              <span>₹{processingFee}</span>
            </>
          </div>

        </div>

      </div>

    </div>
  )
}

export default FirstComponent