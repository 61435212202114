import React from 'react'
import { useSelector } from 'react-redux'

const ThirdComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentDetails =[
        {
            heading: '5000+',
            subHeading: activeLanguage === "Español" ? "Agricultores" : "Farmers"
        },
        {
            heading: '500+',
            subHeading: activeLanguage === "Español" ? "Propietarios de tractores" : "Tractor owners"
        },
        {
            heading: '200+',
            subHeading: activeLanguage === "Español" ? "Comunidad" : "Community"
        },
    ]

  return (
    <div className='ThirdComponent'>

        <div className="container">

            <div className='gridRow'>

                {
                    componentDetails.map((details, index)=>{
                        return(
                            <div className="box" key={index}>
                            <h1>
                                {details.heading}
                            </h1>
                            <p>
                                {details.subHeading}
                            </p>
                        </div>
                        )
                    })
                }

            </div>

            <button
            name="get in touch button">
                {
                    activeLanguage === "Español" ?
                    "Ponerse en contacto"
                    :
                    "Get in touch"
                }
            </button>

        </div>

    </div>
  )
}

export default ThirdComponent