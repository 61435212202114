import React from 'react'
import { useSelector } from 'react-redux'

const FirstComponent = () => {
    const { activeLanguage } = useSelector(state => state.Languages)

    const headingArray = activeLanguage === "Español" ? [
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Motores de alta potencia",
            para: "Realice incluso las tareas más desafiantes con motores que le brindan la fuerza que necesita para manejar cargas pesadas, arar campos extensos y operar implementos exigentes."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Construcción robusta",
            para: "Construidos con materiales duraderos y componentes robustos, estos tractores pueden soportar el uso continuo y terrenos desafiantes, lo que garantiza años de servicio confiable."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Hidráulica Avanzada",
            para: "Experimente el funcionamiento suave y preciso de accesorios hidráulicos con sistemas hidráulicos potentes y eficientes."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Características de comodidad superiores",
            para: "Las largas jornadas de trabajo son muy sencillas con cabinas centradas en el operador que cuentan con asientos ergonómicos, control de clima y reducción de ruido para un ambiente de trabajo cómodo y productivo."
        },
    ] : [
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "High Horsepower Engines",
            para: "Power through even the most challenging tasks with engines delivering the muscle you need to handle heavy loads, plow vast fields, and operate demanding implements."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Rugged Construction",
            para: "Built with durable materials and robust components, these tractors can withstand continuous use and challenging terrains, ensuring years of dependable service."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Advanced Hydraulics",
            para: "Experience smooth and precise operation of hydraulic attachments with powerful and efficient hydraulic systems."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Superior Comfort Features",
            para: "Long work days are a breeze with operator-centric cabins featuring ergonomic seating, climate control, and noise reduction for a comfortable and productive work environment."
        },
    ]

    return (
        <div className='FirstComponent'>

            <div
                className='container'>

                <p className='mainHeading'>
                    {
                        activeLanguage === "Español" ?
                            "Construido para aplicaciones de servicio pesado"
                            :
                            "Built for Heavy-Duty Applications"
                    }
                </p>


                <p className='subHeading'>
                    {
                        activeLanguage === "Español" ?
                            "Nuestros tractores comerciales están diseñados para ofrecer el máximo rendimiento en entornos exigentes."
                            :
                            "Our commercial tractors are engineered for maximum performance in demanding environments."
                    }
                </p>

                <div
                    className='elementsContainer'>

                    <div className='left'>

                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/hola.webp"
                            alt='left side image' />

                    </div>

                    <div
                        className='right'>

                        {
                            headingArray.map((details, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='box'>

                                        <div className="box-left">
                                            <img src={details.icon} alt="" />
                                        </div>

                                        <div className="box-right">

                                            <div className="heading">
                                                {details.heading}
                                            </div>

                                            <div className="details">
                                                {details.para}
                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

            </div>

        </div>
    )
}

export default FirstComponent