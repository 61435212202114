// import { useState } from "react"
// import { EyeIcon, EyeOffIcon } from "lucide-react"
// import './LogInPage.scss'
// import { errorMessage, successMessage } from '../../components/Toastify/message'
// import { NestjsBookingInstance } from '../../components/Axios/NestjsBookingInstance'
// import { DashboardBaseUrl } from '../../components/Axios/DashboardInstance'
// import CryptoJS from "crypto-js"
// import { Link, useNavigate } from 'react-router-dom'
// import { Backdrop, CircularProgress } from "@mui/material"
// import { useGoogleLogin } from '@react-oauth/google'
// import axios from "axios"

// export default function LoginForm() {
//   const [showPassword, setShowPassword] = useState(false)
//   const [activeTab, setActiveTab] = useState("email")
//   const [loading, setLoading] = useState(false)
//   const [email, setEmail] = useState("")
//   const [password, setPassword] = useState("")

//   const navigate = useNavigate()

//   const handleTabChange = (tab) => {
//     setActiveTab(tab)
//   }

//   function handleLogin(e) {
//     e.preventDefault()

//     if (!email) {
//       errorMessage("Please give your email")
//       return
//     }
//     if (!password || password.length < 8) {
//       errorMessage("Password should be atleast 8 characters")
//       return
//     }

//     setLoading(true)

//     const encryptedPassword = CryptoJS.AES.encrypt(password, "m4AfXfQ&1brl3LjQFYO").toString()

//     NestjsBookingInstance.post("/user/login", {
//       email: email.trim(),
//       password: encryptedPassword,
//       authType: "EMAIL",
//     })
//       .then((res) => {
//         if (res.status === 201 && res.data.access_token) {
//           if (res.data.isFarmer) {
//             successMessage("Log in successfull")
//             window.location.href = `${DashboardBaseUrl}/farmer_login?token=${res.data.access_token}&isFarmer=${res.data.isFarmer}&isOperator=${res.data.isOperator}&isOwner=${res.data.isOwner}&isDealer=${res.data.isDealer}`
//             setEmail("")
//             setPassword("")
//           } else {
//             errorMessage("Your account is not a farmer account")
//           }
//         }
//       })
//       .catch((err) => {
//         if (err.response?.status === 409) {
//           errorMessage(err.response.data.message)
//         } else {
//           errorMessage("Some error occurred")
//         }
//       })
//       .finally(() => {
//         setLoading(false)
//       })
//   }

//   const login = useGoogleLogin({
//     onSuccess: (codeResponse) => {
//       axios
//         .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
//           headers: {
//             Authorization: `Bearer ${codeResponse.access_token}`,
//             Accept: "application/json",
//           },
//         })
//         .then((res) => {
//           setLoading(true)
//           NestjsBookingInstance.post("/user/login", {
//             email: res.data.email,
//             authType: "GOOGLE",
//           })
//             .then((res) => {
//               if (res.status === 201 && res.data.access_token) {
//                 if (res.data.isFarmer) {
//                   successMessage("Log in successful")
//                   window.location.href = `${DashboardBaseUrl}/farmer_login?token=${res.data.access_token}&isFarmer=${res.data.isFarmer}&isOperator=${res.data.isOperator}&isOwner=${res.data.isOwner}&isDealer=${res.data.isDealer}`
//                 } else {
//                   errorMessage("Your account is not a farmer account")
//                 }
//               }
//             })
//             .catch((err) => {
//               errorMessage(err.response?.data?.message || "Some error occurred")
//             })
//             .finally(() => {
//               setLoading(false)
//             })
//         })
//         .catch(() => errorMessage("Login Failed"))
//     },
//     onError: () => errorMessage("Login Failed"),
//   })

//   return (
//     <div className="login-page">
//       <div className="login-card">
//         <div className="login-card__header">
//           <h2 className="title">Login to your account</h2>
//           <p className="description">
//             Don't have an account? <Link to="/register">Sign up</Link>
//           </p>
//         </div>

//         <div className="login-card__content">
//           <div className="tabs">
//             <div className="tabs__list" role="tablist">
//               <button role="tab" aria-selected={activeTab === "email"} onClick={() => handleTabChange("email")}>
//                 Email
//               </button>
//               <button role="tab" aria-selected={activeTab === "mobile"} onClick={() => handleTabChange("mobile")}>
//                 Mobile
//               </button>
//             </div>

//             <div className="tabs__content" role="tabpanel" aria-hidden={activeTab !== "email"}>
//               <form className="form" onSubmit={handleLogin}>
//                 <div className="input-group">
//                   <label htmlFor="email">Email</label>
//                   <div className="input-wrapper">
//                     <input
//                       id="email"
//                       type="email"
//                       placeholder="Enter your email"
//                       required
//                       value={email}
//                       onChange={(e) => setEmail(e.target.value)}
//                     />
//                   </div>
//                 </div>

//                 <div className="input-group">
//                   <label htmlFor="email-password">Password</label>
//                   <div className="input-wrapper">
//                     <input
//                       id="email-password"
//                       type={showPassword ? "text" : "password"}
//                       placeholder="Enter your password"
//                       required
//                       value={password}
//                       onChange={(e) => setPassword(e.target.value)}
//                     />
//                     <button type="button" className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
//                       {showPassword ? <EyeOffIcon className="h-4 w-4" /> : <EyeIcon className="h-4 w-4" />}
//                     </button>
//                   </div>
//                 </div>
//                 <button type="submit" className="submit-button">
//                   Login with Email
//                 </button>
//               </form>
//             </div>

//             <div className="tabs__content" role="tabpanel" aria-hidden={activeTab !== "mobile"}>
//               <form className="form">
//                 <div className="input-group">
//                   <label htmlFor="country">Country</label>
//                   <div className="input-wrapper">
//                     <select id="country">
//                       <option value="">Select country</option>
//                       <option value="us">United States</option>
//                       <option value="uk">United Kingdom</option>
//                       <option value="ca">Canada</option>
//                       <option value="au">Australia</option>
//                     </select>
//                   </div>
//                 </div>

//                 <div className="input-group">
//                   <label htmlFor="mobile">Mobile Number</label>
//                   <div className="input-wrapper">
//                     <input id="mobile" type="tel" placeholder="Enter your mobile number" required />
//                   </div>
//                 </div>

//                 <div className="input-group">
//                   <label htmlFor="mobile-password">Password</label>
//                   <div className="input-wrapper">
//                     <input
//                       id="mobile-password"
//                       type={showPassword ? "text" : "password"}
//                       placeholder="Enter your password"
//                       required
//                     />
//                     <button type="button" className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
//                       {showPassword ? <EyeOffIcon className="h-4 w-4" /> : <EyeIcon className="h-4 w-4" />}
//                     </button>
//                   </div>
//                 </div>

//                 <button type="submit" className="submit-button">
//                   Login with Mobile (Not available now)
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>

//         <div className="login-card__footer">
//           <div className="divider">
//             <div className="divider-text">
//               <span>Or continue with</span>
//             </div>
//           </div>

//           <button className="social-button" onClick={() => login()}>
//             <svg viewBox="0 0 24 24">
//               <path
//                 fill="currentColor"
//                 d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
//               />
//               <path
//                 fill="currentColor"
//                 d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
//               />
//               <path
//                 fill="currentColor"
//                 d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
//               />
//               <path
//                 fill="currentColor"
//                 d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
//               />
//             </svg>
//             Continue with Google
//           </button>
//         </div>
//       </div>

//       <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
//         <CircularProgress />
//       </Backdrop>
//     </div>
//   )
// }
import { useState } from "react";
import { CheckCircle } from "lucide-react"
import './LogInPage.scss'
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { errorMessage, successMessage } from "../../components/Toastify/message";
import { NestjsBookingInstance } from "../../components/Axios/NestjsBookingInstance";
import { DashboardBaseUrl } from "../../components/Axios/DashboardInstance";
import CryptoJS from "crypto-js";
import { Link, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

export default function SignupPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignup = (e) => {
    e.preventDefault();

    if (!email) {
      errorMessage("Please enter your email.");
      return;
    }
    if (!password || password.length < 8) {
      errorMessage("Password should be at least 8 characters.");
      return;
    }

    setLoading(true);

    NestjsBookingInstance.post("/user/signup", {
      email: email.trim(),
      password,
    })
      .then((res) => {
        if (res.status === 201) {
          successMessage("Log in successful")
          window.location.href = `${DashboardBaseUrl}/farmer_login?token=${res.data.access_token}&isFarmer=${res.data.isFarmer}&isOperator=${res.data.isOperator}&isOwner=${res.data.isOwner}&isDealer=${res.data.isDealer}`
        }
      })
      .catch((err) => {
        errorMessage(err.response?.data?.message || "Some error occurred.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const googleSignup = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setLoading(true);
          NestjsBookingInstance.post("/user/signup", {
            email: res.data.email,
            authType: "GOOGLE",
          })
            .then((res) => {
              if (res.status === 201) {
                successMessage("Log in successful")
                window.location.href = `${DashboardBaseUrl}/farmer_login?token=${res.data.access_token}&isFarmer=${res.data.isFarmer}&isOperator=${res.data.isOperator}&isOwner=${res.data.isOwner}&isDealer=${res.data.isDealer}`
              }
            })
            .catch((err) => {
              errorMessage(err.response?.data?.message || "Some error occurred.");
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(() => errorMessage("Sign-up Failed."));
    },
    onError: () => errorMessage("Sign-up Failed."),
  });

  return (
    <>
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <div className="container">
        <div className="hero">
          <h1> Join HolaTractor Agriculture with Advanced Mechanization for Better Yields!</h1>
          <ul className="features">
            <li className="feature-item">
              <CheckCircle className="check-icon" size={24} />
              Smart Booking System
            </li>
            <li className="feature-item">
              <CheckCircle className="check-icon" size={24} />
              Affordable and Accessible
            </li>
            <li className="feature-item">
              <CheckCircle className="check-icon" size={24} />
              Maintenance and Support
            </li>
          </ul>
        </div>

        <div className="signup-form">
          <button className="google-button" onClick={() => googleSignup()}>
            <svg width="18" height="18" viewBox="0 0 18 18">
              {/* Google SVG Paths */}
              <path
                fill="#fff"
                d="M17.64 9.2c0-.637-.057-1.251-.164-1.84H9v3.481h4.844c-.209 1.125-.843 2.078-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615z"
              />
              <path
                fill="#fff"
                d="M9 18c2.43 0 4.467-.806 5.956-2.184l-2.908-2.258c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332C2.438 15.983 5.482 18 9 18z"
              />
              <path
                fill="#fff"
                d="M3.964 10.707c-.18-.54-.282-1.117-.282-1.707s.102-1.167.282-1.707V4.961H.957C.347 6.175 0 7.548 0 9s.348 2.825.957 4.039l3.007-2.332z"
              />
              <path
                fill="#fff"
                d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0 5.482 0 2.438 2.017.957 4.961L3.964 7.293C4.672 5.166 6.656 3.58 9 3.58z"
              />
            </svg>
            Sign in with Google
          </button>

          <div className="divider">OR</div>

          <div className="required">* indicates a required field.</div>

          <form onSubmit={handleSignup}>
            <div className="form-group">
              <label className="form-label">*Email</label>
              <input
                type="email"
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label">* Password</label>
              <input
                type="password"
                className="form-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="terms">
              By signing up, you agree to Holatractor<a href="#">terms of service</a> and <a href="#">privacy policy</a>.
            </div>

            <button type="submit" className="submit-button">
              Get started for free
            </button>
          </form>

          <div className="login-link">
            Already have an account? <a href="/register">Sign up</a>
          </div>
        </div>
      </div>

      <div className="trusted-section">
        <h2>Trusted at companies large and small</h2>
        <div className="logo-grid">
          <img src="path/to/logo1.png" alt="Company Logo 1" />
          <img src="path/to/logo2.png" alt="Company Logo 2" />
          <img src="path/to/logo3.png" alt="Company Logo 3" />
          <img src="path/to/logo4.png" alt="Company Logo 4" />
        </div>
      </div>
    </>
  );
}


