import React from 'react'
import serviceBackgroundVideo from '../../../../../assets/video/service.mp4'
import { useSelector } from 'react-redux'

const Banner = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className="Banner">

            <video autoPlay loop muted>
                <source src={serviceBackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <h1>
                {
                    activeLanguage === "Español" ?
                        "Alquiler de tractores comerciales con Holatractor"
                        :
                        "Commercial Tractor Rentals with Holatractor"
                }
            </h1>

            {
                activeLanguage === "Español" ?
                    <p className='paragraphOne'>
                        Servicio de alquiler de tractores comerciales diseñado para dotar a sus proyectos de la potencia y versatilidad que necesitan para tener éxito.
                    </p>
                    :
                    <p className='paragraphOne'>
                        <b style={{color: "#AB0F0C"}}>Commercial tractor rental service </b> designed to equip your projects with the power and versatility they need to succeed.
                    </p>
            }

        </div>
    )
}

export default Banner