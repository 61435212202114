import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';

const SecondComponent = () => {
    const [expandedState, setExpandedState] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            tag: "Flujo de caja mejorado",
            details: "Libere capital valioso para otras necesidades agrícolas."
        },
        {
            tag: "Costos generales reducidos",
            details: "Elimine los gastos de propiedad, almacenamiento y mantenimiento continuo."
        },
        {
            tag: "Escalabilidad para sus necesidades",
            details: "Alquile el equipo que necesita a medida que su granja crece y los requisitos evolucionan."
        },
    ] : [
        {
            tag: "Improved Cash Flow",
            details: "Free up valuable capital for other farm needs."
        },
        {
            tag: "Reduced Overhead Costs",
            details: "Eliminate the expenses of ownership, storage, and ongoing maintenance."
        },
        {
            tag: "Scalability for Your Needs",
            details: "Rent the equipment you need as your farm grows and requirements evolve."
        },
    ]

    return (
        <div className="SecondComponent">

            <div className='left'>

                <img
                    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/field_agent.webp"
                    alt=""
                    className='w-full 1000px:w-[90%] rounded-[6px]' />

            </div>

            <div className="rigth">

                <div className="heading">
                    {
                        activeLanguage === "Español" ?
                            "Ventaja de reparto para los agricultores"
                            :
                            "Pay-As-You-Go Advantage for Farmers"
                    }
                </div>

                {
                    componentArray.map((details, index) => {
                        return (
                            <div key={index}>

                                <div className='box-top' onClick={() => { setExpandedState(index) }}>
                                    <span>{details.tag}</span>
                                    <ExpandMoreIcon
                                        style={{
                                            transform: expandedState === index ? 'rotate(180deg)' : 'rotate(0deg)'
                                        }} />
                                </div>

                                <div className='box-bottom'
                                    style={{
                                        height: expandedState === index ? '100px' : '0px',
                                        display: expandedState === index ? 'block' : 'none'
                                    }}>
                                    {details.details}
                                </div>

                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default SecondComponent