import React from 'react'
import { useSelector } from 'react-redux'

const BannerComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='BannerComponent'>
            <div className='leftContainer'>
                <div className='shadow' />
                {
                    activeLanguage === "Español" ?
                        <div className='textContainer'>
                            <p className='subHeading'>
                                ¡Cómo la tecnología está empoderando a los agricultores!
                            </p>
                            <p className='mainHeading'>
                                Comunidad Holatractor
                            </p>
                            <p className='paragraphOne'>
                                Nos apasiona apoyar a la comunidad agrícola. Creemos que la tecnología juega un papel crucial a la hora de empoderar a los pequeños agricultores para que logren una mayor eficiencia, productividad y sostenibilidad.
                            </p>
                        </div>
                        :
                        <div className='textContainer'>
                            <p className='subHeading'>
                                How technology is empowering farmers!
                            </p>
                            <p className='mainHeading'>
                                Holatractor Community
                            </p>
                            <p className='paragraphOne'>
                                We are passionate about supporting the agricultural community. We believe that technology plays a crucial role in empowering smallholder farmers to achieve greater efficiency, productivity and sustainability.
                            </p>
                        </div>
                }
            </div>
            <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractor_tech_6.webp" alt="banner image" className='rightContainer' />
        </div>
    )
}

export default BannerComponent