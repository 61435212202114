import React from 'react';
import { ArrowUp } from 'lucide-react';
import styles from './Vision.module.scss';
import LogoSlider from '../../components/Slidervision/Logoslider';
import TestimonialSlider from '../../components/Slidervision/TestimonialSlider';
import CtaSection from '../../components/Slidervision/CtaSection';
import TalkWithMe from '../../components/Slidervision/TalkWithMe';
import SplitLayout from '../../components/Slidervision/SplitLayout';

const Vision = () => {
    
    return (
        <div className={styles.container}>
            <section className={styles.visionSection}>
                <div className={styles.visionContainer}>
                    <h2 className={styles.visionTitle}>Transforming Visions into Reality</h2>
                    <p className={styles.visionText}>
                        Explore our web development portfolio for a glimpse into seamless digital solutions that elevate online experiences. I ensure your presence is not just noticed but remembered.
                    </p>
                </div>
            </section>
          <SplitLayout/>
            <button
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                className={styles.scrollToTop}
                aria-label="Scroll to top"
            >
                <ArrowUp />
            </button>

          <LogoSlider/>
          <TestimonialSlider/>
          <TalkWithMe/>
          <CtaSection/>
        </div>
    );
};

export default Vision;
