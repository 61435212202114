import React from "react";
import "./postproperty.scss";
import { useSelector } from "react-redux";
// import PropertyLeft from "./../../assets/g2.jpeg";

const PostProperty = () => {

  // const PropertyLeft= "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/55.png";

  const { activeLanguage } = useSelector(state => state.Languages)

  return (
    <div className="post-property">
      <p>
        {
          activeLanguage === "Español" ?
            "¿Eres dueño de un tractor?"
            :
            "Do you own a tractor?"
        }
      </p>
      <h2 className="heading">
        {
          activeLanguage === "Español" ?
            "Alquila y gana con tu tractor más rápido con Holatractor"
            :
            "Rent and earn with your fastest tractor with Holatractor"
        }
      </h2>
      <div className="container">
        <div className="left">
          <img src={"https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/rental_tractor.webp"} alt="property left img" />
        </div>
        {
          activeLanguage === "Español" ?
            <div className="right">
              <span>Publica tu tractor</span>
              <h2>Regístrese para listar su único tractor para <span>GRATIS</span></h2>
              <p>Ayude a los agricultores por su productividad y gane</p>
              <div className="p-btn">
                <button>Registrarse como propietario</button>
              </div>
            </div>
            :
            <div className="right">
              <span>Publish your tractor</span>
              <h2>Sign up to list your only tractor for <span>FREE</span></h2>
              <p>Help farmers for their productivity and earn</p>
              <div className="p-btn">
                <button>Register as owner</button>
              </div>
            </div>
        }
      </div>
    </div>
  );
};

export default PostProperty;
