import React from "react";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined";
import { useSelector } from 'react-redux'

const FirstComponent = () => {
    const { activeLanguage } = useSelector(state => state.Languages)

    const bottomIconStyle = {
        padding: "10px",
        width: "45px",
        height: "45px",
        borderRadius: "5px",
        backgroundColor: "#d0e8fd",
        color: "#469af5",
        cursor: "pointer",
    };

    return (
        <div className="creativity-section">
            {
                activeLanguage === "Español" ?
                    <h2>
                        ¿La mecanización agrícola ayuda en su agricultura?
                    </h2>
                    :
                    <h2>
                        How Agriculture Mechanization Can Help Your Farming ?
                    </h2>
            }
            <div className="creativity-container">
                <div className="creativity-left">
                    <div className="d-flex">
                        
<img
    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/Check.png"
    style={bottomIconStyle} />
                        {
                            activeLanguage === "Español" ?
                                <div className="text">
                                    <h3>Eficiencia incrementada</h3>
                                    <p>
                                        Los tractores y equipos modernos pueden acelerar significativamente tareas como arar, plantar y cosechar, lo que le permite cubrir más terreno en menos tiempo.
                                    </p>
                                </div>
                                :
                                <div className="text">
                                    <h3>Increased Efficiency</h3>
                                    <p>
                                        Modern tractors and equipment can significantly speed up tasks like plowing, planting, and harvesting, allowing you to cover more ground in less time.
                                    </p>
                                </div>
                        }
                    </div>

                    <div className="d-flex">
                        
<img
    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/Roket+1.png"
    style={bottomIconStyle} />
                        {
                            activeLanguage === "Español" ?
                                <div className="text">
                                    <h3>Productividad mejorada</h3>
                                    <p>
                                        La mecanización reduce la dependencia del trabajo manual, lo que le permite completar tareas más rápido y con menos esfuerzo, lo que en última instancia aumenta la producción de su granja.
                                    </p>
                                </div>
                                :
                                <div className="text">
                                    <h3>Improved Productivity</h3>
                                    <p>
                                        Mechanization reduces reliance on manual labor, enabling you to complete tasks faster and with less effort, ultimately increasing your farm's output.
                                    </p>
                                </div>
                        }
                    </div>

                    <div className="d-flex">
                       
<img
    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/Chart.png"
    style={bottomIconStyle} />
                        {
                            activeLanguage === "Español" ?
                                <div className="text">
                                    <h3>Costos reducidos</h3>
                                    <p>
                                        Si bien existe una inversión inicial, alquilar equipos puede ser más rentable a largo plazo en comparación con comprar y mantener su propia maquinaria.
                                    </p>
                                </div>
                                :
                                <div className="text">
                                    <h3>Reduced Costs</h3>
                                    <p>
                                        While there's an initial investment, renting equipment can be more cost-effective in the long run compared to purchasing and maintaining your own machinery.
                                    </p>
                                </div>
                        }
                    </div>

                    <div className="d-flex">
                        
<img
    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/Star.png"
    style={bottomIconStyle} />
                        {
                            activeLanguage === "Español" ?
                                <div className="text">
                                    <h3>Mejora de la salud del suelo</h3>
                                    <p>
                                        Los equipos modernos pueden promover mejores prácticas de manejo del suelo, como la labranza controlada y la aplicación precisa de nutrientes, lo que conduce a una mejor salud del suelo y una productividad a largo plazo.
                                    </p>
                                </div>
                                :
                                <div className="text">
                                    <h3>Improved Soil Health</h3>
                                    <p>
                                        Modern equipment can promote better soil management practices like controlled tillage and precise nutrient application, leading to improved soil health and long-term productivity.
                                    </p>
                                </div>
                        }
                    </div>
                </div>
                <div className="creativity-right">
                    <img src={"https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/farm_mech.webp"} alt="" />
                </div>
            </div>
        </div>
    );
}

export default FirstComponent