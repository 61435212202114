import React from 'react'
import './Partner.scss'
import Banner from './Banner'
import FirstComponent from './FirstComponent'
import SecondComponent from './SecondComponent'
import ThirdComponent from './ThirdComponent'
import FourthComponent from './FourthComponent'
import FifthComponent from './FifthComponent'
import SixthComponent from './SixthComponent'
import SeventhComponent from './SeventhComponent'

const Partner = () => {
  return (
    <div className='Partner'>

        <Banner />

        <FirstComponent />

        <SecondComponent />

        <ThirdComponent />

        <FourthComponent />

        <FifthComponent />

        <SixthComponent />

        <SeventhComponent />

    </div>
  )
}

export default Partner