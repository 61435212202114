import React from 'react'
import { useSelector } from 'react-redux'

const SecondComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const containerArrayDetails = activeLanguage === "Español" ? [
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Empoderando a su comunidad",
            paragraphs: "Nuestro modelo de pago por uso hace que los equipos potentes sean accesibles para todos, fomentando una economía colaborativa dentro de su comunidad local."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Construyendo conexiones más fuertes",
            paragraphs: "Conéctese con otros inquilinos y propietarios de tractores a través de nuestra plataforma, comparta consejos e ideas de proyectos y cree una red de recursos locales."
        },
    ]
    :
    [
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Empowering Your Community",
            paragraphs: "Our pay-as-you-go model makes powerful equipment accessible to everyone, fostering a sharing economy within your local community."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Building Stronger Connections",
            paragraphs: "Connect with other renters and tractor owners through our platform, share tips and project ideas, and build a network of local resources."
        },
    ]

    return (
        <div className='SecondComponent'>

            <div className='container'>

                {
                    containerArrayDetails.map((details, index) => {
                        return (
                            <div
                                className='box'
                                key={index}>
                                <div className="boxTop">
                                    <video
                                        src={details.videoLink}
                                        autoPlay
                                        loop
                                        controls={false}
                                        width="120px" // Adjust width as needed
                                        height="auto" // Adjust height as needed
                                    />
                                    <div className='boxTopRight'>
                                        <p>
                                            {details.mainHeading}
                                        </p>
                                    </div>
                                </div>
                                <div className="boxBottom">
                                    <p>
                                        {details.paragraphs}
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default SecondComponent