// import ImgOne from "../../utils/track1.jpg"
// import ImgTwo from "../../utils/track2.jpg"
// import ImgThree from "../../utils/track3.jpg"
// import ImgFour from "../../utils/track4.jpeg"
// import ImgFive from "../../utils/track5.jpg"
// import ImgSix from "../../utils/track6.jpg"
// import ImgSeven from "../../utils/track7.jpg"

const ImgOne = "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tracktor13.png"
const ImgTwo = "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/hola/cfea1377c9.webp"
const ImgThree = "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/hola/b844d527af.webp"
const ImgFour = "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/hola/95d39a60ac.webp"
const ImgFive = "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/hola/68f2e6d62f.webp"
const ImgSeven = "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/hola/1c42aae3ca.webp"
const ImgSix = "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/hola/PngItem_3609679.webp"
// const ImgSeven = "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tracktor13.png"


const CollectionData = [
    {
        id:1,
        image : ImgOne,
        title : "arado de tractores",
        para : "mejor para uso agrícola"
    },
    {
        id:2,
        image : ImgTwo,
        title : "tractor manual",
        para : ""
    },
    {
        id:3,
        image : ImgThree,
        title : "Tractor con maquinaria",
        para : ""
    },
    {
        id:4,
        image : ImgFour,
        title : "Tractor adjunto de perforación",
        para : ""
    },
    {
        id:5,
        image : ImgSeven,
        title : "Maquinaria grande",
        para : ""
    },
    {
        id:6,
        image : ImgSix,
        title : "Tractor para la construcción",
        para : ""
    },
    {
        id:7,
        image : ImgFive,
        title : "tractor de pozo",
        para : ""
    },
    
]

export const CollectionDataEnglish = [
    {
        id:1,
        image : ImgOne,
        title : "tractor plow",
        para : "best for agricultural use"
    },
    {
        id:2,
        image : ImgTwo,
        title : "manual tractor",
        para : ""
    },
    {
        id:3,
        image : ImgThree,
        title : "Tractor with machinery",
        para : ""
    },
    {
        id:4,
        image : ImgFour,
        title : "Attached tractor drilling",
        para : ""
    },
    {
        id:5,
        image : ImgSeven,
        title : "large machinery",
        para : ""
    },
    {
        id:6,
        image : ImgSix,
        title : "Construction tractor",
        para : ""
    },
    {
        id:7,
        image : ImgFive,
        title : "well tractor",
        para : ""
    },
    
]

export default CollectionData;