import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const SecondComponent = () => {

    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const contentArray = activeLanguage === "Español" ? [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Recursos en línea",
            para: "Holatractor es una plataforma integral en línea que proporciona información y recursos sobre el uso, mantenimiento, resolución de problemas y pautas de seguridad de los equipos de tractores."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Demostraciones de campo",
            para: "Holatractor realiza demostraciones de campo y sesiones de capacitación práctica donde los miembros de la comunidad pueden aprender observando y participando en prácticas prácticas. Esto puede ayudar a reforzar el conocimiento teórico con aplicaciones en el mundo real y fomentar una mejor comprensión del funcionamiento del equipo del tractor."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Eventos comunitarios",
            para: "Holatractor organiza eventos comunitarios, como eventos agrícolas, ferias comerciales y exposiciones de equipos, donde los propietarios y operadores de tractores pueden interactuar con expertos, fabricantes y proveedores de la industria. Estos eventos pueden servir como valiosas oportunidades para establecer contactos y brindar acceso a los últimos avances en tecnología de tractores."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Foros en línea y redes sociales",
            para: "Holatractor crea y mantiene foros en línea, grupos de redes sociales y plataformas de discusión dedicados a los entusiastas de los equipos para tractores. Fomentar la participación activa y el intercambio de conocimientos dentro de estas comunidades, y brindar apoyo y asistencia oportuna a los miembros que buscan asesoramiento o soluciones a sus problemas."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/attachment.png",
            heading: "Manuales y guías de usuario",
            para: "Holatractor crea manuales y guías fáciles de usar que brindan instrucciones paso a paso para operar y mantener el equipo del tractor. Incluya ilustraciones, consejos para la solución de problemas y precauciones de seguridad para garantizar la facilidad de uso."
        },
    ] : [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Online resources",
            para: "Holatractor is a comprehensive online platform that provides information and resources on tractor equipment use, maintenance, troubleshooting, and safety guidelines."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Field demonstrations",
            para: "Holatractor conducts field demonstrations and hands-on training sessions where community members can learn by observing and participating in hands-on practices. This can help reinforce theoretical knowledge with real-world applications and foster a better understanding of how tractor equipment works."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Community events",
            para: "Holatractor hosts community events, such as agricultural events, trade shows and equipment expos, where tractor owners and operators can interact with industry experts, manufacturers and suppliers. These events can serve as valuable networking opportunities and provide access to the latest advancements in tractor technology."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Online forums and social networks",
            para: "Holatractor creates and maintains online forums, social media groups and discussion platforms dedicated to tractor equipment enthusiasts. Encourage active participation and knowledge sharing within these communities, and provide timely support and assistance to members seeking advice or solutions to their problems."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/attachment.png",
            heading: "Manuals and user guides",
            para: "Holatractor creates easy-to-use manuals and guides that provide step-by-step instructions for operating and maintaining tractor equipment. Include illustrations, troubleshooting tips, and safety precautions to ensure ease of use."
        },
    ]

    return (
        <div className='SecondComponent'>

            <div className="headings">

                <h2>
                    {
                        activeLanguage === "Español" ?
                            "Participación de la comunidad para una operación perfecta de los equipos de tractores"
                            :
                            "Community participation for perfect operation of tractor equipment"
                    }
                </h2>

                <p>
                    {
                        activeLanguage === "Español" ?
                            "Holatractor tiene como objetivo fomentar una plataforma vibrante de participación comunitaria que integre a la perfección el conocimiento de la maquinaria para mejorar la facilidad de uso del equipo tractor. A través de esfuerzos colaborativos y experiencia compartida, los usuarios pueden acceder a una gran cantidad de información, consejos y mejores prácticas adaptadas a sus necesidades específicas. Al facilitar la comunicación abierta y la interacción entre los miembros, Holatractor crea un entorno de apoyo donde los usuarios pueden intercambiar ideas, solucionar problemas y mantenerse actualizados sobre las últimas innovaciones."
                            :
                            "Holatractor aims to foster a vibrant community engagement platform that seamlessly integrates machinery knowledge to improve the usability of tractor equipment. Through collaborative efforts and shared expertise, users can access a wealth of information, advice, and best practices tailored to their specific needs. By facilitating open communication and interaction between members, Holatractor creates a supportive environment where users can exchange ideas, solve problems, and stay up to date on the latest innovations."
                    }
                </p>

            </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className='box'
                                        key={index}
                                        onClick={() => { setActiveContnentHeading(index) }}>
                                        <div
                                            className='heading'
                                            style={{
                                                color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                            }}>
                                            <p>{index + 1 > 9 ? index + 1 : `0${index + 1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img
                            src={contentArray[activeContentHeading].img}
                            alt="" />

                        <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                        </div>

                        <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default SecondComponent