import React from 'react'
import serviceBackgroundVideo from '../../../../../assets/video/service.mp4'
import { useSelector } from 'react-redux'

const Banner = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className="Banner">

            <video autoPlay loop muted>
                <source src={serviceBackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <h1>
                {
                    activeLanguage === "Español" ?
                        "Alquiler de tractores de pago por uso para agricultores con holatractor"
                        :
                        "Pay-As-You-Go Tractor Rentals for Farmers with holatractor"
                }
            </h1>

            {
                activeLanguage === "Español" ?
                    <p className='paragraphOne'>
                        Creemos que cada agricultor merece acceso a la energía que necesita para tener éxito. Es por eso que ofrecemos un revolucionario servicio de alquiler de tractores de pago por uso diseñado específicamente para sus necesidades agrícolas.
                    </p>
                    :
                    <p className='paragraphOne'>
                        we believe every farmer deserves access to the power they need to succeed. That's why we offer a revolutionary  <b style={{color: "#AB0F0C"}}>pay-as-you-go tractor rental service </b> specifically designed for your agricultural needs.
                    </p>
            }

        </div>
    )
}

export default Banner