import React from 'react';
import styles from './custom-section.module.css';
import { ArrowRight } from 'lucide-react'

function CustomSection() {
  return (
    <section className={styles.container}>
      <div className={styles.header}>
        
          <h2 className={styles.title}>
            Our <span className={styles.customWrapper}>
              custom
              <img
                src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/66ba221ee11a105825d90a67_home-p-cases-title-underline.svg"
                alt=""
                className={styles.underlineImg}
              />
            </span> approach leads projects to{' '}
            <span className={styles.highlight}>success</span>
          </h2>
        <button className={styles.moreButton}>
          More projects <ArrowRight className="w-4 h-4" />
        </button>
      </div>

      <div className={styles.card}>


        <div className={styles.cardContent}>
          <div className={styles.imageGrid}>
            <img
              src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/657189228e6c4708a4d40312_wordpress-logo.svg"
              alt="WordPress.com logo"

            />
            <img
              src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/65af916477d1d2c6a5958769_case-wordpress-1.webp"
              alt="WordPress marketing preview"

              className={styles.images}
            />
          </div>

          <div className={styles.info}>
            <img
              src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/65eef669d28a05fda3172b0e_case-wp-2.webp"
              alt="WordPress marketing preview"

              className={styles.image}
            />
            <div className={styles.tags}>
              <span className={styles.tag}>Automattic</span>
              <span className={styles.tag}>SaaS</span>
            </div>

            <p className={styles.description}>
              WordPress, the world's most popular website builder, cooperated with our UI/UX design
              services company to get help with branded marketing materials and landing pages to
              advertise various WordPress products.
            </p>

            <button className={styles.caseButton}>
              Check the full case <ArrowRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CustomSection;

