import React from 'react';

import styles from './case-study.module.css'
import { ArrowRight } from 'lucide-react'

export default function CaseStudy() {
  return (
    <section className={styles.container}>
      <div className={styles.card}>
        <div className={styles.leftContent}>
          <div className={styles.logoContainer}>
            <img
              src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/657189223c5ff6cec91f805c_players-health-logo.svg"
              alt="Players Health Logo"
             
              className={styles.logo}
            />
          </div>
          <div className={styles.imageDiv}>
            <img
              src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/65718624440c7bd747355c22_players-health-1.webp"
              alt="Small dashboard preview"
              className={styles.smallImage}
            />
          </div>
          <div className={styles.dashboardContainer}>            <img
              src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/65718624db5e5db977508543_players-health-2.webp"
              alt="Mobile app dashboard"
             
              className={styles.dashboardImage}            />
          </div>
        </div>
        <div className={styles.rightContent}>
          <img
            src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/65718624db5e5db977508543_players-health-2.webp"
            alt="Desktop dashboard interface"
            width={500}
            height={300}
            className={styles.dashboard}
          />
          <div className={styles.stats}>
            <span className={styles.amount}>$34M raised</span>
            <span className={styles.category}>Fintech</span>
          </div>
          <p className={styles.description}>
            Player's Health is a finance platform that creates the safest and most accessible environments for young athletes. Our team joined the client's team, provided UI design services, created a product design that resulted in $34M funding raised.
          </p>
          <button className={styles.cta}>
            Check the full case
            <ArrowRight className={styles.arrow} />
          </button>
        </div>
      </div>
    </section>
  )
}

