import React from 'react'
import './UtilityTractors.scss'
import Banner from './Banner'
import FourthComponent from './FourthComponent'
import SecondComponent from './SecondComponent'
import SeventhComponent from './SeventhComponent'
import FirstComponent from './FirstComponent'

const UtilityTractors = () => {
  return (
    <div className='UtilityTractors'>

        <Banner />

        <FirstComponent />

        <SecondComponent />

        <SeventhComponent />

        <FourthComponent />

    </div>
  )
}

export default UtilityTractors