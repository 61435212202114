import React, { useEffect, useState } from 'react'
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { useSelector } from 'react-redux'

const ThirdComponent = () => {

    const [activeArrayElement, setActiveArrayElement] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            mainHeading: "Modernización de flotas de tractores",
            subHeading: "Nos esforzamos por introducir modelos de tractores más nuevos y eficientes en combustible en nuestro grupo de alquiler. Los avances en la tecnología de motores y las herramientas agrícolas de precisión pueden reducir significativamente el consumo de combustible y las emisiones en comparación con los tractores más antiguos."
        },
        {
            mainHeading: "Optimización de la utilización de recursos",
            subHeading: "Nuestra plataforma en línea facilita el alquiler eficiente de tractores, garantizando que los tractores se utilicen solo cuando sea necesario. Esto minimiza el tiempo de inactividad y el impacto ambiental asociado."
        },
        {
            mainHeading: "Construyendo una comunidad sostenible",
            subHeading: "Nos asociamos con organizaciones agrícolas locales, grupos ambientalistas e instituciones de investigación para fomentar el intercambio de conocimientos y la colaboración hacia prácticas agrícolas sostenibles."
        },
        {
            mainHeading: "Promoción de prácticas sostenibles",
            subHeading: "Abogamos y educamos a nuestra red de agricultores sobre prácticas sostenibles como labranza reducida, cultivos de cobertura y técnicas de riego eficientes. Estos métodos pueden minimizar la erosión del suelo, mejorar la salud del suelo y reducir las emisiones de gases de efecto invernadero."
        },
        {
            mainHeading: "Apoyando la innovación",
            subHeading: "Nos mantenemos informados sobre las tecnologías emergentes en el sector agrícola, como las herramientas de agricultura de precisión y la automatización. Exploramos oportunidades para integrar estas tecnologías en nuestros servicios, promoviendo la toma de decisiones basada en datos y la optimización de recursos para los agricultores."
        },

    ]
        :
        [
            {
                mainHeading: "Tractor fleet modernization",
                subHeading: "We strive to introduce newer, more fuel-efficient tractor models into our rental group. Advances in engine technology and precision farming tools can significantly reduce fuel consumption and emissions compared to older tractors."
            },
            {
                mainHeading: "Optimization of resource utilization",
                subHeading: "Our online platform facilitates efficient tractor rental, ensuring that tractors are used only when necessary. This minimizes downtime and the associated environmental impact."
            },
            {
                mainHeading: "Building a sustainable community",
                subHeading: "We partner with local agricultural organizations, environmental groups, and research institutions to foster knowledge sharing and collaboration toward sustainable agricultural practices."
            },
            {
                mainHeading: "Promotion of sustainable practices",
                subHeading: "We advocate and educate our network of farmers on sustainable practices such as reduced tillage, cover crops, and efficient irrigation techniques. These methods can minimize soil erosion, improve soil health, and reduce greenhouse gas emissions."
            },
            {
                mainHeading: "Supporting innovation",
                subHeading: "We stay informed on emerging technologies in the agricultural sector, such as precision farming tools and automation. We explore opportunities to integrate these technologies into our services, promoting data-driven decision making and resource optimization for farmers."
            },

        ]

    function changeContext() {
        setActiveArrayElement(prev => prev + 1 === componentArray.length ? 0 : prev + 1)
    }

    useEffect(() => {
        const intervalSet = setInterval(() => {
            changeContext()
        }, 3000)
        return () => clearInterval(intervalSet);
    }, [])

    return (
        <div className="ThirdCpmponent">

            <div className="container">

                <div className="left-container">

                    <p className='mainHeading'>
                        {
                            activeLanguage === "Español" ?
                                "Tecnología e Innovación:"
                                :
                                "Technology and Innovation:"
                        }
                    </p>

                    <div className="paragraphsContainer">

                        {
                            componentArray.map((details, index) => {
                                return (
                                    <div className='box' key={index}>

                                        <div className='box-top'>
                                            <AcUnitIcon />
                                            <p>
                                                {details.mainHeading}
                                            </p>
                                        </div>

                                        <div className="box-middle">
                                            <div
                                                className="inside-box-middle"
                                                style={{
                                                    display: activeArrayElement === index ? 'block' : 'none'
                                                }} />
                                        </div>

                                        <div className="box-bottom" style={{
                                            display: activeArrayElement === index ? 'block' : 'none'
                                        }}>
                                            <p>
                                                {details.subHeading}
                                            </p>
                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

                <div className="right-container">
                    <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractor_two.webp" alt="Third component" />
                </div>

            </div>

        </div>
    )
}

export default ThirdComponent