import React from 'react'

const FourthComponent = () => {
    const advantagesArray = [
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            You can easily use this calculator and get the exact amount of EMI that has to be paid.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            You can easily use this calculator and get the exact amount of EMI that has to be paid.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            You can easily use this calculator and get the exact amount of EMI that has to be paid.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            You can easily use this calculator and get the exact amount of EMI that has to be paid.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            You can easily use this calculator and get the exact amount of EMI that has to be paid.
        </p>,
    ]

    const factorsArray = [
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Interest Rate: </span> The interest rate is what you pay for borrowing the loan. In short, it shows you the cost of your loan. It differs from one bank to another. The higher the interest rate, the higher the EMI will be. Finding a lender that offers you the lowest interest rates can help you lower your home loan EMIs. The lower the interest rate, the lower the total cost of your loan will be.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Interest Rate: </span> The interest rate is what you pay for borrowing the loan. In short, it shows you the cost of your loan. It differs from one bank to another. The higher the interest rate, the higher the EMI will be. Finding a lender that offers you the lowest interest rates can help you lower your home loan EMIs. The lower the interest rate, the lower the total cost of your loan will be.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Interest Rate: </span> The interest rate is what you pay for borrowing the loan. In short, it shows you the cost of your loan. It differs from one bank to another. The higher the interest rate, the higher the EMI will be. Finding a lender that offers you the lowest interest rates can help you lower your home loan EMIs. The lower the interest rate, the lower the total cost of your loan will be.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Interest Rate: </span> The interest rate is what you pay for borrowing the loan. In short, it shows you the cost of your loan. It differs from one bank to another. The higher the interest rate, the higher the EMI will be. Finding a lender that offers you the lowest interest rates can help you lower your home loan EMIs. The lower the interest rate, the lower the total cost of your loan will be.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Interest Rate: </span> The interest rate is what you pay for borrowing the loan. In short, it shows you the cost of your loan. It differs from one bank to another. The higher the interest rate, the higher the EMI will be. Finding a lender that offers you the lowest interest rates can help you lower your home loan EMIs. The lower the interest rate, the lower the total cost of your loan will be.
        </p>,
    ]

    return (
        <div className='FourthComponent'>

            <p className="mainHeading">
                Advantages of using BankBazaar Home loan EMI calculator:
            </p>

            <p>
                The advantages of using the Home Loan EMI calculator offered by BankBazaar are as follows:
            </p>

            <ul
                style={{ paddingLeft: '20px' }}>

                {
                    advantagesArray.map((details, index) => {
                        return (
                            <li
                                key={index}>
                                {details}
                            </li>
                        )
                    })
                }

            </ul>

            <p className="mainHeading">
                What Factors Affect Your Home Loan EMI?
            </p>

            <p>
                When calculating your EMI, the calculator includes all the parameters that impact it. Let’s take a look at the various factors that affect your monthly repayments:
            </p>

            <ul
                style={{ paddingLeft: '20px' }}>

                {
                    factorsArray.map((details, index) => {
                        return (
                            <li
                                key={index}>
                                {details}
                            </li>
                        )
                    })
                }

            </ul>

            <p className="mainHeading">
                What are Flexible and Fixed Home Loan EMIs?
            </p>

            <p>
                Your housing loan EMI is usually a fixed amount. But there are certain circumstances under which it can change. Let’s take a look at some of these instances:
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Flexible Home Loan EMIs - </span> If you have opted for flexible EMIs, such as in the case of step-up or step-down property loans, then there will be a change in your loan EMIs. In the case of step-up loans, your EMI will gradually increase over the tenure. In the case of step-down loans, your EMIs will decrease as time passes.
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Partly Disbursed Loan - </span> In the case of partly disbursed loans, the pre-EMIs will increase with each disbursement.
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Home Loan Prepayment - </span> Prepayments impact your EMIs as well. You can either choose to reduce the EMI for the same loan tenure or reduce the loan tenure and keep the EMIs constant. The latter is more beneficial since you will pay less interest.
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Rise in the floating interest rate: </span> Whenever the repo rate changes, the floating rate of interest also changes. If the floating rate increases, the lender will normally increase the loan tenure while keeping your EMI the same. If the new tenure exceeds the maximum period permitted, then the bank may increase your EMI.
            </p>

            <p className="mainHeading">
                What is a Home Loan Pre-EMI?
            </p>

            <p>
                In some cases, the lender will disburse the home loan amount in stages. When this happens, you have to start paying your EMI only when you get the final amount and the whole loan has been disbursed. Till this date, you have to pay only the interest applicable on the partly spent amounts. This is known as pre-EMI. Once the loan gets fully disbursed, the pre-EMIs will stop and, you have to start paying your regular EMIs.
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            However, a wiser option would be to opt for a full EMI payment, as it ensures that you have already paid down a portion of the remaining loan amount at the time of possession.
            </p>

        </div>
    )
}

export default FourthComponent