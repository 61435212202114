import React from 'react';
import "./forbuilders.scss";
import BuilderLogo from "../../../../utils/d_hp_ppf_xl.jpg"
import { useSelector } from 'react-redux';

const ForInvestor = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='forbuilder-container'>

            {
                activeLanguage === "Español" ?
                    <div className="builder-box-one">

                        <p>¿Cómo invertir con holatractor?</p>

                        <p>¿Quieres ser inversor a pequeña escala?</p>

                        <p>¿Qué es el crowdfunding de Holatractor?</p>

                        <p>¿Cómo asegurar su dinero y obtener beneficios con holatractor?</p>

                    </div>
                    :
                    <div className="builder-box-one">

                        <p>How to invest with holatractor?</p>

                        <p>Do you want to be a small-scale investor?</p>

                        <p>What is Holatractor crowdfunding?</p>

                        <p>How to secure your money and make profits with Holatractor?</p>

                    </div>
            }

            {
                activeLanguage === "Español" ?
                    <div className="builder-box-two">
                        <p>Relación con inversores</p>
                        <div className="box-two-text">
                            <p>
                                Datos de inversores
                            </p>
                            <p>
                                Documentos de Holatrctor
                            </p>
                            <p>
                                <button>Aplica ya</button>
                            </p>
                        </div>
                    </div>
                    :
                    <div className="builder-box-two">
                        <p>Investor relations</p>
                        <div className="box-two-text">
                            <p>
                                Investor data
                            </p>
                            <p>
                                Holatrctor Documents
                            </p>
                            <p>
                                <button>Apply now</button>
                            </p>
                        </div>
                    </div>
            }

            <div className="builder-box-three">
                <img src={BuilderLogo} alt="Builder Image" />
                <div className="awarded-section">
                    {
                        activeLanguage === "Español" ?
                            <p>@ premiado y reconocido por</p>
                            :
                            <p>@ awarded and recognized by</p>
                    }
                    <div className="award-images">
                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/recognize/Bayer_Foundation_Pure_Sign_Dark_Green_RGB+1.webp"
                            alt="foundation image" />
                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/recognize/983_ifad_logo+1.webp"
                            alt="iFad" />
                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/recognize/GSMA-Logo-Red_2022+1.webp"
                            alt="GSMA" />
                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/recognize/IICA-Logo-Social-Communications+1.webp"
                            alt="IICA" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForInvestor