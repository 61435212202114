import React from 'react'
import './CommercialTractors.scss'
import Banner from './Banner'
import FirstComponent from './FirstComponent'
import SecondComponent from './SecondComponent'
import SeventhComponent from './SeventhComponent'
import FourthComponent from './FourthComponent'

const CommercialTractors = () => {
  return (
    <div className='CommercialTractors'>

        <Banner />

        <FirstComponent />

        <SecondComponent />

        <SeventhComponent />

        <FourthComponent />

    </div>
  )
}

export default CommercialTractors