import React, { useState } from 'react';
import styles from './FAQSection.module.css';
import { ChevronRight } from 'lucide-react';

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "Can your designers prodvide design solutions for complex B2B use cases?",
      answer: "Yes, our designers have extensive experience in creating solutions for complex B2B scenarios. We focus on understanding your specific business needs and delivering tailored design solutions that address your unique challenges."
    },
    {
      question: "Can I interview a designer and see their portfolio?",
      answer: "We encourage potential clients to meet our designers and review their portfolios. This helps ensure the best fit for your project and allows you to see their relevant experience firsthand."
    },
    {
      question: "How do you keep the communication during the project?",
      answer: "We maintain regular communication through scheduled check-ins, progress updates, and collaborative tools. Our team is always available for discussions and feedback to ensure project alignment."
    },
    {
      question: "What services does your design agency provide?",
      answer: "We offer a comprehensive range of design services including UI/UX design, brand identity, web design, mobile app design, and product design. Each service is customized to meet your specific requirements."
    },
    {
      question: "What is the timeline for a design project?",
      answer: "Project timelines vary based on scope and complexity. Typically, projects range from 4-12 weeks. We'll provide a detailed timeline during the initial consultation based on your specific needs."
    }
  ];

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className={styles.faqSection}>
      <div className={styles.container}>
        <h2 className={styles.title}>Frequently asked questions</h2>
        
        <div className={styles.accordionContainer}>
          {faqs.map((faq, index) => (
            <div key={index} className={styles.accordionItem}>
              <button
                className={`${styles.accordionTrigger} ${openIndex === index ? styles.open : ''}`}
                onClick={() => toggleAccordion(index)}
              >
                <div className={styles.chevronContainer}>
                  <ChevronRight className={styles.chevron} />
                </div>
                <span>{faq.question}</span>
              </button>
              {openIndex === index && (
                <div className={styles.accordionContent}>
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;

