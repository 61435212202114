import React from 'react'
import './ServisiosDeReparacionDeEscalones.scss'
import { motion } from 'framer-motion'
import SecondComponent from './SecondComponent'
import { useSelector } from 'react-redux'

const ServisiosDeReparacionDeEscalones = () => {

  const { activeLanguage } = useSelector(state => state.Languages)

  return (
    <div className='ServisiosDeReparacionDeEscalones'>

      <div className="banner">

        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_group.webp" alt="banner image" />

        <div className='shadow' />

        <motion.div
          initial={{
            x: -100,
            opacity: 0
          }}
          whileInView={{
            x: 0,
            opacity: 1
          }}
          transition={{
            duration: 1
          }}
          className='bannerTextContainer'>

          {
            activeLanguage === "Español" ?
              <div className='bannerText'>
                <p>
                  Requisito de publicación
                </p>
                <p>
                  Impulsando tractores comunitarios para el crecimiento comunitario
                </p>
                <button
                  name="publicación comunitaria button">
                  Publicación comunitaria
                </button>
              </div>
              :
              <div className='bannerText'>
                <p>
                  Publication requirement
                </p>
                <p>
                  Driving community tractors for community growth
                </p>
                <button
                  name="publicación comunitaria button">
                  Community Post
                </button>
              </div>
          }

        </motion.div>

      </div>

      <SecondComponent />

    </div>
  )
}

export default ServisiosDeReparacionDeEscalones