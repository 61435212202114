import React, { useEffect, useRef } from 'react';
import './SplitLayout.css';

const SplitLayout = () => {
  const cardsRef = useRef(null);
  const [activeCard, setActiveCard] = React.useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveCard(Number(entry.target.dataset.index));
          }
        });
      },
      {
        root: null,
        threshold: 0.5,
      }
    );

    const cards = cardsRef.current.querySelectorAll('.content-card');
    cards.forEach((card) => observer.observe(card));

    return () => observer.disconnect();
  }, []);

  const contentCards = [
    {
      title: "Framer development",
      description: "Transforming design into captivating digital realities with precision and innovation using design tools called Framer",
      image: "https://m.media-amazon.com/images/I/61qAJu32q6L._AC_UF1000,1000_QL80_.jpg",
    },
    {
      title: "Web Development",
      description: "Creating responsive and modern web applications with cutting-edge technologies",
      image: "https://t3.ftcdn.net/jpg/09/33/09/48/360_F_933094884_ucVApr6sPi8cCBQREBp3PokmWwrlY37P.jpg",
    },
    {
      title: "UI/UX Design",
      description: "Crafting intuitive and beautiful user interfaces that enhance user experience",
      image: "https://img.pikbest.com/origin/09/43/42/20ipIkbEsTMAP.jpeg!w700wp",
    },
    {
        title: "Web Development",
        description: "Creating responsive and modern web applications with cutting-edge technologies",
        image: "https://t3.ftcdn.net/jpg/09/33/09/48/360_F_933094884_ucVApr6sPi8cCBQREBp3PokmWwrlY37P.jpg",
      },
      {
        title: "UI/UX Design",
        description: "Crafting intuitive and beautiful user interfaces that enhance user experience",
        image: "https://img.pikbest.com/origin/09/43/42/20ipIkbEsTMAP.jpeg!w700wp",
      },
  ];

  return (
    <div className="split-layout">
      <div className="left-section">
        <h1>Let me assist you in achieving your goals</h1>
        <p className="description">
          I offer expertise in web development, problem-solving, and strategic planning 
          for tailored solutions that match your unique needs.
        </p>
        <div className="stats">
          <div>
            <h2>50+</h2>
            <p>Projects finished</p>
          </div>
          <div>
            <h2>80+</h2>
            <p>Happy Clients</p>
          </div>
        </div>
      </div>

      <div className="right-section" ref={cardsRef}>
        {contentCards.map((card, index) => (
          <div
            key={index}
            data-index={index}
            className="content-card"
          >
            <div className="card-content">
              <div className="card-image">
                <img src={card.image} alt={card.title} />
              </div>
              <h2>{card.title}</h2>
              <p>{card.description}</p>
              <button className="view-work-btn">View example work</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SplitLayout;

