import React from 'react'
import './ServiciosDeSeguimineto.scss'
import Banner from './Banner'
import FirstComponent from './FirstComponent'
import SecondComponent from './SecondComponent'
import FifthComponent from './FifthComponent'

const ServiciosDeSeguimineto = () => {
  return (
    <div className='ServiciosDeSeguimineto'>

        <Banner />

        <FirstComponent />

        <SecondComponent />

        <FifthComponent />

    </div>
  )
}

export default ServiciosDeSeguimineto