import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';

const SecondComponent = () => {
    const [expandedState, setExpandedState] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            tag: "Inventario extenso",
            details: "Ofrecemos una amplia variedad de tractores de fabricantes líderes que se adaptan a cualquier tamaño y presupuesto de granja."
        },
        {
            tag: "Consejos de expertos",
            details: "Nuestros agentes expertos pueden ayudarlo a elegir el tractor adecuado para sus necesidades específicas y responder cualquier pregunta que pueda tener."
        },
        {
            tag: "Opciones de financiación",
            details: "Ofrecemos financiación flexible y soluciones de incentivos para ayudarle a conseguir el equipo que necesita."
        },
        {
            tag: "Piezas y servicio",
            details: "Nuestro agregador de servicios dedicado brinda mantenimiento y reparaciones expertos para mantener su tractor funcionando sin problemas."
        },
        {
            tag: "Compromiso con la innovación",
            details: "Nos mantenemos a la vanguardia de la tecnología agrícola, ofreciendo tractores equipados con los últimos avances para un rendimiento óptimo."
        },
    ] : [
        {
            tag: "Extensive Inventory",
            details: "We offer a wide variety of tractors from leading manufacturers to suit any farm size and budget."
        },
        {
            tag: "Expert Advice",
            details: "Our knowledgeable Agents can help you choose the right tractor for your specific needs and answer any questions you may have."
        },
        {
            tag: "Financing Options",
            details: "We offer flexible financing and incentive solutions to help you secure the equipment you need."
        },
        {
            tag: "Parts & Service",
            details: "Our dedicated service aggregator provides expert maintenance and repairs to keep your tractor running smoothly."
        },
        {
            tag: "Commitment to Innovation",
            details: "We stay at the forefront of agricultural technology, offering tractors equipped with the latest advancements for optimal performance."
        },
    ]

    return (
        <div className="SecondComponent">

            <div className='left'>

                <img
                    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/field_agent.webp"
                    alt=""
                    className='w-full 1000px:w-[90%] rounded-[6px]' />

            </div>

            <div className="rigth">

                <div className="heading">
                    {
                        activeLanguage === "Español" ?
                            "¿Por qué elegir Holatractor para sus necesidades de tractores agrícolas?"
                            :
                            "Why Choose Holatractor for Your Agricultural Tractor Needs?"
                    }
                </div>

                {
                    componentArray.map((details, index) => {
                        return (
                            <div key={index}>

                                <div className='box-top' onClick={() => { setExpandedState(index) }}>
                                    <span>{details.tag}</span>
                                    <ExpandMoreIcon
                                        style={{
                                            transform: expandedState === index ? 'rotate(180deg)' : 'rotate(0deg)'
                                        }} />
                                </div>

                                <div className='box-bottom'
                                    style={{
                                        height: expandedState === index ? '100px' : '0px',
                                        display: expandedState === index ? 'block' : 'none'
                                    }}>
                                    {details.details}
                                </div>

                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default SecondComponent