import React from 'react';
import "./forbuilders.scss";
import BuilderLogo from "../../../../utils/d_hp_ppf_xl.jpg"
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ForComunidad = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='forbuilder-container'>

            {
                activeLanguage === "Español" ?
                    <div className="builder-box-one">

                        <p>¿Cómo unirse a una comunidad?</p>

                        <p> ¿Cómo unirse como propietario de la comunidad?</p>

                        <p> ¿Cómo ayudar a los agricultores como propietarios de la comunidad?</p>

                        <p> ¿Cómo obtener beneficios de nuestra comunidad?</p>

                    </div>
                    :
                    <div className="builder-box-one">

                        <p>How to join a community?</p>

                        <p>How to join as a community owner?</p>

                        <p>How to help farmers as community owners?</p>

                        <p>How to obtain benefits from our community?</p>

                    </div>
            }

            {
                activeLanguage === "Español" ?
                    <div className="builder-box-two">
                        <p>Características de la comunidad</p>
                        <div className="box-two-text">
                            <Link to={'/comunidad/post-requirement-of-tractor'} style={{ textDecoration: 'none' }}>
                                <p>Poste requisito de tractor</p>
                            </Link>
                            <Link to={'/comunidad/beneficio-tecnológico-para-la-agricultura'} style={{ textDecoration: 'none' }}>
                                <p>Beneficio tecnológico para la agricultura</p>
                            </Link>
                            <Link to={'/comunidad/reuniones-communitrates'} style={{ textDecoration: 'none' }}>
                                <p>Reuniones comunitarias</p>
                            </Link>
                            <Link to={'/comunidad/consejos-de-cultivo'} style={{ textDecoration: 'none' }}>
                                <p>Consejos de cultivo</p>
                            </Link>
                        </div>
                    </div>
                    :
                    <div className="builder-box-two">
                        <p>Community Features</p>
                        <div className="box-two-text">
                            <Link to={'/comunidad/post-requirement-of-tractor'} style={{ textDecoration: 'none' }}>
                                <p>Tractor Requirement Pole</p>
                            </Link>
                            <Link to={'/comunidad/beneficio-tecnológico-para-la-agricultura'} style={{ textDecoration: 'none' }}>
                                <p>Technological benefit for agriculture</p>
                            </Link>
                            <Link to={'/comunidad/reuniones-communitrates'} style={{ textDecoration: 'none' }}>
                                <p>Community meetings</p>
                            </Link>
                            <Link to={'/comunidad/consejos-de-cultivo'} style={{ textDecoration: 'none' }}>
                                <p>Growing Tips</p>
                            </Link>
                        </div>
                    </div>
            }

            <div className="builder-box-three">
                <img src={BuilderLogo} alt="Builder Image" />
            </div>

            {
                activeLanguage === "Español" ?
                    <div className="builder-box-four">

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="button1 button">Únete a nosotras</div>
                        </a>

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="button2 button">Propietario de la comunidad</div>
                        </a>

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="button3 button">Socios</div>
                        </a>
                    </div>
                    :
                    <div className="builder-box-four">

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="button1 button">Join us</div>
                        </a>

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="button2 button">Community Owner</div>
                        </a>

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="button3 button">Partners</div>
                        </a>
                    </div>
            }
        </div>
    )
}

export default ForComunidad