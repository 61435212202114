import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const SecondComponent = () => {

    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const contentArray = activeLanguage === "Español" ? [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Préstamos Agrícolas",
            para: "Préstamos especializados disponibles a través de bancos o programas gubernamentales diseñados para respaldar la compra de equipos agrícolas. Ayudamos a un agricultor a incorporarse como propietario de un tractor."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Reembolsos e incentivos",
            para: "Nuestros socios ofrecen descuentos promocionales e incentivos de hasta el 70% para fomentar la propiedad de tractores por parte de un agricultor. Apoyamos a nuestros agricultores para que obtengan sus incentivos y crezcan en su producción."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Generación potencial de ingresos",
            para: "Alquile el tractor a otros agricultores fuera de temporada a través de la plataforma holatractor, creando un flujo de ingresos adicional."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Inversión en el futuro de la campo",
            para: "Un tractor es un activo valioso que se revaloriza con el tiempo, lo que contribuye al valor total de la campo."
        },
    ]
    :
    [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Agricultural Loans",
            para: "Specialized loans available through banks or government programs designed to support farm equipment purchases.We help for onboard a farmer as tractor owner."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Rebates and Incentives",
            para: "Our partners offering promotional discounts and incentives upto 70% to encourage tractor ownership for a farmer.We support for our farmers get their incentives and grow in their production."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Potential Income Generation",
            para: "Rent out the tractor to other farmers during the off-season through holatractor platform, creating an additional revenue stream."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Investment in Farm's Future",
            para: "A tractor is a valuable asset that appreciates in value over time, contributing to the overall farm equity."
        },
    ]

    return (
        <div className='SecondComponent'>

            <div className="headings">

                <h2>
                    {
                        activeLanguage === "Español" ?
                            "Propiedad del tractor agricultor"
                            :
                            "Tractor Ownership for a farmer"
                    }
                </h2>

                <p>
                    {
                        activeLanguage === "Español" ?
                            "La propiedad de un tractor puede parecer una inversión importante, pero en nuestra plataforma hay numerosas opciones de financiación disponibles para que sea posible."
                            :
                            "Tractor ownership may seem like a significant investment, but numerous financing options are available in our platform to make it achievable."
                    }
                </p>

            </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className='box'
                                        key={index}
                                        onClick={() => { setActiveContnentHeading(index) }}>
                                        <div
                                            className='heading'
                                            style={{
                                                color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                            }}>
                                            <p>{index + 1 > 9 ? index + 1 : `0${index + 1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img
                            src={contentArray[activeContentHeading].img}
                            alt="" />

                        <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                        </div>

                        <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default SecondComponent