import React, { useState } from 'react';
import { Search, User, MapPin, Calendar, Clock } from 'lucide-react';
import './Rentform.scss';

const Rentform = () => {
    const [activeMode, setActiveMode] = useState('Distance');

    const rentalModes = ['Small', 'Medium', 'Large'];

    return (
        <div className="car-rental">
            <div className="container-inside">
                

                <main className="main">
                    <div className="content">
                        <h1>Premium Car Rental Services</h1>
                        <p>
                            Affordable and flexible car rentals for every journey. Choose from a wide range
                            of vehicles for hassle-free travel experiences.
                        </p>

                        <div className="booking-form">
                            <div className="toggle-buttons">
                                {rentalModes.map(mode => (
                                    <button 
                                        key={mode}
                                        className={activeMode === mode ? 'active' : ''}
                                        onClick={() => setActiveMode(mode)}
                                    >
                                        {mode}
                                    </button>
                                ))}
                            </div>

                            <div className="input-group">
                                <div className="input-wrapper">
                                    <MapPin className="input-icon" size={20} />
                                    <input type="text" placeholder="Pickup address" />
                                </div>
                                
                                <div className="input-wrapper">
                                    <MapPin className="input-icon" size={20} />
                                    <input type="text" placeholder="Drop off address" />
                                </div>
                                
                                <div className="input-wrapper">
                                    <Calendar className="input-icon" size={20} />
                                    <input type="date" placeholder="Pickup date" />
                                </div>
                                
                                <div className="input-wrapper">
                                    <Clock className="input-icon" size={20} />
                                    <input type="time" placeholder="Pickup time" />
                                </div>
                            </div>

                            <button className="search-button">
                                Search now
                            </button>
                        </div>
                    </div>
                </main>
                
                <div className="progress-indicator">
                    {['EASILY', 'SEARCH', 'BOOK', 'ENJOY'].map((step, index) => (
                        <React.Fragment key={step}>
                            <span>{step}</span>
                            {index < 3 && <span>•</span>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Rentform;