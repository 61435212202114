import React, { useState } from 'react';
import "./forbuilders.scss";
import BuilderLogo from "../../../../utils/d_hp_ppf_xl.jpg"
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ForBuilders = () => {
    const [activeLink, setActiveLink] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const linkArray = activeLanguage === "Español" ? [
        {
            heading: "dueño del tractor",
            link: "denu-del-tractor",
            rightTopHeading: "oferta del propietario",
            subLinkArray: [
                {
                    heading: "Servicios de tractores",
                    link: "service"
                },
                {
                    heading: "Mecanización agrícola",
                    link: "tractor-assembly-service"
                },
                {
                    heading: "Servicios de seguimiento de tractores",
                    link: "servicios-de-seguimineto"
                },
                {
                    heading: "servicios semanales o mensuales",
                    link: "servicios-mensuales"
                },
            ]
        },
        {
            heading: "Tractor nuevo",
            link: "#",
            rightTopHeading: "",
            subLinkArray: [
                {
                    heading: "Tractores compactos",
                    link: "new-tractor/compact-tractors"
                },
                {
                    heading: "Tractores agrícolas",
                    link: "new-tractor/agricultural-tractors"
                },
                {
                    heading: "Tractores utilitarios",
                    link: "new-tractor/utility-tractors"
                },
                {
                    heading: "Tractores especiales",
                    link: "new-tractor/commercial-tractors"
                },
            ]
        },
        {
            heading: "Reserva de tractores",
            link: "#",
            rightTopHeading: "paga sobre la marcha",
            subLinkArray: [
                {
                    heading: "Reserva y Alquiler",
                    link: "tractor-reservations/reservation-and-rental"
                },
                {
                    heading: "Reserva comunitaria",
                    link: "tractor-reservations/community-booking"
                },
                {
                    heading: "Reserva de granjero",
                    link: "tractor-reservations/farmers-booking"
                },
                {
                    heading: "Reserva comercial",
                    link: "tractor-reservations/commercial-booking"
                },
            ]
        },
        {
            heading: "Tractor comerciales",
            link: "#",
            rightTopHeading: "",
            subLinkArray: [
                {
                    heading: "Incorporación de Saas",
                    link: "#"
                },
                {
                    heading: "Gestión de flotas",
                    link: "#"
                },
                {
                    heading: "arrendar el tractor",
                    link: "#"
                },
                {
                    heading: "Personalizar requisito",
                    link: "#"
                },
            ]
        },
        {
            heading: "Servicios comerciales",
            link: "#",
            rightTopHeading: "",
            subLinkArray: []
        },
        {
            heading: "Finanzas",
            link: "#",
            rightTopHeading: "",
            subLinkArray: [
                {
                    heading: "Préstamo de tractor",
                    link: "#"
                },
                {
                    heading: "Préstamos para equipos",
                    link: "#"
                },
                {
                    heading: "Programa de reembolso",
                    link: "#"
                },
                {
                    heading: "Elegibilidad para préstamos",
                    link: "#"
                },
                {
                    heading: "Calculadora de préstamo",
                    link: "#"
                },
            ]
        },
    ]
    :
    [
        {
            heading: "Tractor owner",
            link: "denu-del-tractor",
            rightTopHeading: "Owner offer",
            subLinkArray: [
                {
                    heading: "Tractor services",
                    link: "service"
                },
                {
                    heading: "Agricultural mechanization",
                    link: "tractor-assembly-service"
                },
                {
                    heading: "Tractor Tracking Services",
                    link: "servicios-de-seguimineto"
                },
                {
                    heading: "Weekly or monthly services",
                    link: "servicios-mensuales"
                },
            ]
        },
        {
            heading: "New tractor",
            link: "#",
            rightTopHeading: "",
            subLinkArray: [
                {
                    heading: "Compact tractors",
                    link: "new-tractor/compact-tractors"
                },
                {
                    heading: "Agricultural tractors",
                    link: "new-tractor/agricultural-tractors"
                },
                {
                    heading: "Utility tractors",
                    link: "new-tractor/utility-tractors"
                },
                {
                    heading: "Special tractors",
                    link: "new-tractor/commercial-tractors"
                },
            ]
        },
        {
            heading: "Tractor Reservation",
            link: "#",
            rightTopHeading: "Pay as you go",
            subLinkArray: [
                {
                    heading: "Reservation and Rental",
                    link: "tractor-reservations/reservation-and-rental"
                },
                {
                    heading: "Community booking",
                    link: "tractor-reservations/community-booking"
                },
                {
                    heading: "Farmer's booking",
                    link: "tractor-reservations/farmers-booking"
                },
                {
                    heading: "Commercial booking",
                    link: "tractor-reservations/commercial-booking"
                },
            ]
        },
        {
            heading: "commercial tractor",
            link: "#",
            rightTopHeading: "",
            subLinkArray: [
                {
                    heading: "SaaS Incorporation",
                    link: "#"
                },
                {
                    heading: "Fleet management",
                    link: "#"
                },
                {
                    heading: "Rent the tractor",
                    link: "#"
                },
                {
                    heading: "Customize requirement",
                    link: "#"
                },
            ]
        },
        {
            heading: "Commercial services",
            link: "#",
            rightTopHeading: "",
            subLinkArray: []
        },
        {
            heading: "Finance",
            link: "#",
            rightTopHeading: "",
            subLinkArray: [
                {
                    heading: "Tractor loan",
                    link: "#"
                },
                {
                    heading: "Equipment loans",
                    link: "#"
                },
                {
                    heading: "Refund program",
                    link: "#"
                },
                {
                    heading: "Loan Eligibility",
                    link: "#"
                },
                {
                    heading: "Loan Calculator",
                    link: "#"
                },
            ]
        },
    ]

    return (
        <div className='forbuilder-container'>
            <div className="builder-box-one">

                {
                    linkArray.map((details, index) => {
                        return (
                            <p
                                key={index}
                                onMouseEnter={() => { setActiveLink(index) }}>
                                <Link
                                    to={`/${details.link}`}
                                    style={{
                                        textDecoration: "none",
                                        color: 'black'
                                    }}>
                                    {details.heading}
                                </Link>
                            </p>
                        )
                    })
                }

                <div className="box-one-bottom">
                    <p>
                        {
                            activeLanguage === "Español" ?
                                "servicios especiales"
                                :
                                "special services"
                        }
                    </p>
                    {/* <p>Contacta con nosotros</p>
                    <span>+591 77613811</span> */}
                </div>
            </div>

            <div className="builder-box-two">
                <p>{linkArray[activeLink].rightTopHeading}</p>
                <div className="box-two-text">
                    {
                        linkArray[activeLink].subLinkArray.map((details, index) => {
                            return (
                                <Link
                                    to={`/${details.link}`}
                                    style={{
                                        textDecoration: "none"
                                    }}>
                                    <p>
                                        {details.heading}
                                    </p>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>

            <div className="builder-box-three">
                <img src={BuilderLogo} alt="Builder Image" />
            </div>

            <div className="builder-box-four">

                <a href="#" target="_blank" rel="noopener noreferrer">
                    <div className="button1 button">
                        {
                            activeLanguage === "Español" ?
                                "Únete como dueño"
                                :
                                "Join as owner"
                        }
                    </div>
                </a>

                <a href="#" target="_blank" rel="noopener noreferrer">
                    <div className="button2 button">
                        {
                            activeLanguage === "Español" ?
                                "Acceso"
                                :
                                "Access"
                        }
                    </div>
                </a>

            </div>

        </div>
    )
}

export default ForBuilders