import React from 'react'
import "./AgriculturalTractors.scss"
import Banner from './Banner'
import FirstComponent from './FirstComponent'
import SecondComponent from './SecondComponent'
import FourthComponent from './FourthComponent'

const AgriculturalTractors = () => {
  return (
    <div className="AgriculturalTractors">

        <Banner />

        <FirstComponent />
        
        <SecondComponent />

        <FourthComponent />

    </div>
  )
}

export default AgriculturalTractors