import React from 'react'

const ThirdComponent = () => {
    const benefitsArray = [
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Easy process: </span> You only have to enter the loan amount, interest rate, processing fee (if available), and tenure. No complicated calculations or complex mathematics. The actual computation will be taken care of by the home loan emi calculator.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Easy process: </span> You only have to enter the loan amount, interest rate, processing fee (if available), and tenure. No complicated calculations or complex mathematics. The actual computation will be taken care of by the home loan emi calculator.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Easy process: </span> You only have to enter the loan amount, interest rate, processing fee (if available), and tenure. No complicated calculations or complex mathematics. The actual computation will be taken care of by the home loan emi calculator.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Easy process: </span> You only have to enter the loan amount, interest rate, processing fee (if available), and tenure. No complicated calculations or complex mathematics. The actual computation will be taken care of by the home loan emi calculator.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Easy process: </span> You only have to enter the loan amount, interest rate, processing fee (if available), and tenure. No complicated calculations or complex mathematics. The actual computation will be taken care of by the home loan emi calculator.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Easy process: </span> You only have to enter the loan amount, interest rate, processing fee (if available), and tenure. No complicated calculations or complex mathematics. The actual computation will be taken care of by the home loan emi calculator.
        </p>,
    ]

    return (
        <div className="ThirdComponent">

            <p className='mainHeading'>
                How to Calculate Home Loan EMI Using Excel Sheet?
            </p>

            <p>
                Calculation of home loan EMI is a simple method using an excel spreadsheet. In order to do so, three variables of a housing loan are utilised, namely, rate of interest, period, and loan amount. It must be noted that the rate of interest should be calculated on a monthly basis.
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span className="subHeading">For example, </span> if the rate of interest is 12%, it should be calculated as 12%/12 = 1%. The period represents the total number of EMI payable.
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                In order to calculate EMI, click on the “=” symbol, type ‘PMT(the rate of interest, period, loan amount)’, and click enter. The excel sheet will the produce an amount in a negative value. This indicates the cash flow that the borrower will have to incur. This is the EMI that a home loan borrower would have to pay.
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span className="subHeading">For example, if your loan amount is 5 lakh for 2 years at 12% p.a.</span> the formula to calculate EMI is as follows.
            </p>

            <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                =PMT(12%/12, 24, 500000)
            </p>

            <p className="mainHeading">
                What are the Benefits of using Home Loan EMI Calculator?
            </p>

            <p>
                There are many benefits of using the home loan EMI calculator tool provided by BankBazaar. They are listed below:
            </p>

            <ul
                style={{ paddingLeft: '20px' }}>

                {
                    benefitsArray.map((details, index) => {
                        return (
                            <li
                                key={index}>
                                {details}
                            </li>
                        )
                    })
                }

            </ul>

        </div>
    )
}

export default ThirdComponent