import React from 'react'

const FifthComponent = () => {
    const rulesArray = [
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Section 80C: </span> You can claim a tax deduction of up to 1.5 lakh every year on the principal amounts paid towards your property loan.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Section 80C: </span> You can claim a tax deduction of up to 1.5 lakh every year on the principal amounts paid towards your property loan.
        </p>,
        <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Section 80C: </span> You can claim a tax deduction of up to 1.5 lakh every year on the principal amounts paid towards your property loan.
        </p>,
    ]

  return (
    <div className='FifthComponent'>

        <p className="mainHeading">
        What are the Tax Benefits of Paying Home Loan EMIs?
        </p>

        <p>
        Taking a loan to buy a house can be expensive, but it also has certain benefits, especially when it comes to taxes. The government offers tax reliefs via the Income Tax Act of , 1961 on the EMIs you pay every year. These are as follows:
        </p>

        <ul
                style={{ paddingLeft: '20px' }}>

                {
                    rulesArray.map((details, index) => {
                        return (
                            <li
                                key={index}>
                                {details}
                            </li>
                        )
                    })
                }

            </ul>

            <p className="subHeading">
            New Tax Regime
            </p>

            <p>
            There were slight changes made to the home loan tax benefits under the new tax regime, which were announced during the Union Budget 2023 by Finance Minister Nirmala Sitharaman
            </p>

    </div>
  )
}

export default FifthComponent