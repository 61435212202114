import React, { useState, useEffect } from 'react'

const FifthComponent = () => {

    const [activeArray, setActiveArray] = useState(0)

    const contentArray = [
        {
            count: "01",
            heading: "Cloud platforms (AWS, Azure, GCP)"
        },
        {
            count: "02",
            heading: "Containers and orchestration tools (Docker, Kubernetes)"
        },
        {
            count: "03",
            heading: "Automation tools (Ansible, Terraform)"
        },
        {
            count: "04",
            heading: "Monitoring and logging tools"
        },
        {
            count: "05",
            heading: "DevOps practices"
        },
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveArray(prev => prev < contentArray.length - 1 ? prev + 1 : 0);
        }, 3000);

        return () => clearInterval(interval);
    }, [activeArray]);

    return (
        <div
            className='FifthComponent'>

            <div
                className='Fifth_container'>

                <div
                    className='container_left'>

                    <h2
                        className='text-xl 768px:text-3xl'>
                        Tools and Technologies
                    </h2>

                    <img
                        alt='Tools_and_technologies_for_data_visualization'
                        src={"https://wallpapercave.com/wp/wp13952499.png"} />

                </div>

                <div
                    className='container_right'>

                    <div
                        className='right_container'>

                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className={` ${index === contentArray.length - 1 ? 'map_container_alt' : 'map_container'}`}>

                                        <div
                                            style={{
                                                minWidth: '56px',
                                                maxWidth: '56px',
                                                minHeight: '56px',
                                                maxHeight: '56px',
                                                borderRadius: '8px',
                                                border: '1px solid #707070',
                                                backgroundColor: '#1b1b1b',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: '1'
                                            }}>

                                            <h4
                                                className={` text-xl 768px:text-2xl`}
                                                style={{
                                                    color: activeArray === index && "#AB0F0C"
                                                }}>
                                                {details.count}
                                            </h4>

                                        </div>

                                        <div>

                                            <h4
                                                className={` text-xl 768px:text-2xl`}
                                                style={{
                                                    color: activeArray === index && "#AB0F0C"
                                                }}>
                                                {details.heading}
                                            </h4>

                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

            </div>

        </div>
    )
}

export default FifthComponent