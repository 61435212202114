import React from 'react'
import { useSelector } from 'react-redux'

const SecondComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const containerArrayDetails = activeLanguage === "Español" ? [
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Siega",
            paragraphs: "Coloque una plataforma de corte para mantener céspedes, pastos y campos impecables."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Labranza y cultivo",
            paragraphs: "Prepare semilleros, cultive jardines y convierta abono con una variedad de accesorios para cultivadores y cultivadores."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Carga y transporte",
            paragraphs: "Mueva materiales, leña o escombros con un accesorio de carga. Muchos tractores compactos también pueden arrastrar remolques para lograr una capacidad de transporte aún mayor."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Remoción de nieve",
            paragraphs: "Despeje entradas de vehículos, senderos y campos con un accesorio quitanieves, lo que garantiza el funcionamiento durante todo el año."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Paisajismo",
            paragraphs: "Nivele caminos de acceso, cree bermas o excave zanjas con una variedad de accesorios de paisajismo."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Trabajo de árbol",
            paragraphs: "Limpie la maleza, retire las ramas o incluso transporte leña con los accesorios adecuados."
        },
    ]
    :
    [
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Mowing",
            paragraphs: "Attach a mower deck to maintain pristine lawns, pastures, and fields."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Tilling & Cultivation",
            paragraphs: "Prepare seedbeds, cultivate gardens, and turn compost with a variety of tiller and cultivator attachments."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Loading & Hauling",
            paragraphs: "Move materials, firewood, or debris with a loader attachment. Many compact tractors can also tow trailers for even greater hauling capacity."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Snow Removal",
            paragraphs: "Clear driveways, walkways, and fields with a snowblower attachment, ensuring year-round operation."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Landscaping",
            paragraphs: "Grade driveways, create berms, or dig trenches with a variety of landscaping attachments."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Tree Work",
            paragraphs: "Clear brush, haul away branches, or even transport firewood with the right attachments."
        },
    ]

    return (
        <div className='SecondComponent'>

            <p className='mainHeading'>
            {
          activeLanguage === "Español" ?
            "¿Qué se puede hacer con un tractor compacto?"
            :
            "What Can You Do with a Compact Tractor?"
        }
            </p>

            <div className='container'>

                {
                    containerArrayDetails.map((details, index) => {
                        return (
                            <div
                                className='box'
                                key={index}>
                                <div className="boxTop">
                                    <video
                                        src={details.videoLink}
                                        autoPlay
                                        loop
                                        controls={false}
                                        width="120px" // Adjust width as needed
                                        height="auto" // Adjust height as needed
                                    />
                                    <div className='boxTopRight'>
                                        <p>
                                            {details.mainHeading}
                                        </p>
                                    </div>
                                </div>
                                <div className="boxBottom">
                                    <p>
                                        {details.paragraphs}
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default SecondComponent