import React from 'react'
import { useSelector } from 'react-redux'

const FourthComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='FourthComponent'>

            <div className="container">

                {/* Heading */}
                <h1>
                    {
                        activeLanguage === "Español" ?
                            "Beneficios para nuestra comunidad"
                            :
                            "Benefits for our community"
                    }
                </h1>

                <div className='box'>

                    <div className="box-container">

                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/farmer_benefit1.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_2.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_3.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_4.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_5.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_6.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_7.webp" alt="temp" />

                    </div>

                </div>

                <div className='paragraphs-container'>
                    <p>
                        {
                            activeLanguage === "Español" ?
                                "Holatractor se dedica a brindar a nuestra comunidad los tractores y el conocimiento necesarios para prosperar en un clima cambiante. Al adoptar los avances tecnológicos y fomentar prácticas sostenibles, podemos crear un futuro mejor para la agricultura y nuestro planeta."
                                :
                                "Holatractor is dedicated to providing our community with the tractors and knowledge needed to thrive in a changing climate. By embracing technological advances and encouraging sustainable practices, we can create a better future for agriculture and our planet."
                        }
                    </p>
                    <p>
                        {
                            activeLanguage === "Español" ?
                                "No se trata sólo de conectar a los agricultores con los tractores. Nos apasiona potenciar un futuro sostenible para la agricultura. Creemos que la tecnología y la innovación desempeñan un papel crucial para abordar el cambio climático y promover prácticas agrícolas climáticamente inteligentes."
                                :
                                "It's not just about connecting farmers to tractors. We are passionate about promoting a sustainable future for agriculture. We believe that technology and innovation play a crucial role in addressing climate change and promoting climate-smart agricultural practices."
                        }
                    </p>
                </div>

            </div>

        </div>
    )
}

export default FourthComponent