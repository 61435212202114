import React from 'react'
import { useSelector } from 'react-redux'

const Banner = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='BannerComponent'>
            <div className='leftContainer'>
                <div className='shadow' />
                {
                    activeLanguage === "Español" ?
                        <div className='textContainer'>
                            <p className='mainHeading'>
                            Nuestro innovador servicio IoT
                            </p>
                            <p className='paragraphOne'>
                            Ofrecemos un servicio de seguimiento de tractores de última generación impulsado por tecnología confiable de IoT (Internet de las cosas). Al instalar un dispositivo IoT pequeño y fácil de usar en su tractor, obtiene acceso a una gran cantidad de información y funciones, todas ellas cómodamente accesibles a través de nuestra aplicación móvil fácil de usar o nuestra plataforma en línea.
                            </p>
                        </div>
                        :
                        <div className='textContainer'>
                            <p className='mainHeading'>
                            Our Innovative IOT Service
                            </p>
                            <p className='paragraphOne'>
                            We offer a state-of-the-art tractor tracking service powered by reliable IoT (Internet of Things) technology. By installing a small, easy-to-use IoT device on your tractor, you gain access to a wealth of information and features, all conveniently accessible through our user-friendly mobile app or online platform.
                            </p>
                        </div>
                }
            </div>
            <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractor_tech_6.webp" alt="banner image" className='rightContainer' />
        </div>
    )
}

export default Banner