import React, { useEffect, useState } from 'react'
import './TeamComponent.scss'
import AddIcon from '@mui/icons-material/Add';
import PopUp from './PopUp';

const TeamComponent = () => {
  const [activeState, setActiveState] = useState(0)

  const teamArray = [
    {
      bgImageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/red2.webp",
      profileImage: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/pedro.jpeg",
      name: "Pedro Viera(CEO)",
      videoLink: "https://pakhiweb.s3.us-west-2.amazonaws.com/ss/home/homebanner.mp4"
    },
    {
      bgImageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/red1.webp",
      profileImage: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/maria.jpeg",
      name: "Maria Rene(CFO)",
      videoLink: "https://pakhiweb.s3.us-west-2.amazonaws.com/ss/home/homebanner.mp4"
    },
    {
      bgImageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/red3.webp",
      profileImage: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/nim.jpeg",
      name: "Nimain Giri(CTO)",
      videoLink: "https://pakhiweb.s3.us-west-2.amazonaws.com/ss/home/homebanner.mp4"
    },
    // {
    //   bgImageLink: "https://wallpapercave.com/uwp/uwp4341460.jpeg",
    //   profileImage: "https://wallpapercave.com/wp/wp13798748.jpg",
    //   name: "John Doe",
    //   videoLink: "https://pakhiweb.s3.us-west-2.amazonaws.com/ss/home/homebanner.mp4"
    // },
  ]

  useEffect(() => {
    const interval = setInterval(() => {
        setActiveState(prev => prev < teamArray.length - 1 ? prev + 1 : 0);
    }, 3000);

    return () => clearInterval(interval);
}, [activeState]);

  return (
    <div className='TeamComponent'>

      <h1>
      Nuestro equipo central
      </h1>

      <div className="container">

        <img src={teamArray[activeState].bgImageLink} alt="" />

          <PopUp 
          active={false} 
          imageLink={activeState === 0 ? teamArray[teamArray.length - 1].profileImage : teamArray[activeState - 1].profileImage}
          heading={activeState === 0 ? teamArray[teamArray.length - 1].name : teamArray[activeState - 1].name}
          videoLink={activeState === 0 ? teamArray[teamArray.length - 1].videoLink : teamArray[activeState - 1].videoLink} />

          <PopUp 
          active={true} 
          imageLink={teamArray[activeState].profileImage}
          heading={teamArray[activeState].name}
          videoLink={teamArray[activeState].videoLink} />

        <PopUp 
          active={false} 
          imageLink={activeState === teamArray.length - 1 ? teamArray[0].profileImage : teamArray[activeState + 1].profileImage}
          heading={activeState === teamArray.length - 1 ? teamArray[0].name : teamArray[activeState + 1].name}
          videoLink={activeState === teamArray.length - 1 ? teamArray[0].videoLink : teamArray[activeState + 1].videoLink} />

      </div>

    </div>
  )
}

export default TeamComponent