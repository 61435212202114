import React from 'react'
import './LoanCalculator.scss'
import SecondComponent from './SecondComponent'
import ThirdComponent from './ThirdComponent'
import FourthComponent from './FourthComponent'
import FifthComponent from './FifthComponent'
import FirstComponent from './FirstComponent'

const LoanCalculator = () => {
  return (
    <div
      className='LoanCalculator'>

      <p
        className='mainHeading'>
        Home Loan EMI Calculator
      </p>

      <div className="container">

        <p>
          BankBazaar’s Home Loan Equated Monthly Instalment (EMI) online calculator is a free financial tool that helps home loan borrowers plan and manage their loan repayments. The only data you need to input into the calculator are your desired loan amount, home loan interest rates, and tenure. The calculator will then display the precise amount that will have to be paid every month as EMI, thus helping you plan your monthly budget for months at a time.
        </p>

        <p>
          You can use the calculator any number of times for free. This especially comes in handy any time there is an update in the interest rate and you would like to recalculate your EMI.
        </p>

        <p>
          Once you hit the ‘’Calculate’’ button, the data displayed on screen includes not just the EMI with a breakup of the principal amount and interest amount, but also an amortization table and bar graph with details of the EMI across the entire loan tenure. All this data will be calculated and displayed within seconds saving you valuable time, thus making BankBazaar’s Housing loan EMI calculator a helpful financial resource for all home loan borrowers
        </p>

      </div>

      <p>
        You can use the calculator any number of times for free. This especially comes in handy any time there is an update in the interest rate and you would like to recalculate your EMI.
      </p>

      <p>
        Once you hit the ‘’Calculate’’ button, the data displayed on screen includes not just the EMI with a breakup of the principal amount and interest amount, but also an amortization table and bar graph with details of the EMI across the entire loan tenure. All this data will be calculated and displayed within seconds saving you valuable time, thus making BankBazaar’s Housing loan EMI calculator a helpful financial resource for all home loan borrowers.
      </p>

      <FirstComponent />

      <SecondComponent />

      <ThirdComponent />

      <FourthComponent />

      <FifthComponent />

    </div>
  )
}

export default LoanCalculator