import React, { useEffect } from "react";
import "./app.scss";
import HomePage from "./components/Home/HomePage";
import Login from "./components/Login/Login";
import HousingEdge from "./components/HousingEdge/HousingEdge";
import FeatureCollection from "./components/FeatureCollection/FeatureCollection";
import AppDownload from "./components/AppDownload/AppDownload";
import PostProperty from "./components/PostProperty/PostProperty";
import Footer from "./components/Footer/Footer";
import Creativity from "./components/CreativitySeaction/Creativity";
import ShowProperties from "./Pages/ShowProperties/ShowProperties";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Navbar from "./components/Home/Navbar/Navbar";
import SearchBar from "./components/Home/SearchBar/SearchBar";
import SingleProperties from "./Pages/ShowProperties/SingleProperties/SingleProperties";
import Error from "./Pages/Error/Error"
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Rentform from "./Pages/Rentform/Rentform";

import Farmer from "./Pages/Menu/Farmer/Farmer";
import Impacto from "./Pages/Menu/Impacto/Impacto";
import Service from "./Pages/Menu/Service/Service";
import ServicesTractores from "./Pages/Menu/ServicesTractores/ServicesTractores";
import ServiciosMensuales from "./Pages/Menu/ServiciosMensuales/ServiciosMensuales";
import TermsAndCondition from "./Pages/TermsAndCondition/TermsAndCondition";
import ServiciosDeSeguimineto from "./Pages/Menu/ServiciosDeSeguimineto/ServiciosDeSeguimineto";
import { useCookies } from 'react-cookie'
import { useDispatch } from "react-redux";
import { updateCookieComponentShowToFalse, updateCookieComponentShowToTrue } from "./redux/cookieComponentShow";
import DenuDelTractor from "./Pages/Menu/DenuDelTractor/DenuDelTractor";
import PostRequirementOfTtractor from "./Pages/SubMenus/Comunidad/PostRequirementOfTractor/PostRequirementOfTtractor";
import BeneficioTecnológicoParaLaAgricultura from "./Pages/SubMenus/Comunidad/BeneficioTecnológicoParaLaAgricultura/BeneficioTecnológicoParaLaAgricultura";
import ReunionesComunitrates from "./Pages/SubMenus/Comunidad/ReunionesComunitrates/ReunionesComunitrates";
import ConsejosDeCultivo from "./Pages/SubMenus/Comunidad/ConsejosDeCultivo/ConsejosDeCultivo";
import ServiciosDeSoporteDeAgentes from "./Pages/SubMenus/Agente/ServiciosDeSoporteDeAgentes/ServiciosDeSoporteDeAgentes";
import ReparacionDeTractores from "./Pages/SubMenus/Agente/ReparacionDeTracores/ReparacionDeTractores";
import MesaDeAyuda from "./Pages/SubMenus/Agente/MesaDeAyuda/MesaDeAyuda";
import ServisiosDeReparacionDeEscalones from "./Pages/SubMenus/Agente/ServiciosDeReparacionDeEscalones/ServisiosDeReparacionDeEscalones";
import VehicleInformation from "./components/Home/VehicleInformation/VehicleInformation";
import RegenerativeAgriculture from "./palmPages/RegenerativeAgriculture/RegenerativeAgriculture";

import PalmFooter from "./palmComponents/Footer/Footer"
import PalmNavbar from "./palmComponents/Navbar/Navbar"
import PalmHomePage from "./palmPages/HomePage/HomePage"
import PalmCommunity from './palmPages/Community/Community'
import PalmPricing from './palmPages/Pricing/Pricing'
import PalmAcai from './palmPages/Acai/Acai'
import PalmPalmRecollection from './palmPages/PalmRecollection/PalmRecollection'
import MiniTractor from "./components/Home/MiniTractor/MiniTractor";
import NewComponent from "./components/Home/NewComponent/NewComponent";
import MiniTractorPage from "./Pages/MiniTractorPage/MiniTractorPage";
import LoanCalculator from "./Pages/LoanCalculator/LoanCalculator";
import ApplyLoan from "./Pages/ApplyLoan/ApplyLoan";
import LoanApplication from "./Pages/LoanApplication/LoanApplication";
import Partner from "./Pages/Partner/Partner";
import TeamComponent from "./components/Home/TeamComponent/TeamComponent";
import CompactTractors from "./Pages/SubMenus/Builders/NewTractors/CompactTractors/CompactTractors";
import AgriculturalTractors from "./Pages/SubMenus/Builders/NewTractors/AgriculturalTractors/AgriculturalTractors";
import UtilityTractors from "./Pages/SubMenus/Builders/NewTractors/UtilityTractors/UtilityTractors";
import CommercialTractors from "./Pages/SubMenus/Builders/NewTractors/CommercialTractors/CommercialTractors";
import ReservationAndRental from "./Pages/SubMenus/Builders/TractorReservation/ReservationAndRental/ReservationAndRental";
import CommunityBooking from "./Pages/SubMenus/Builders/TractorReservation/CommunityBooking/CommunityBooking";
import FarmersBooking from "./Pages/SubMenus/Builders/TractorReservation/FarmersBooking/FarmersBooking";
import CommercialBooking from "./Pages/SubMenus/Builders/TractorReservation/CommercialBooking/CommercialBooking"
import Register from "./Pages/Register/Register"
import LogInPage from "./Pages/Register/LogInPage"
import Booking from "./Pages/booking/booking";

import { GoogleOAuthProvider } from '@react-oauth/google';


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Vision from "./Pages/Vision/Vision";
import Agenda from "./Pages/Agenda/Agenda";
import FarmerPrivacyPolicy from "./Pages/PrivacyPolicy/FarmerPrivacyPolicy";

const App = () => {

  const [cookies, setCookies] = useCookies(['cookieAccept'])
  const dispatch = useDispatch()

  useEffect(() => {
    if (cookies.cookieAccept === 'accepted') {
      dispatch(updateCookieComponentShowToFalse())
    } else {
      dispatch(updateCookieComponentShowToTrue())
    }
  }, [])

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div
        style={{paddingTop: '12vh'}}>
          <HomePage />
          <HousingEdge />
          <FeatureCollection />
          <VehicleInformation />
          <NewComponent />
          <PostProperty />
          <MiniTractor />
          <Creativity />
          <TeamComponent />
          <AppDownload />
          <Footer />
        </div>
      ),
    },
    // {
    //   path: "/login",
    //   element: (<Login />)
    // },
    {
      path: "/search",
      element: <div 
        style={{paddingTop: '12vh'}}>
        <Navbar />
        <SearchBar />
        <ShowProperties />
        <Footer />
      </div>
    },
    {
      path: "/search/:id",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <SingleProperties />
        <Footer />
      </div>
    },
    {
      path: "/privacy-policy",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <PrivacyPolicy />
        <Footer />
      </div>
    },
    {
      path: "/privacy-policy-farmer",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <FarmerPrivacyPolicy />
        <Footer />
      </div>
    },
    {
      path: "/rent-form",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <Rentform />
        <Footer />
      </div>
    },
    {
      path: "/vision",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <Vision/>
        <Footer />
      </div>
    },
    {
      path: "/farmer",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <Farmer />
        <Footer />
      </div>
    },
    {
      path: "/service",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <Service />
        <Footer />
      </div>
    },
    {
      path: "/impact",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <Impacto />
        <Footer />
      </div>
    },
    {
      path: "/tractor-assembly-service",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <ServicesTractores />
        <Footer />
      </div>
    },
    {
      path: "/denu-del-tractor",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <DenuDelTractor />
        <Footer />
      </div>
    },
    {
      path: "/servicios-mensuales",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <SearchBar />
        <ServiciosMensuales />
        <Footer />
      </div>
    },
    {
      path: "/servicios-de-seguimineto",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <ServiciosDeSeguimineto />
        <Footer />
      </div>
    },
    {
      path: "/mini-tractor",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <MiniTractorPage />
        <Footer />
      </div>
    },
    {
      path: "/Calculadora_de_préstamo",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <LoanCalculator />
        <Footer />
      </div>
    },
    {
      path: "/ApplyLoan",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <ApplyLoan />
        <Footer />
      </div>
    },
    {
      path: "/LoanApplication",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <LoanApplication />
        <Footer />
      </div>
    },
    {
      path: "/Partner",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <Partner />
        <Footer />
      </div>
    },
    {
      path: '/Agenda',
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <Agenda/>
        <Footer />
      </div>
    },
    {
      path: "/comunidad",
      children: [
        {
          path: 'post-requirement-of-tractor',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <PostRequirementOfTtractor />
            <Footer />
          </div>
        },
        {
          path: 'beneficio-tecnológico-para-la-agricultura',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <BeneficioTecnológicoParaLaAgricultura />
            <Footer />
          </div>
        },
        {
          path: 'reuniones-communitrates',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <ReunionesComunitrates />
            <Footer />
          </div>
        },
        {
          path: 'consejos-de-cultivo',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <ConsejosDeCultivo />
            <Footer />
          </div>
        },
      ]
    },
    {
      path: "/agente",
      children: [
        {
          path: 'servicios-de-soporte-de-agentes',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <ServiciosDeSoporteDeAgentes />
            <Footer />
          </div>
        },
        {
          path: 'reparación-de-tractores',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <ReparacionDeTractores />
            <Footer />
          </div>
        },
        {
          path: 'mesa-de-ayuda',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <MesaDeAyuda />
            <Footer />
          </div>
        },
        {
          path: 'servicios-de-reparacion-de-escalones',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <ServisiosDeReparacionDeEscalones />
            <Footer />
          </div>
        },
      ]
    },
    {
      path: "/new-tractor",
      children: [
        {
          path: 'compact-tractors',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <CompactTractors />
            <Footer />
          </div>
        },
        {
          path: 'agricultural-tractors',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <AgriculturalTractors />
            <Footer />
          </div>
        },
        {
          path: 'utility-tractors',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <UtilityTractors />
            <Footer />
          </div>
        },
        {
          path: 'commercial-tractors',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <CommercialTractors />
            <Footer />
          </div>
        },
      ]
    },
    {
      path: "/tractor-reservations",
      children: [
        {
          path: 'reservation-and-rental',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <ReservationAndRental />
            <Footer />
          </div>
        },
        {
          path: 'community-booking',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <CommunityBooking />
            <Footer />
          </div>
        },
        {
          path: 'farmers-booking',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <FarmersBooking />
            <Footer />
          </div>
        },
        {
          path: 'commercial-booking',
          element: <div
          style={{paddingTop: '12vh'}}>
            <Navbar />
            <CommercialBooking />
            <Footer />
          </div>
        },
       
      ]
    },
    {
      path: "/register",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <Register />
        {/* <Footer /> */}
      </div>
    },
    {
      path: "/login",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <LogInPage />
        {/* <Footer /> */}
      </div>
    },
    {
      path: "/regenerative-agriculture",
      element: <>
        <div className="w-full relative">
          <div className="absolute h-full w-[1px] z-50 bg-yellow-200 left-[10%] top-0 hidden 900px:block" />
          <div className="absolute h-full w-[1px] z-50 bg-yellow-200 left-[50%] top-0 hidden 900px:block" />
          <div className="absolute h-full w-[1px] z-50 bg-yellow-200 right-[10%] top-0 hidden 900px:block" />
          <PalmNavbar />
          <PalmHomePage />
          <PalmFooter />
        </div>
      </>
    },
    {
      path: "/regenerative-agriculture/community",
      element: <>
        <PalmCommunity />
      </>
    },
    {
      path: "/regenerative-agriculture/acai",
      element: <div className='relative'>
        <div className="absolute h-full w-[1px] z-50 bg-yellow-200 left-[10%] top-0 hidden 900px:block" />
        <div className="absolute h-full w-[1px] z-50 bg-yellow-200 left-[50%] top-0 hidden 900px:block" />
        <div className="absolute h-full w-[1px] z-50 bg-yellow-200 right-[10%] top-0 hidden 900px:block" />
        <PalmAcai />
      </div>
    },
    {
      path: "/regenerative-agriculture/palm-recollection",
      element: <div className='relative'>
        <div className="absolute h-full w-[1px] z-50 bg-yellow-200 left-[10%] top-0 hidden 900px:block" />
        <div className="absolute h-full w-[1px] z-50 bg-yellow-200 left-[50%] top-0 hidden 900px:block" />
        <div className="absolute h-full w-[1px] z-50 bg-yellow-200 right-[10%] top-0 hidden 900px:block" />
        <PalmPalmRecollection />
      </div>
    },
    {
      path: "/regenerative-agriculture/pricing",
      element: <PalmPricing />
    },
    {
      path: "/terms-and-condition",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <TermsAndCondition />
        <Footer />
      </div>
    },
    {
      path: "/booking",
      element: <div
      style={{paddingTop: '12vh', backgroundColor: "#AB0F0C"}}>
        <Navbar />
        <Booking />
        <Footer />
      </div>
    },
    {
      path: "/regenerative-agriculture/regenerative-agriculture",
      element: <>
        <RegenerativeAgriculture />
      </>
    },
    {
      path: "*",
      element: <div
      style={{paddingTop: '12vh'}}>
        <Navbar />
        <Error />
        <Footer />
      </div>
    }
  ]);

  return (
    <GoogleOAuthProvider clientId="316072302761-qn635is55ki0ons7rk7rshbtlhb1ovkn.apps.googleusercontent.com">
        <ToastContainer />
      <RouterProvider router={router} />
      </GoogleOAuthProvider>
  );
};

export default App;
