import React from 'react'
import './Service.scss'
import serviceBackgroundVideo from '../../../assets/video/service.mp4'
import MiddleBtn from '../../../components/Home/HomeMiddle/MiddleButton/MiddleBtn'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useState } from 'react';
import ServiceFooter from './ServiceFooter';
import SeventhComponent from './SeventhComponent'
import FifthComponent from './FifthComponent'
import { useSelector } from 'react-redux';

const Service = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='Service'>

            <div className="Container">

                <div className="video-section">

                    <video autoPlay loop muted>
                        <source src={serviceBackgroundVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <p>
                        {
                            activeLanguage === "Español" ?
                                "Holatractor en Servicios de Tractores y Agricultura Generativa"
                                :
                                "Holatractor in Tractor Services and Generative Agriculture"
                        }
                    </p>

                    <div className='button'>
                        <MiddleBtn />
                    </div>

                </div>

                <ServiceFooter />

                <SeventhComponent />

                <FifthComponent />

            </div>

        </div>
    )
}

export default Service