import React from 'react';
import { ArrowDownRight } from 'lucide-react';
import styles from './designer-banner.module.css';

export default function PartnerSection() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.banner}>
          <div className={styles.content}>
            <h2 className={styles.title}>
              Get a reliable partner that provides solutions to design challenges
            </h2>
            <div className={styles.buttonGroup}>
                    <div className={styles.iconButton}>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 7H17V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7 17L17 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <a href="#hire" className={styles.ctaButton}>
                        Hire a designer
                    </a>
                </div>
          </div>
          <div className={styles.imageWrapper}>
            <img
              src="https://cdn.prod.website-files.com/65647bbe0d57c8abad78e939/65783a39c19885912b02d68f_partner-banner.webp"
              alt="Design partnership illustration"
              className={styles.image}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

