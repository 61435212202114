import React from 'react';
import "./forbuilders.scss";
import BuilderLogo from "../../../../utils/d_hp_ppf_xl.jpg"
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ForAgent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='forbuilder-container'>

            {
                activeLanguage === "Español" ?
                    <div className="builder-box-one">

                        <p>Cómo unirse con nosotros como agente?</p>

                        <p>¿Cómo unirse como operador?</p>

                        <p>¿Cómo crecemos jóvenes emprendedores?</p>

                        <p>¿Cómo ganar con nosotros?</p>

                    </div>
                    :
                    <div className="builder-box-one">

                        <p>How to join us as an agent?</p>

                        <p>How to join as an operator?</p>

                        <p>How do we grow young entrepreneurs?</p>

                        <p>How to earn with us?</p>

                    </div>
            }

            {
                activeLanguage === "Español" ?
                    <div className="builder-box-two">
                        <p>ofertas</p>
                        <div className="box-two-text">
                            <Link to={'/agente/servicios-de-soporte-de-agentes'} style={{ textDecoration: 'none' }}>
                                <p>
                                    Servicios de soporte de agentes
                                </p>
                            </Link>
                            <Link to={'/agente/reparación-de-tractores'} style={{ textDecoration: 'none' }}>
                                <p>
                                    Reparación de tractores
                                </p>
                            </Link>
                            <Link to={'/agente/mesa-de-ayuda'} style={{ textDecoration: 'none' }}>
                                <p>
                                    Mesa de ayuda
                                </p>
                            </Link>
                            <Link to={'/agente/servicios-de-reparacion-de-escalones'} style={{ textDecoration: 'none' }}>
                                <p>
                                    Servicios de reparación de escalones
                                </p>
                            </Link>
                        </div>
                    </div>
                    :
                    <div className="builder-box-two">
                        <p>offers</p>
                        <div className="box-two-text">
                            <Link to={'/agente/servicios-de-soporte-de-agentes'} style={{ textDecoration: 'none' }}>
                                <p>
                                    Agent Support Services
                                </p>
                            </Link>
                            <Link to={'/agente/reparación-de-tractores'} style={{ textDecoration: 'none' }}>
                                <p>
                                    Tractor repair
                                </p>
                            </Link>
                            <Link to={'/agente/mesa-de-ayuda'} style={{ textDecoration: 'none' }}>
                                <p>
                                    Help Desk
                                </p>
                            </Link>
                            <Link to={'/agente/servicios-de-reparacion-de-escalones'} style={{ textDecoration: 'none' }}>
                                <p>
                                    Step Repair Services
                                </p>
                            </Link>
                        </div>
                    </div>
            }
            <div className="builder-box-three">
                <img
                    src={"https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/women_tractor.webp"}
                    alt="Builder Image" />
            </div>

            {
                activeLanguage === "Español" ?
                    <div className="builder-box-four">

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="button1 button">Agente</div>
                        </a>

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="button2 button">Operador</div>
                        </a>
                    </div>
                    :
                    <div className="builder-box-four">

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="button1 button">Agent</div>
                        </a>

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <div className="button2 button">Operator</div>
                        </a>
                    </div>
            }
        </div>
    )
}

export default ForAgent