import React from 'react'

const FifthComponent = () => {

    const firstGridArray = [
        {
            heading: "Maximum Loan Amount",
            description: "Rs: 2,00,000 /-"
        },
        {
            heading: "Maximum Loan Amount",
            description: "Rs: 2,00,000 /-"
        },
        {
            heading: "Maximum Loan Amount",
            description: "Rs: 2,00,000 /-"
        },
        {
            heading: "Maximum Loan Amount",
            description: "Rs: 2,00,000 /-"
        },
        {
            heading: "Maximum Loan Amount",
            description: "Rs: 2,00,000 /-"
        },
        {
            heading: "Maximum Loan Amount",
            description: "Rs: 2,00,000 /-"
        },
    ]

    const secondGridArray = [
        {
            heading: "Maximum Loan Amount",
            description: "Rs: 2,00,000 /-"
        },
        {
            heading: "Maximum Loan Amount",
            description: "Rs: 2,00,000 /-"
        },
    ]

  return (
    <div className='FifthComponent'>

        <h1>
            Rates & Charges
        </h1>

        <hr />

        <div className="firstGridArray">

            {
                firstGridArray.map((details, index)=>{
                    return (
                        <div className='firstGridArrayBox' key={index}>

                            <h2>
                                {details.heading}
                            </h2>

                            <p>
                                {details.description}
                            </p>

                        </div>
                    )
                })
            }

        </div>

        <div className="secondGridArray">

            {
                secondGridArray.map((details, index)=>{
                    return (
                        <div className='firstGridArrayBox' key={index}>

                            <h2>
                                {details.heading}
                            </h2>

                            <p>
                                {details.description}
                            </p>

                        </div>
                    )
                })
            }

        </div>

    </div>
  )
}

export default FifthComponent