import { useState } from 'react'
import { useSelector } from 'react-redux'
import { errorMessage, successMessage } from '../../components/Toastify/message'
import { NestjsBookingInstance } from "../../components/Axios/NestjsBookingInstance"
import { Backdrop, CircularProgress } from '@mui/material'

const ConnectSection = () => {
  const [loading, setLoading] = useState(false)

  const [first_name, set_first_name] = useState("")
  const [last_name, set_last_name] = useState("")
  const [email, set_email] = useState("")
  const [phone_no, set_phone_no] = useState("")
  const [budget, set_budget] = useState("")
  const [tractror_type, set_tractror_type] = useState("")
  const [message, set_message] = useState("")

  const { activeLanguage } = useSelector(state => state.Languages)

  function handleInquiry() {
    if (!first_name || !last_name) {
      errorMessage("Please give complete name")
      return
    }
    if (!email || !phone_no) {
      errorMessage("Please give contact details")
      return
    }
    if (!budget) {
      errorMessage("Select your budget")
      return
    }
    if (!tractror_type) {
      errorMessage("Select the tractor type")
      return
    }
    if (!message) {
      errorMessage("Give your message")
      return
    }

    setLoading(true)
    NestjsBookingInstance.post("/inquiry", {
      first_name:first_name,
      last_name:last_name,
    email:email,
    phone_number: phone_no,
    budget:budget,
    tractor_type: tractror_type,
    message: message
    })
      .then(() => {
        successMessage("Form submitted")
        set_first_name("");
        set_last_name("");
        set_email("");
        set_phone_no("");
        set_budget("");
        set_tractror_type("")
        set_message("")
      }).catch((err) => { 
        errorMessage("Some error occurred try after sometimes") 
        // console.log(err)
      })
      .finally(() => { setLoading(false) })
  }

  return (
    <div className='connect'>

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {
        activeLanguage === "Español" ?
          <h1>
            Solicitud de reserva de tractor
          </h1>
          :
          <h1>
            Tractor reservation request
          </h1>
      }

      <hr />

      {
        activeLanguage === "Español" ?
          <h3>
            Tu campo, tus necesidades, nuestros tractores
          </h3>
          :
          <h3>
            Your field, your needs, our tractors
          </h3>
      }

      <div className="inputs">
        <input
          className="name"
          id="first_name"
          name="first_name"
          placeholder={activeLanguage === "Español" ? "Nombre de pila" : "First name"}
          value={first_name}
          onChange={e => { set_first_name(e.target.value) }}
          type='text' />
        <input
          className="name"
          id="last_name"
          name="last_name"
          placeholder={activeLanguage === "Español" ? "Apellido" : "Last name"}
          value={last_name}
          onChange={e => { set_last_name(e.target.value) }}
          type='text' />
      </div>

      <div className="inputs">
        <input
          className="name"
          id="email"
          name="email"
          placeholder={activeLanguage === "Español" ? 'Correo electrónico' : "Email"}
          value={email}
          onChange={e => { set_email(e.target.value) }}
          type='email' />
        <input
          className="name"
          id="phone_number"
          name="phone_number"
          placeholder={activeLanguage === "Español" ? 'Número de teléfono' : "Phone number"}
          value={phone_no}
          onChange={e => { set_phone_no(e.target.value) }}
          type='text' />
      </div>

      <div className="inputs">
        <select name="tractor_type" id="tractor_type" value={tractror_type} onChange={e => { set_tractror_type(e.target.value) }}>
          <option defaultChecked>{activeLanguage === "Español" ? "Tipo de tractor" : "Tractor type"}</option>
          <option value={"small"}>{activeLanguage === "Español" ? "Pequeño" : "Small"}</option>
          <option value={"medium"}>{activeLanguage === "Español" ? "Mediano" : "Medium"}</option>
          <option value={"large"}>{activeLanguage === "Español" ? "Grande" : "Large"}</option>
        </select>
        <select name="tractor_type" id="tractor_type" value={budget} onChange={e => { set_budget(e.target.value) }}>
          <option defaultChecked>{activeLanguage === "Español" ? "Presupuesto" : "Budget"}</option>
          <option value={"0-500"}>0$ -500$</option>
          <option value={"500-1000"}>500$ - 1000$</option>
          <option value={"1000-1500"}>1000$ - 1500$</option>
          <option value={"1500-2000"}>1500$ - 2000$</option>
          <option value={"2000-2500"}>2000$ - 2500$</option>
          <option value={"2500-3500"}>2500$ - 3000$</option>
        </select>
      </div>

      <textarea
        id="message"
        name="message"
        className="message"
        placeholder={activeLanguage === "Español" ? 'Cuéntanos más sobre' : "Tell us more about"}
        value={message}
        onChange={e => { set_message(e.target.value) }} />

      <button
        name='send now button'
        onClick={() => { handleInquiry() }}>
        {activeLanguage === "Español" ? "Enviar" : "Send"}
      </button>

    </div>
  )
}

export default ConnectSection