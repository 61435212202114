import React from 'react'
import { useSelector } from 'react-redux'

const SeventhComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            heading: "Labranza y preparación del suelo",
            description: "arado, disco, rastrillado y subsolado para una preparación óptima del lecho de siembra.",
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/site.png"
        },
        {
            heading: "Plantación y siembra",
            description: "Plantación y siembra precisas de diversos cultivos, lo que garantiza una germinación y un crecimiento eficientes.",
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/planting.png"
        },
        {
            heading: "Cuidado de cultivos",
            description: "cultivar, fumigar, regar y cosechar para maximizar el rendimiento de los cultivos.",
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/greenhouse.png"
        },
        {
            heading: "Construcción",
            description: "Despeje de terrenos para nuevos campos o proyectos de construcción.",
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/architecture+(1).png"
        },
        {
            heading: "Transporte",
            description: "Transporte eficiente de cultivos, equipos y suministros.",
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/tractor.png"
        },


        {
            heading: "Aplicación de fertilizantes",
            description: "Mejora la fertilidad del suelo y promueve el crecimiento de las plantas.",
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/seed-bag.png"
        },



    ]
        :
        [
            {
                heading: "Tillage and Soil Preparation",
                description: "Plowing, discing, harrowing, and subsoiling for optimal seedbed preparation.",
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/site.png"
            },
            {
                heading: "Planting and Seeding",
                description: "Precise planting and seeding of various crops, ensuring efficient germination and growth.",
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/planting.png"
            },
            {
                heading: "Crop Care",
                description: "Cultivating, spraying, irrigating, and harvesting to maximize crop yields.",
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/greenhouse.png"
            },
            {
                heading: "Land Clearing and Construction",
                description: "Clearing land for new fields or construction projects.",
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/architecture+(1).png"
            },

            {
                heading: "Transportation",
                description: "Efficiently transporting crops, equipment, and supplies.",
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/tractor.png"
            },

            {
                heading: "Fertilizer Application",
                description: "Enhance soil fertility and promote plant growth.",
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/seed-bag.png"
            },
        ]

    return (
        <div
            className='SeventhComponent'>

            <div
                className='seventh_container'
                style={{
                    // background: 'radial-gradient(50% 50% at 50% 50%, rgba(158, 110, 230, 0.28) 0%, rgba(158, 110, 230, 0) 100%)'
                }}>

                <h1
                    className='text-3xl 1000px:text-5xl'>
                    {
                        activeLanguage === "Español" ?
                            "Nuestros Servicios"
                            :
                            "Our Services"
                    }
                </h1>

                <div
                    className='grid-cols-1 800px:grid-cols-2 data_container'>

                    {
                        componentArray.map((details, index) => {
                            return (
                                <div
                                    key={index}
                                    className='data_box'>

                                    <img
                                        alt={details.heading}
                                        src={details.imageLink}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            objectFit: 'cover'
                                        }} />

                                    <div
                                        className='text-lg 1000px:text-2xl data_text_container'>

                                        <p>
                                            <b>
                                                {details.heading}
                                            </b>
                                        </p>

                                        <p>
                                            {details.description}
                                        </p>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </div>

        </div>
    )
}

export default SeventhComponent