import React from 'react'
import { useSelector } from 'react-redux'

const ThirdComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const processBoxArray = activeLanguage === "Español" ? [
        {
            id: 1,
            circleValue: 1,
            boxHeading: 'Verificación de identidad',

        },
        {
            id: 2,
            circleValue: 2,
            boxHeading: 'Documentación del vehículo',
            // paraText: 'Lorem ipsum dolor sit amet.'
        },
        {
            id: 3,
            circleValue: 3,
            boxHeading: 'Verificación de dirección',
            // paraText: 'Lorem ipsum dolor sit amet.'
        },
        {
            id: 4,
            circleValue: 4,
            boxHeading: 'Cuenta bancaria',
            // paraText: 'Lorem ipsum dolor sit amet.'
        },
    ]
        :
        [
            {
                id: 1,
                circleValue: 1,
                boxHeading: 'Identity verification',

            },
            {
                id: 2,
                circleValue: 2,
                boxHeading: 'Vehicle documentation',
                // paraText: 'Lorem ipsum dolor sit amet.'
            },
            {
                id: 3,
                circleValue: 3,
                boxHeading: 'Address verification',
                // paraText: 'Lorem ipsum dolor sit amet.'
            },
            {
                id: 4,
                circleValue: 4,
                boxHeading: 'Bank account',
                // paraText: 'Lorem ipsum dolor sit amet.'
            },
        ]

    return (
        <div className='ThirdComponent'>

            <div className="heading">
                {
                    activeLanguage === "Español" ?
                        "Nuestro proceso"
                        :
                        "Our process"
                }
            </div>

            <div className="processBox">

                {
                    processBoxArray.map((box) => {
                        return (
                            <div key={box.id} className='box'>

                                <div className="circe">
                                    <span>{box.circleValue}</span>
                                </div>

                                <div className="box-heading">
                                    {box.boxHeading}
                                </div>

                                <div className="para">
                                    {box.paraText}
                                </div>

                            </div>
                        )
                    })
                }

            </div>

            <div className="heading"></div>

            <div className="feedBackBox">

                <div className="left">

                    <div className="image">

                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractor_owner.jpeg" alt="" />

                    </div>

                    <div className="name">Mr John D</div>

                    <div className="address">
                        {
                            activeLanguage === "Español" ?
                                "Dueño del tractor"
                                :
                                "Tractor owner"
                        }
                    </div>
                </div>

                <div className="right">

                    <p>
                        {
                            activeLanguage === "Español" ?
                                `"El proceso de incorporación a Holatractor fue increíblemente sencillo. Incluir mi tractor fue muy fácil: su plataforma en línea es fácil de usar e intuitiva. En cuestión de minutos, tenía mi tractor en la lista con descripciones claras, fotografías y mis tarifas de alquiler preferidas."`
                                :
                                `"The onboarding process with Holatractor was incredibly easy. Listing my tractor was very easy – their online platform is easy to use and intuitive. Within minutes, I had my tractor listed with clear descriptions, photos, and my preferred rental rates."`
                        }
                    </p>

                    <div className='mobile-details'>

                        <div className="basic-details">

                            <div className="name">Mr John D</div>

                            <div className="address">
                                {
                                    activeLanguage === "Español" ?
                                        "Dueño del tractor"
                                        :
                                        "Tractor owner"
                                }
                            </div>

                        </div>

                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractor_owner.jpeg" alt="" />

                    </div>

                </div>

            </div>

        </div>
    )
}

export default ThirdComponent