import React from 'react'
import './CompactTractors.scss'
import Banner from './Banner'
import FirstComponent from './FirstComponent'
import SecondComponent from './SecondComponent'
import ThirdComponent from './ThirdComponent'
import SeventhComponent from './SeventhComponent'

const CompactTractors = () => {
  return (
    <div className='CompactTractors'>

        <Banner />

        <FirstComponent />

        <SecondComponent />

        <ThirdComponent />

        <SeventhComponent />

    </div>
  )
}

export default CompactTractors